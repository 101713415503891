import React, {useState, useEffect, useRef} from 'react';
import {Box} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import 'typeface-roboto';
import EditFilter from '../Common/EditFilter.js';
import DeleteItem from '../Common/DeleteItem';
import { Link } from "react-router-dom";
import CategoriesTable from './CategoriesTable';
import PlacesFilters from './PlacesFilters';
import { getEnvironmentCampaignIds } from '../../CommonFunctions';
import {getPlacesCategories} from '../../ApiUtility/ApiUtility';


/**
 * Component for managing Places Specific and Places Generic filters.
 *
 * @component
 */

function Places (){
    const [isDeleteFilterDialogOpen, setIsDeleteFilterDialogOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({});
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState({});

    const [type, setType] = useState("specific");
    const [location, setLocation] = useState({});

    const placesFiltersTableRef = useRef();
    const genericFiltersTableRef = useRef();
    

    const resetPlacesNav = e => {
        setType("specific");
        setActiveCategory({});
    }

    const handlePlacesToggle = (e, i) => {
        setType(i);
        if(i == "generic") setActiveCategory({id: 0})
        else setActiveCategory({})
    }

    const refreshData = e => {
        if(activeCategory.id == 0) genericFiltersTableRef.current.onQueryChange();
        else placesFiltersTableRef.current.onQueryChange();
    }

    const handleEditClose = e => {
        setSelectedFilter({});
        setEditOpen(false);
    }

    useEffect(() => {
        getPlacesCategories().then(setCategories)
    }, [])
    
    return (
        <>
            <EditFilter
                editOpen={editOpen}
                handleEditClose={handleEditClose}
                selectedFilter={selectedFilter} 
                setSelectedFilter={setSelectedFilter}
                campaign={{name: activeCategory.category_name, ...activeCategory}}
                retailer={{retailer_id:0}}
                refreshData={refreshData}
                frameAssociations={[
                    {"entity_id":getEnvironmentCampaignIds().places, "entity_type_name":"campaign"},
                    {"entity_id":activeCategory.id, "entity_type_name":"category"}
                ]}
                frame_type={activeCategory.id == 0 ? "places-generic" : "places"}
            />
            <DeleteItem 
                open={isDeleteFilterDialogOpen}
                setIsDeleteOpen={setIsDeleteFilterDialogOpen}
                handleDeleteClose={e => setIsDeleteFilterDialogOpen(false)}
                selectedItem={selectedFilter} 
                setSelectedItem={setSelectedFilter}
                refreshData={refreshData}
                campaign={location}
            />
            <Box style={{width:'100%'}}>
                <Box style={{textAlign: "left"}} pl={2}>
                    <Link style={{color: 'black'}} to={"/other-setups/non-sponsor"}>
                    </Link>
                </Box>
                <ToggleButtonGroup
                    style={{margin: "10px 0"}}
                    value={type}
                    exclusive
                    onChange={handlePlacesToggle}
                    aria-label="text alignment"
                    id="togglebuttongroup"
                >
                    <ToggleButton value="specific" aria-label="left aligned">
                        Places Specific
                    </ToggleButton>
                    <ToggleButton id="places_generic_toggle" value="generic" aria-label="centered">
                        Places Generic
                    </ToggleButton>
                </ToggleButtonGroup>
                    <Box style={{width: '90%', margin: '0 auto', position: 'relative'}} 
                        className={type !== "specific" || !!activeCategory.id ? "hidden" : ""}>
                        <CategoriesTable
                            setActiveCategory={setActiveCategory}
                            activeCategory={activeCategory}
                            categories={categories}
                            placesFiltersTableRef={placesFiltersTableRef}
                        />
                    </Box>
                    <Box className={!!activeCategory.id ? "" : "hidden"} style={{width: '90%', margin: '0 auto'}} >
                        <PlacesFilters
                            entity_id={location.location_id}
                            location={location}
                            resetPlacesNav={resetPlacesNav}
                            tableRef={placesFiltersTableRef}
                            setEditOpen={setEditOpen}
                            setSelectedFilter={setSelectedFilter}
                            setIsDeleteFilterDialogOpen={setIsDeleteFilterDialogOpen}
                            campaign_entity_id={getEnvironmentCampaignIds().places}
                            activeCategory={activeCategory}
                        />
                    </Box>
                    <Box style={{width: '90%', margin: '0 auto'}} className={type !== "generic" ? "hidden" : ""}>
                        <PlacesFilters
                            entity_id={1}
                            resetPlacesNav={resetPlacesNav}
                            setEditOpen={setEditOpen}
                            setSelectedFilter={setSelectedFilter}
                            setIsDeleteFilterDialogOpen={setIsDeleteFilterDialogOpen}
                            tableRef={genericFiltersTableRef}
                            campaign_entity_id={getEnvironmentCampaignIds().places}
                            activeCategory={activeCategory}
                        />
                    </Box>
            </Box>
        </>
    )
  }

  export default Places;
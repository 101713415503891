import React, {useState, useEffect, useRef, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Dialog, TextField, AppBar, Toolbar, IconButton,
   Typography, Grid, CircularProgress} from '@material-ui/core/';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab/';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SaveExitDialog from '../Common/SaveExitDialog';
import Alert from '@material-ui/lab/Alert';
import { GoogleMap, Polygon, useJsApiLoader, StandaloneSearchBox, Marker, DrawingManager } from "@react-google-maps/api";
import { formatGeoCode} from '../../CommonFunctions';
import { saveLocation } from "../../ApiUtility/ApiUtility";


const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'rgb(72, 120, 152)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formLabel: {
    display: 'block'
  }
}));

const containerStyle = {
  width: '100%',
  height: '100%',
  marginTop: '20px'
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Component used to edit location geofences/radius.
 *
 * @component
 */

export default function LocationDialog(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [locationName, setLocationName] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [err, setErr] = useState({});
    const [isSaveBeforeExitOpen, setIsSaveBeforeExitOpen] = useState(false);
    const [locationWasSaved, setLocationWasSaved] = useState(false);
    const [areSettingsUpdated, setAreSettingsUpdated] = useState(false);
    const [testLocationID, setTestLocationID] = useState(" ");
    const [path, setPath] = useState([]);
    const [zoom, setZoom] = useState(8);
    const [center, setCenter] = useState({ lat: 52.52047739093263, lng: 13.36653284549709 });
    const [map, setMap] = React.useState(null);
    const [searchBox, setSearchBox] = useState(null);
    const [type, setType] = useState("fence");
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [radius, setRadius] = useState(props.selectedLocation.geoFence?.radius || 0);

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const markerRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        setAreSettingsUpdated(true);
        if (polygonRef.current) {
        const nextPath = polygonRef.current
            .getPath()
            .getArray()
            .map(latLng => {
            return { lat: latLng.lat(), lng: latLng.lng() };
            });
        setPath(nextPath);
        }
    }, [setPath]);

    // Bind refs to current Polygon and listeners
    const onPolygonLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const polyPath = polygon.getPath();
            listenersRef.current.push(
                polyPath.addListener("set_at", onEdit),
                polyPath.addListener("insert_at", onEdit),
                polyPath.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    const handleTypeToggle = (e, v) => setType(v);

    // Clean up refs
    const onPolygonUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAS7you8rJo6sGwsyESxF5dsCeJptPujTw",
        libraries: ["places", "drawing"]
    })

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const updateLocationName = (e) => {
        if(locationName == e.target.value) return;
        setAreSettingsUpdated(true);
        if(!props.selectedLocation.location_id) return setLocationName(e.target.value);
        let nextLocation = Object.assign({}, props.selectedLocation);
        nextLocation.geoFence.geofence_name = e.target.value;
        nextLocation.location_name = e.target.value;
        nextLocation.venue_name = e.target.value;
        setLocationName(e.target.value);
        props.setSelectedLocation(nextLocation);
    }

    const closeEditLocation = () => {
        if(isSaving) return;
        if(areSettingsUpdated) setIsSaveBeforeExitOpen(true)
        else{
            setValue(0);
            setPath([]);
            setErr({});
            props.handleEditClose();
        }
    }

    const hasErrors = () => {
        if(type == "radius" && radius == 0){
            setErr({message: "Radius cannot be zero"});
            setTimeout(() => setErr({}), 2000);
            setIsSaving(false);
            return true;
        } 
        else if(type == "fence" && path.length == 0) {
            setErr({message: "No Fence Present"});
            setTimeout(() => setErr({}), 2000);
            setIsSaving(false);
            return true;
        }
       
        console.log("passed handle errors");
        return false;
    }

    const handleSave = async () => {
        setIsSaving(true);
        if( !!hasErrors() ) return;
        let nextSelectedLocation = JSON.parse(JSON.stringify(props.selectedLocation));
        delete nextSelectedLocation.tableData;
        if(!nextSelectedLocation.geoFence) nextSelectedLocation = await createNewLocationItem()
        if(type == "fence") nextSelectedLocation.geoFence.data = path.map(x => ({latitude: x.lat, longitude: x.lng}));
        else nextSelectedLocation.geoFence.data = [{latitude: selectedPlace.lat, longitude: selectedPlace.lng}];
        nextSelectedLocation.geoFence.geofence_name = locationName;
        nextSelectedLocation.venue_name = locationName;
        nextSelectedLocation.location_name = locationName;
        nextSelectedLocation.location_type = "location";
        nextSelectedLocation.geoFence.radius = radius;
        saveLocation(nextSelectedLocation).then(e => {
            if(e.code == 100) {
                setErr({message: "Radius cannot be zero"});
                setTimeout(() => setErr({}), 2000);
                setIsSaving(false);
                return;
            }
            setAreSettingsUpdated(false);
            let id = e.geofence_id;
            if(type == "fence") addLocationGeoFence("" + id);
            props.refreshData();
            props.setSelectedLocation(e);
            setIsSaving(false);
            setLocationWasSaved(true);
            setTimeout(() =>{
                setLocationWasSaved(false);
            }, 2000)
        });
    }
    
    const addLocationGeoFence = (GeofenceId) => {
        let polygon =  path.map(x => [x.lng, x.lat]);
        polygon = [...polygon, polygon[0]]
        let params = {
            CollectionName: 'CameraPlusLocations', /* required */
            GeofenceId, /* required */
            Geometry: { /* required */
                Polygon: [ polygon ]
            }
        };
        props.locationClient.putGeofence(params, function(err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else     console.log(data);           // successful response
        });
    }

    const drawNew = e => setPath([]);

    const onSearchLoad = ref => setSearchBox(ref);

    const onPlacesChanged = () =>{
        let place = searchBox.getPlaces()[0];
        let coords = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
        setSelectedPlace(coords);
    }

    const handleMapClick = e => {
        if(type == "fence") return;
        setAreSettingsUpdated(true);
        setSelectedPlace({lat: e.latLng.lat(), lng: e.latLng.lng()})
    }

    const fitMapToPlace = () => {
        if(!map || !selectedPlace) return;
        let zoom = map.zoom;
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(selectedPlace);
        map.fitBounds(bounds);
        map.setZoom(zoom);
    }

    const createNewLocationItem = () => {
        return new Promise((resolve, reject) => {
            let geocoder = new window.google.maps.Geocoder;
            let locationData = type == "radius" ? selectedPlace : path[0];
            geocoder.geocode({location: locationData}, (results, status) => {
                resolve(formatGeoCode(results));
            })
        })
    }

    useEffect(() => {
        setLocationName(props.selectedLocation.geoFence?.geofence_name)
        if(props.selectedLocation.geoFence?.data.length > 1){
            let path = props.selectedLocation.geoFence?.data.map(x => ({lat: x.latitude, lng: x.longitude}));
            setPath(path || []);
            setType("fence");
        }else if (props.selectedLocation.geoFence?.data.length == 1){
            let latitude = props.selectedLocation.geoFence?.data[0].latitude;
            let longitude = props.selectedLocation.geoFence?.data[0].longitude;
            setSelectedPlace(latitude ? {lat: latitude, lng: longitude} : null);
            setType("radius");
            setRadius(props.selectedLocation.geoFence.radius);
        }else{
            setPath([]);
            setSelectedPlace(null)
        }
        
    }, [props.selectedLocation.location_id]);

    useEffect(() => {
        if(!map) return;
        if(!!polygonRef.current) map.fitBounds(polygonRef.current.getBounds());
        else if(!!selectedPlace) fitMapToPlace()
        else{
            var bounds = new window.google.maps.LatLngBounds();
            bounds.extend({lat: 38.60311643259823, lng: -101.83650509905742});
            map.fitBounds(bounds);
            setTimeout(() => {
                map.setZoom(4);
            }, 1000)
        } 
    }, [map]);

    useEffect(() => {
        fitMapToPlace(); 
    }, [selectedPlace])
       

    useEffect(() => {
        if(!isLoaded) return;
        if (!window.google.maps.Polygon.prototype.getBounds) {
 
            window.google.maps.Polygon.prototype.getBounds=function(){
                var bounds = new window.google.maps.LatLngBounds()
                this.getPath().forEach(function(element,index){bounds.extend(element)})
                return bounds
            }
        }
    }, [isLoaded]);

    return (
        <div>
        <SaveExitDialog
            open={isSaveBeforeExitOpen}
            setIsSaveBeforeExitOpen={setIsSaveBeforeExitOpen}
            setAreSettingsUpdated={setAreSettingsUpdated}
            handleSave={handleSave}
            setValue={setValue}
            handleEditClose={props.handleEditClose}
            setErr={setErr}
        />
        <Dialog fullScreen open={props.editOpen} onClose={closeEditLocation} 
            TransitionComponent={Transition}>
            <AppBar className={classes.appBar} position="fixed">
            <Toolbar>
                <IconButton id="close_button" edge="start" color="inherit" disabled={isSaving} onClick={closeEditLocation} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title} >
                {props.selectedLocation.geoFence?.geofence_name}
                </Typography>
                <Button id="save_button" autoFocus color="inherit" onClick={handleSave}>
                {!props.selectedLocation.location_id ? 'create' : 'save'} {isSaving && <CircularProgress size={24} style={{position: "absolute"}} />}
                </Button>
            </Toolbar>
            {locationWasSaved && <Alert severity="success">Location has been saved</Alert>}
            <Alert 
                className={!err.message ? "hidden" : ""} 
                severity="error"
            >
                {err.message}
            </Alert>
            </AppBar>
            <Grid container p={3} style={{width: '98%', margin: '80px auto 0px', height: '70vh'}}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">
                    <TextField id="outlined-basic"  
                        label="ID" 
                        InputProps={{
                            readOnly: true,
                        }}
                        value={props.selectedLocation.location_id || " "} 
                        onClick={e => setTestLocationID(123)}
                        onChange={e=>e}
                        variant="outlined" /> 
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">
                    <TextField id="Location_name" label="Name" onBlur={updateLocationName} 
                        defaultValue={locationName} variant="outlined" autoFocus={typeof props.selectedLocation.geoFence?.geofence_name  == "undefined"} /> 
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <ToggleButtonGroup
                        value={type}
                        exclusive
                        onChange={handleTypeToggle}
                        aria-label="text alignment"
                        id="toggle-geolocation-type"
                    >
                        <ToggleButton value="fence" aria-label="left aligned">
                            Fence
                        </ToggleButton>
                        <ToggleButton id="places_generic_toggle" value="radius" aria-label="centered">
                            Radius
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={2}> 
                    {type == "fence" && <Button variant="contained" color="primary" onClick={drawNew}>Draw New</Button>}
                    {type == "radius" && 
                        (<Typography variant="subtitle1">
                            <TextField id="radius" label="Radius (in Miles)" 
                                onChange={e => setRadius(e.target.value)} defaultValue={radius} variant="outlined" 
                            /> 
                        </Typography>)
                    }
                </Grid>
                    {isLoaded && <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        options={{maxZoom: 15}}
                        onClick={handleMapClick}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {path.length == 0 && type == "fence" && 
                            <DrawingManager
                                drawingMode={'polygon'}
                                drawingControl={false}
                                onPolygonComplete={poly => {
                                    let newPolyPath = []
                                    poly.getPath().forEach(x => newPolyPath.push({lat: x.lat(), lng: x.lng()}));
                                    setPath(newPolyPath);
                                    poly.setMap(null);
                                }}
                            />
                        }
                         <StandaloneSearchBox
                            onLoad={onSearchLoad}
                            ref={searchBox}
                            onPlacesChanged={
                                onPlacesChanged
                            }
                            >
                            <input
                                type="text"
                                placeholder="Enter location"
                                style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `240px`,
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-120px"
                                }}
                            />
                        </StandaloneSearchBox>
                        {type == "radius" && selectedPlace && <Marker ref={markerRef} position={selectedPlace} />}
                        {path.length > 0 && type == 'fence' && <Polygon
                            // Make the Polygon editable / draggable
                            editable
                            draggable
                            path={path}
                            // Event used when manipulating and adding points
                            onMouseUp={onEdit}
                            // Event used when dragging the whole Polygon
                            onDragEnd={onEdit}
                            onLoad={onPolygonLoad}
                            onUnmount={onPolygonUnmount}
                        />}
                    </GoogleMap>}
            </Grid>    
        </Dialog>
        </div>
    );
}
import React, {useState, useEffect} from 'react';
import MaterialTable, {MTableBody, MTablePagination} from 'material-table';
import {Link, Typography} from '@material-ui/core';
import {Create, Delete} from '@material-ui/icons';
import ViewLinks from '../Common/ViewLinks';
import {getCampaignLocationList } from '../../ApiUtility/ApiUtility';
import {applySchedActiveStatus, getStatusFilterStatement,
    getAPICall, getEnvironmentCampaignIds, formatSearch} from '../../CommonFunctions';
import {pageHistoryAtom, visitedLinksAtom} from '../../App';
import {useAtom} from 'jotai';

const table_styling = {headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold', borderRight: '1px solid white'},
        cellStyle: {borderRight: '.5px solid rgba(224, 224, 224, 1)'}};

const itemsToSelectColumns = [
    { title: 'ID', field: 'location_id', ...table_styling},
    { title: 'Geo Location', field: 'location_name', ...table_styling},
    { title: 'Type', field: 'location_type', ...table_styling}
]

const selectedItemColumns = [
    { title: 'ID', field: 'frame_id', ...table_styling},
    { title: 'Geo Filters', field: 'name', ...table_styling},
    { title: 'Status', field: 'active', ...table_styling, lookup: { 0: 'Inactive', 1: 'Active' }}
]

let queryFilter = [];

/**
 * Component used to list AR Faces filters.
 *
 * @component
 */

function ARFacesFilters(props){

    const table_styling = {headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold', borderRight: '1px solid white'},
        cellStyle: {borderRight: '.5px solid rgba(224, 224, 224, 1)'}};

    const [campaignLocationList, setCampaignLocationList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageHistory, setPageHistory] = useAtom(pageHistoryAtom);
    const [visitedLinks, setVisitedLinks] = useAtom(visitedLinksAtom);

    let filterBy = null;


    useEffect(()=>{
        getCampaignLocationList(getEnvironmentCampaignIds().ar).then(setCampaignLocationList)
        .catch(err => err)
        return e => queryFilter = [];
    }, [])

    return(
        <>
        <ViewLinks 
            open={props.open} 
            setIsLinksDialogOpen={props.setIsLinksDialogOpen}
            filterLocationLinks={props.filterLocationLinks}
            campaignLocationLinks={props.campaignLocationLinks}
            setSelectedFilter={props.setSelectedFilter}
            setSelectedItem={props.setSelectedFilter}
            selectedItem={props.selectedFilter}
            selectedItemColumns={selectedItemColumns} 
            itemsToSelectColumns={itemsToSelectColumns}
            campaign_id={getEnvironmentCampaignIds().ar}
            itemsToSelect={campaignLocationList}
            refreshData={e => props.tableRef.current && props.tableRef.current.onQueryChange()}
        />
        <MaterialTable
            title="GeoFilters"
            options={{
                filtering: true,
                pageSize,
                pageSizeOptions: [10, 20, 30]
            }}
            components={{
                Pagination: props => {
                    delete props.classes.caption;
                    delete props.classes.selectRoot;
                    delete props.classes.toolbar;
                    return <MTablePagination {...props} onPageChange={props.onChangePage}/>
                },
                Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                    let nextFilterBy = value.filter(x => x !== filterBy);
                    filterBy = nextFilterBy[0];
                    props.onFilterChanged(columnId, [filterBy]);
                }}/>
            }}
            tableRef={props.tableRef}
            onSearchChange={(e) => e}
            columns={[
                { title: 'Id', field: 'frame_id', filtering: false, ...table_styling},
                { title: 'Filter Name', field: 'name', filtering: false, ...table_styling},
                { title: 'Status', field: 'active_status',
                 ...table_styling, defaultFilter: queryFilter,
                 lookup: { 0: 'Inactive', 1: 'Active', 2: 'Scheduled Inactive', 3: 'Scheduled Active' }},
                { title: 'Linked', field: 'optin_algorithm', filtering: false, ...table_styling, 
                    render: rowData => (
                        <Link 
                            data-frame-id={rowData.frame_id} 
                            data-name={rowData.name} 
                            onClick={e => props.handleViewLinksClick(rowData)}
                            className={"view-links"}
                        >View Links</Link>
                    )
                },
                { title: 'Links Count', field: 'location_count', filtering: false, ...table_styling},
                {
                    title: 'Actions', filtering: false,
                    render: rowData => (
                        <Typography>
                            <Link 
                                className={visitedLinks.filters.includes(rowData.frame_id) ? "visited" : ""}
                                style={{cursor: "pointer"}}
                                onClick={ e => {
                                    props.setSelectedFilter(rowData);
                                    props.setEditOpen(true)
                                    setVisitedLinks(x => {
                                        x.filters.push(rowData.frame_id)
                                        return x;
                                    })
                                }}
                            >
                                <Create style={{color: "black"}}/>
                                    <span 
                                        style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                        Edit
                                    </span>
                            </Link>&nbsp;&nbsp;
                            <Link 
                                href="/delete_campaign"
                                onClick={event => {
                                    event.preventDefault();
                                    props.setSelectedFilter(rowData);
                                    props.setIsDeleteFilterDialogOpen(true);
                                }}
                            >
                                <Delete 
                                    style={{color: "black"}}
                                    className="delete_ar_filter"
                                />
                                    <span 
                                        style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                        Delete
                                    </span>
                            </Link>
                        </Typography>
                    ),
                    headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold'}
                },
            ]}
            actions={[
                {
                    icon: 'add',
                    tooltip: 'Add AR Filter',
                    isFreeAction: true,
                    onClick: (e) => props.setEditOpen(true)
                }
            ]}
            data={query =>
                new Promise((resolve, reject) => {
                    let {active, jobFlag} = getStatusFilterStatement(query.filters)
                    queryFilter = query.filters[0].value;
                     if(pageHistory.active && pageHistory.pageNumber) {
                        query.page = pageHistory.pageNumber;
                        setPageHistory(x => {
                            delete x.active;
                            return x;
                        });
                    };
                    setPageSize(query.pageSize);
                    let url = `/CameraPlus/frame/links`
                    url += '?size=' + query.pageSize
                    url += '&from=' + (query.page * query.pageSize)
                    url += '&keyword=' + formatSearch(query.search);
                    if(query.filters[0]) url += `&active=${active}`;
                    if(query.filters[0]) url += `&jobFlag=${jobFlag}`;
                    getAPICall(url, {
                        "frameAssociations": [
                            {
                                "entity_type_name": "campaign",
                                "entity_id": getEnvironmentCampaignIds().ar
                            }
                        ]
                    })
                    .then(response => response.data)
                    .then(result => {
                      resolve({
                        data: applySchedActiveStatus(result.response.items),
                        page: pageHistory.active ? (pageHistory.pageNumber || 0) : query.page || 0,
                        totalCount: result.response.total
                      })
                    })
                })}
        />
        </>
    )
}

export default ARFacesFilters;
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import Draggable from "react-draggable";

/**
 * Component used to set preview Lottie files on canvas. Not currently supported in app.
 *
 * @component
 */

function LottiePreview(props) {
  let lottieData = JSON.parse(JSON.stringify(props.uploadedLottie)).data;
  let scale = (window.innerHeight * 0.6375) / 1080;

  console.log("got past lottie data");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log("defaultOptions: ", defaultOptions);

  const handleMovablePosition = (coordinates) => {
    let nextSettings = props.canvasProps.upload_images;
    nextSettings.lottie = coordinates;
    props.updateCanvasProps("upload_images", nextSettings);
  };

  useEffect(() => {
  }, [props.uploadedLottie]);

  return (
    <Draggable
      bounds="parent"
      handle=".handle"
      defaultPosition={{ x: 0, y: 0 }}
      position={props.canvasProps?.upload_images?.lottie}
      grid={[1, 1]}
      scale={1}
      onStop={(e, d) => handleMovablePosition({ x: d.x, y: d.y })}
    >
      <div
        className="handle"
        style={{
          height: lottieData ? lottieData.h * scale : 0,
          width: lottieData ? lottieData.w * scale : 0,
          position: "absolute",
          zIndex: 10000,
        }}
      >
        {defaultOptions.animationData?.v && (
          <Lottie
            options={defaultOptions}
            height={lottieData ? lottieData.h * scale : 0}
            width={lottieData ? lottieData.w * scale : 0}
            style={{ position: "relative" }}
            isStopped={false}
            isPaused={false}
          />
        )}
      </div>
    </Draggable>
  );
}

export default LottiePreview;

import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import {deleteCampaignLocationAssociation} from '../../../ApiUtility/ApiUtility';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    textAlign: "center"
  },
}));

/**
 * Component used to delete GeoLocations.
 *
 * @component
 */

function DeleteGeoLocationDialog(props) {
  const [wasLocationDeleted, setWasLocationDeleted] = useState(false);

  const classes = useStyles();
  const { open } = props;

  const handleClose = () => {
    if(wasLocationDeleted) props.refreshData();
    props.setIsDeleteDialogOpen(false);
    setTimeout(()=>{
        setWasLocationDeleted(false);
    }, 1000);
  };

  const handleDeleteClick = async e => {
    try{
      await deleteCampaignLocationAssociation(props.campaign_id, props.location_id);
      setWasLocationDeleted(true);
    }catch(err){
      
    }
  }

  return (
    <Dialog style={{textAlign: 'center'}} 
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {wasLocationDeleted ? 'Location Was Successfully Deleted' : 'Are you sure you want to delete this location?'}
      </DialogTitle>
      <div className={wasLocationDeleted ? `${classes.root} hidden` : `${classes.root}`}>
        <Button id="yes_delete_geolocation" variant="contained" color="primary" onClick={handleDeleteClick}>
          Yes
        </Button>
        <Button 
          variant="contained" color="secondary"
          onClick={event => props.setIsDeleteDialogOpen(false)}
        >
          No
        </Button>
      </div>
      <div className={wasLocationDeleted ? `${classes.root}` : `${classes.root} hidden`}>
        <Button id="ok_geolocation_deleted" variant="contained" onClick={handleClose}>
          Ok
        </Button>
      </div>
    </Dialog>
  );
}

export default DeleteGeoLocationDialog;
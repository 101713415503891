import React, {useState} from 'react';
import MaterialTable, {MTablePagination} from 'material-table';
import {Link, Typography} from '@material-ui/core';
import {Create} from '@material-ui/icons';
import {getAPICall, formatSearch } from '../../CommonFunctions';
import {pageHistoryAtom} from '../../App';
import {useAtom} from 'jotai';

const table_styling = {headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold', borderRight: '1px solid white'},
        cellStyle: {borderRight: '.5px solid rgba(224, 224, 224, 1)'}};

/**
 * Component for listing cities.
 *
 * @component
 */

function CitiesTable(props){

    const [pageSize, setPageSize] = useState(10);
    const [pageHistory, setPageHistory] = useAtom(pageHistoryAtom);


    return(
        <MaterialTable
            title="Cities"
            tableRef={props.tableRef}
            options={{
                pageSize,
                pageSizeOptions: [10, 20, 30]
            }}
            components={{
                Pagination: props => {
                    delete props.classes.caption;
                    delete props.classes.selectRoot;
                    delete props.classes.toolbar;
                    return <MTablePagination {...props} onPageChange={props.onChangePage}/>
                }
             }}
            columns={[
                { title: 'Id', field: 'location_id', ...table_styling},
                { title: 'City', field: 'city_name', ...table_styling},
                { title: 'State', field: 'state_name', ...table_styling},
                {
                    title: 'Action',
                    render: rowData => (
                        <Typography>
                            <Link 
                                style={{cursor: "pointer"}}
                                onClick={ e => {
                                    rowData.name = `${rowData.city_name}, ${rowData.state_name}`
                                    props.setLocation(rowData);
                                    props.cityFiltersTableRef.current.onQueryChange();
                                }}
                            >
                                <Create style={{color: "black"}}/>
                                    <span 
                                        style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                        Edit Geo Filters
                                    </span>
                            </Link>&nbsp;&nbsp;
                        </Typography>
                    ),
                    headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold'}
                },
            ]}
            actions={[
                {
                    icon: 'add',
                    tooltip: 'Add City Specific Frames',
                    isFreeAction: true,
                    onClick: (e) => props.setEditOpen(true)
                }
            ]}
            data={query =>
                new Promise((resolve, reject) => {
                    setPageSize(query.pageSize);
                    if(pageHistory.active && pageHistory.pageNumber) {
                        query.page = pageHistory.pageNumber;
                        setPageHistory(x => {
                            delete x.active;
                            return x;
                        });
                    };
                    let url = `/CameraPlus/location/list`
                    url += '?size=' + query.pageSize
                    url += '&from=' + (query.page * query.pageSize)
                    url += '&keyword=' + formatSearch(query.search);
                    getAPICall(url, {
                        "location_type": "city",
                        "location_name": query.search
                    })
                    .then(response => response.data)
                    .then(result => {
                        
                      resolve({
                        data: result.response.items,
                        page: pageHistory.active ? (pageHistory.pageNumber || 0) : query.page || 0,
                        totalCount: result.response.total
                      })
                    })
                })}
        />
    )
}

export default CitiesTable;
import React, {useState, useEffect, useRef} from 'react';
import { Stage, Layer, Image, Label } from 'react-konva';
import CanvasText from '../Common/CanvasText';
import useImage from 'use-image';
import { parseStringToInt, getXOffset, getYOffset, transformCanvasProps } from '../../CommonFunctions';
import {getStaticAssetPath} from '../../ApiUtility/ApiUtility';
import '../../App.css';
import GIFImage from '../Common/FilterDesignerComponents/GIF';
import APNGImage from '../Common/FilterDesignerComponents/APNG';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


const StickerImage = (props, height) => {
  let scale = props.canvasHeight/1080;
  const {uploadedSticker } = props;

  const [imageUrl, setImageUrl] = useState("");
  const [imageNode, setImageNode] = useState(null);
  const [stickerHeight, setStickerHeight] = useState();
  const [stickerWidth, setStickerWidth] = useState();
  
  if(props.alignment == "horizontal" && props.filterOrientationType == "vertical") scale *= .75;
  else if(props.alignment == "horizontal") scale *= .75;
  else if(props.alignment == "vertical" && props.filterOrientationType == "horizontal") scale = .88;

  const [image, status] = useImage(imageUrl, 'Anonymous');

  useEffect(() => {
    if(uploadedSticker.url) {
      setImageUrl(uploadedSticker.url);
    }else{
      setImageUrl("");
      setImageNode(null);
    }
  },[uploadedSticker])

  useEffect(()=> {
    if(!imageNode) return;
    setStickerHeight(imageNode.height * scale);
    setStickerWidth(imageNode.width * scale);
  }, [imageNode]);

  useEffect(()=> {
    if(status == "loaded") setImageNode(image);
  },[status]);

  if(!!props.hide) return <Label/>
  

  return (
    <Image 
      x={parseStringToInt(props.canvasProps.upload_images.positionedImage.x)}
      y={parseStringToInt(props.canvasProps.upload_images.positionedImage.y)}
      height={stickerHeight}
      width={stickerWidth}
      image={imageNode}
    />
  )
};

const FilterImage = (props) => {

  let scale = 1; 
  const {uploadedBackgroundUrl, selectedFilter} = props;

  const [imageUrl, setImageUrl] = useState("");
  const [imageNode, setImageNode] = useState(null);

  if(props.alignment == "horizontal" && props.filterOrientationType == "vertical") scale = .75;
  else if(props.alignment == "vertical" && props.filterOrientationType == "horizontal") scale = 1/.75;

  const handleHorizontalImage = () => {
    let type = props.canvasProps.settings.type;
    let nextSettings = props.canvasProps.settings;
    if(type != 1 && type != 3){
      nextSettings.type = 1;
      props.updateCanvasProps("settings", nextSettings);
      props.setAlignment("horizontal");
    }else{
      props.setFilterOrientationType('horizontal');
    }
  }

  const handleVerticalImage = () => {
    let type = props.canvasProps.settings.type;
    let nextSettings = props.canvasProps.settings;
    if(type != 0 && type != 2){
      nextSettings.type = 0;
      props.updateCanvasProps("settings", nextSettings);
      props.setAlignment("vertical");
    }else{
      props.setFilterOrientationType('vertical');
    }
  }

  const getXOffset = (props) => {
    const {canvasProps: {settings: {type, alignment}}} = props;
    if(type == 0 || type == 1) return 0;
    else if(type == 3 && props.alignment == 'vertical') return 0 - ((window.innerHeight*.85) / (2 / alignment));
    else if(type == 2 && props.alignment == 'horizontal') return 0 + ((window.innerHeight*.85 * scale - window.innerHeight*.6375 * scale) / (2 / alignment));
  }
  
  const getYOffset = (props) => {
    const {canvasProps: {settings: {type, orienation}}} = props;
    if(type == 0 || type == 1) return 0;
    else if(type == 3 && props.alignment == 'vertical') return ((window.innerHeight*.85 - window.innerHeight*.6375) / (2 / orienation));
    else if(type == 2 && props.alignment == 'horizontal') return -1 * ((window.innerHeight*.85 * scale - window.innerHeight*.6375 * scale) / (2 / orienation));
  }

  const [image, status] = useImage(imageUrl, 'Anonymous');


  useEffect(() => {
    const checkSetHostedBackground = async () => {
      if(!!uploadedBackgroundUrl) return;
      let url  = await getStaticAssetPath(selectedFilter.frame_id, "bi");
      setImageUrl(url);
    }
    checkSetHostedBackground();
  }, [selectedFilter.frame_id]);

  useEffect(() => {
    if(!!uploadedBackgroundUrl) {
      setImageUrl(uploadedBackgroundUrl);
    }
  },[uploadedBackgroundUrl])


  useEffect(()=> {
    if(status == "loaded" && image.height == 1080 && image.width == 1440){
      setImageNode(image);
      handleHorizontalImage()
    }else if(status == "loaded" && image.height !== 1440 && image.width !== 1080){
      props.setUploadedBackground(false);
      props.setImageUploadErr("Image must be 1440 X 1080");
      props.setUploadedBackgroundUrl(null);
    }else if(status == "loaded" && image.height == 1440 && image.width == 1080){
      handleVerticalImage();
      setImageNode(image);
    }
  },[status]);

  return (
    <Image 
      x={getXOffset(props)}
      y={getYOffset(props)}
      key={1}
      width={props.orientation_type == 1 || props.orientation_type == 3 ? window.innerHeight*.85/.75*scale : window.innerHeight*.6375 * scale}
      height={window.innerHeight*.85*scale}
      image={imageNode}
    />
  )
};

/**
 * Component used to display filter preview in library tab.
 *
 * @component
 */

export default function FilterDialog(props) {

  const orientation_type = props.canvasProps.settings.type;

  const [uploadedBackgroundUrl, setUploadedBackgroundUrl] = useState(null);
  const [imageUploadErr, setImageUploadErr] = useState(false);
  const [filterOrientationType, setFilterOrientationType] = useState(null);
  const [freeformtext, setFreeformtext] = useState("Free Form Text");
  const [cityText, setCityText] = useState("City");
  const [background, setBackground] = useState("white");
  const [eyeDropperActive, setEyeDropperActive] = useState(false);
  const stageRef = useRef();

  const getModCanvasProps = () => {
    if(!props.canvasProps.settings.type) return props.canvasProps;
    let modCanvasProps = JSON.parse(JSON.stringify(props.canvasProps));
    let x_offset = getXOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType});
    let y_offset = getYOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType});
    let d = null;
    let scaleIn = props.canvasHeight / (window.innerHeight*.85);
    return transformCanvasProps(modCanvasProps, filterOrientationType, props.alignment, x_offset, y_offset, d, scaleIn);
  }

  let {uploadedBackground, uploadedGif, uploadedApng} = props;


  useEffect(() => {
    setTimeout(() => {
      if(stageRef.current) stageRef.current.draw();
    }, 2000);
  }, [props.fonts]);

  return (

    <div>
        <div style={{ width: props.width, height: props.height}}>
          {!!props.attributionActive && !!props.attributionText && <div
            style={{top: '30px', left: '30px', height:'5%', borderRadius: '20px',
              background: 'rgb(78,78,78)', display: 'flex', alignItems: 'center', pointerEvents: 'none'}}
          >
            <InfoOutlinedIcon style={{color: "white", height: '17px', marginRight: '5px', marginLeft: '5px'}}/> 
            <span style={{color: "white", fontSize: '12px', marginRight:'10px'}}>{props.attributionText}</span>
          </div>}
          <Stage style={{border: '1px solid rgb(0, 188, 212)', marginBottom: '100px', background}}
              className={eyeDropperActive ? "pippette_cursor" : ""}
              width={props.alignment=="horizontal" ? window.innerHeight*.85/.75 : parseFloat(props.width)} 
              height={parseFloat(props.height)}
              ref={stageRef}
              key={props.key}
            >
              <Layer>
                <FilterImage
                  alignment={props.alignment}
                  filterOrientationType={filterOrientationType}
                  canvasProps={getModCanvasProps()}
                  updateCanvasProps={props.updateCanvasProps}
                  setAlignment={props.setAlignment}
                  setFilterOrientationType={setFilterOrientationType}
                  uploadedBackgroundUrl={uploadedBackground}
                  selectedFilter={props.selectedFilter}
                  setUploadedBackground={props.setUploadedBackground}
                  setImageUploadErr={setImageUploadErr}
                  setUploadedBackgroundUrl={setUploadedBackgroundUrl}
                  orientation_type={orientation_type}
                />
                <StickerImage
                  canvasHeight={parseFloat(props.height)}
                  uploadedSticker={props.uploadedSticker}
                  alignment={props.alignment}
                  selectedFilter={props.selectedFilter}
                  setSelectedFilter={props.setSelectedFilter}
                  canvasProps={getModCanvasProps()}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                />
                <APNGImage
                  canvasHeight={parseFloat(props.height)}
                  uploadedApng={uploadedApng}
                  alignment={props.alignment}
                  selectedFilter={props.selectedFilter}
                  setSelectedFilter={props.setSelectedFilter}
                  canvasProps={getModCanvasProps()}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                />
                <GIFImage
                  canvasHeight={parseFloat(props.height)}
                  uploadedGif={uploadedGif}
                  alignment={props.alignment}
                  selectedFilter={props.selectedFilter}
                  setSelectedFilter={props.setSelectedFilter}
                  canvasProps={getModCanvasProps()}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                />
                <CanvasText 
                  text={
                    cityText}
                  canvasHeight={parseFloat(props.height)}
                  name={"city"}
                  alignment={props.alignment}
                  settings={getModCanvasProps().variables.city}  
                  canvasProps={props.canvasProps}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                />
                <CanvasText 
                  canvasHeight={parseFloat(props.height)}
                  text={props.canvasProps.variables.date.format}
                  name={"date"}
                  alignment={props.alignment}
                  settings={getModCanvasProps().variables.date}  
                  canvasProps={props.canvasProps}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                  fonts={props.fonts}
                />
                <CanvasText 
                  canvasHeight={parseFloat(props.height)}
                  text={props.canvasProps.variables.time.format}
                  name={"time"}
                  alignment={props.alignment}
                  settings={getModCanvasProps().variables.time}  
                  canvasProps={props.canvasProps}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                />
                <CanvasText
                  canvasHeight={parseFloat(props.height)}
                  text={
                    props.canvasProps.variables.freeformtext.text || 
                    freeformtext}
                  name={"freeformtext"}
                  alignment={props.alignment}
                  settings={getModCanvasProps().variables.freeformtext}  
                  canvasProps={props.canvasProps}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                />
                {props.selectedFilter.frame_type?.includes("places") && <CanvasText 
                  text={"Places Dynamic"}
                  name={"nonPartnerConfig"}
                  alignment={props.alignment}
                  settings={getModCanvasProps().nonPartnerConfig}  
                  canvasProps={props.canvasProps}
                  updateCanvasProps={props.updateCanvasProps}
                  filterOrientationType={filterOrientationType}
                />}
              </Layer>
          </Stage>
        </div>
    </div>
  );
}
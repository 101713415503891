import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { imageToDataUri } from "../../../CommonFunctions";
import {
  saveARJSON,
  deleteAREffect,
  getArEffects,
  getArBinaries,
  getGDriveThumbnail,
} from "../../../ApiUtility/ApiUtility";

/**
 * Component used to control uploading and deleting DeepAR Effects.
 * Hooks up to GoogleDrive to get effects and Thumbnails.
 *
 * @component
 */

export default function ARUpload(props) {
  const [arBeautUrl, setArBeautUrl] = useState("");
  const [arEffects, setArEffects] = useState([]);

  const handleRadioChange = (e) => {
    props.setDeepArBgRadio(e.target.value);
  };


  const handleDelete = () => {
    props.selectedArBinaries.forEach((x) =>
      deleteAREffect(
        props.retailer.retailer_id,
        props.campaign.campaign_id,
        props.selectedFilter.frame_id,
        x.name
      )
    );
    saveARJSON(
      props.retailer.retailer_id,
      props.campaign.campaign_id,
      props.selectedFilter.frame_id,
      { scenes: [] }
    ).then((x) => props.setSelectedArBinaries([]));
  };

  const handleTriggerHintChange = (e, v) => {
    let nextSelectedFilter = Object.assign({}, props.selectedFilter);
    nextSelectedFilter.frame_hint = e.target.value || null;
    props.setSelectedFilter(nextSelectedFilter);
  };

  const handleARChange = async (e, v) => {
    props.setErr({});
    props.setIsARLoading(true);
    if (!v) {
      props.setSelectedArBinaries([]);
      props.setSelectedDeepArVersion(null);
      props.setIsARLoading(false);
    } else
      getArBinaries(v.id).then(async (r) => {
        let thumbnailIndex = r.data.findIndex((x) => x.type == "thumbnail");
        if (thumbnailIndex == -1) {
          props.setErr({ message: "Thumbnail Missing from Google Drive" });
          props.setIsARLoading(false);
          return;
        }

        let thumbnail = r.data.splice(thumbnailIndex, 1)[0];
        let { id, name } = thumbnail;
        let url = `https://www.googleapis.com/drive/v3/files/${id}?supportsAllDrives=true&includeItemsFromAllDrives=true&alt=media`;
        let image_url = await getGDriveThumbnail(url);

        if (name.includes(".gif")) {
          let base64 = await imageToDataUri(image_url, 320, 320);
          image_url = [base64, image_url];
        }
        props.setUploadedThumbnail(image_url);
        props.setSelectedArBinaries(r.data);
        props.setSelectedDeepArVersion(v.deepArVersion);
      });
  };

  const verifyDeepArVersion = (version) => {
    let versionRegex = new RegExp(/^(\d+\.)?(\d+\.)?(\*|\d+)$/);
    if (versionRegex.test(version)) return version;
    return false;
  };

  const getSelectedBinary = () => {
    let resultIndex;
    if (!props.selectedArBinaries[0] || arEffects.length < 1) return "";
    else {
      resultIndex = arEffects.findIndex(
        (e) => e.name == props.selectedArBinaries[0].scene_name
      );
      return arEffects[resultIndex];
    }
  };

  useEffect(() => {
    getArEffects().then((r) => {
      setArEffects(r.data);
    });
  }, []);

  return (
    <>
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Alert
          className={!props.imageUploadErr ? "hidden" : ""}
          variant="outlined"
          severity="error"
        >
          {props.imageUploadErr}
        </Alert>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Asset</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="AR1">
              <TableCell align="right">
                {!props.selectedArBinaries[0] ||
                !!props.selectedArBinaries[0].id ? (
                  <Autocomplete
                    id="ar-asset-select"
                    options={arEffects}
                    onChange={handleARChange}
                    value={getSelectedBinary()}
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) =>
                      !verifyDeepArVersion(option.deepArVersion)
                    }
                    renderOption={(option) => {
                      let { name, deepArVersion } = option;
                      return (
                        <div style={{ fontSize: "12px" }}>
                          {`${name} - ${
                            verifyDeepArVersion(deepArVersion) ||
                            "Invalid Version Number"
                          }`}
                        </div>
                      );
                    }}
                    size="small"
                    style={{ fontSize: "12px" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          style={{ fontSize: "12px" }}
                          {...params}
                          label="Face Filter"
                          variant="outlined"
                        />
                      );
                    }}
                  />
                ) : (
                  <List dense={true}>
                    {props.selectedArBinaries.map((x) => {
                      return (
                        <ListItem>
                          <ListItemText
                            style={{ wordBreak: "break-word" }}
                            primary={x.name}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </TableCell>
              <TableCell align="right">
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleDelete()}
                  className={
                    !props.selectedArBinaries[0] ||
                    !!props.selectedArBinaries[0].id
                      ? "hidden"
                      : ""
                  }
                >
                  Delete
                </Link>
                {props.isARLoading && <CircularProgress size={24} />}
              </TableCell>
            </TableRow>
            <TableRow key="ARB">
              <TableCell component="th" scope="row">
                <TextField
                  id="trigger_hint"
                  label="Trigger Hint"
                  variant="outlined"
                  defaultValue={props.selectedFilter.frame_hint}
                  onBlur={handleTriggerHintChange}
                />
              </TableCell>
              <TableCell align="right">
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleDelete("arb")}
                  className={!!arBeautUrl ? "" : "hidden"}
                >
                  Delete
                </Link>
              </TableCell>
            </TableRow>
            <TableRow key="ArBg">
              <TableCell component="th" scope="row">
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Does this effect have a background?
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="scheduleSettings"
                    value={props.deepArBgRadio}
                    onChange={handleRadioChange}
                    row
                    style={{ width: "auto" }}
                  >
                    <FormControlLabel
                      id="radio_deep_ar_w_bg"
                      color="primary"
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                    <FormControlLabel
                      id="radio_deep_ar_wo_bg"
                      color="primary"
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
              <TableCell align="right">
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleDelete("arb")}
                  className={!!arBeautUrl ? "" : "hidden"}
                >
                  Delete
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";
import { placesCanvasProps } from "../../data/defaultCanvasProps";
import {
  Image,
  ExpandMore,
  ExpandLess,
  LocationCity,
  Apps,
  Face,
  Today,
  QueryBuilder,
  TextFields,
  Rotate90DegreesCcw,
  ScreenRotation,
  PlayCircleOutline,
  Filter8,
} from "@material-ui/icons/";
import ThumbnailUpload from "./ThumbnailUpload";
import TextSettings from "./TextSettings";
import ImageUpload from "./ImageUpload";
import LottieUpload from "./LottieUpload";
import ARUpload from "./FilterDesignerComponents/ARUpload";
import ArPlacement from "./FilterDesignerComponents/ArPlacement";
import WebARConfiguration from "./FilterDesignerComponents/WebARConfiguration";
import PreviewDialog from "./PreviewDialog";
import { useDropzone } from "react-dropzone";
import { deleteARExperience, deleteARAudio } from "../../ApiUtility/ApiUtility";
import { Storage } from "aws-amplify";
import "typeface-roboto";

import { readUploadedImage } from "../../CommonFunctions";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  CircularProgress,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    display: "inline-block",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  label: {
    marginRight: "10px",
    display: "inline-block",
  },
  drawer: {
    width: 360,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 360,
    height: window.innerHeight - 140,
    overflow: "scroll",
    marginTop: 140,
  },
}));

const styles = {
  label: {
    marginRight: "10px",
    display: "block",
  },
};

/**
 * Component used to upload and delete Ar Experience.
 *
 * @component
 */

const ArExperienceUpload = (props) => {
  const [effect, setEffect] = props.worldViewDuple;
  const [arAudioFile, setArAudioFile] = props.arAudioDuple;
  let retailer_id = props.campaign.retailer_id || 0;
  let campaign_id = props.campaign.campaign_id;
  let frame_id = props.selectedFilter.frame_id;

  const requestConfig = {
    customPrefix: {
      public: "retailers",
      protected: "retailers",
      private: "retailers",
    },
  };

  const {
    getRootProps: getRootPropsEffect,
    getInputProps: getInputPropsEffect,
  } = useDropzone({ onDrop: (f) => onDrop(f, "effect") });

  const { getRootProps: getRootPropsAudio, getInputProps: getInputPropsAudio } =
    useDropzone({ onDrop: (f) => onDrop(f, "audio") });

  const onDrop = async (file, type) => {
    if (type === "effect") {
      let binaryString = await readUploadedImage(file[0]);
      setEffect({ source: "loading", file: binaryString });
      uploadAsset(file[0]);
    } else if (type == "audio") {
      setArAudioFile({ source: "loading", file: file[0] });
      let uploadedAudio = await uploadAudio(file[0]);
      props.updateCanvasProps("arAudio", uploadedAudio);
      props.setAreSettingsUpdated(true);
    }
  };

  const uploadAsset = async (arFile) => {
    return new Promise(async (resolve, reject) => {
      Storage.put(
        `/${retailer_id}/${campaign_id}/frames/${frame_id}/ar-experience.glb`,
        arFile,
        requestConfig
      )
        .then((r) => {
          console.log("r: ", r);

          resolve(r);
          setEffect((x) => ({ ...x, source: "local" }));
          if (props.canvasProps.arAudio) {
            console.log("in arAudio exists");
            setArAudioFile({});
            props.loadAudio({ key: props.canvasProps.arAudio.key });
          }
        })
        .catch((err) => reject(err));
    });
  };

  const uploadAudio = async (audioFile) => {
    const { path } = audioFile;
    return new Promise(async (resolve, reject) => {
      Storage.put(
        `/${retailer_id}/${campaign_id}/frames/${frame_id}/${path}`,
        audioFile,
        requestConfig
      )
        .then((r) => {
          console.log("r: ", r);
          props.loadAudio(r);
          // setArAudioFile((x) => ({ ...x, source: "local" }));
          resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  const deleteArAudioFile = async () => {
    try {
      deleteARAudio({
        retailer_id,
        campaign_id,
        frame_id,
        path: arAudioFile.file.path,
      });
      setArAudioFile({});
      props.updateCanvasProps("arAudio", null);
      props.setAreSettingsUpdated(true);
    } catch (err) {
      console.error("Could not delete ar experience");
    }
  };

  const deleteAsset = async () => {
    try {
      deleteARExperience({ retailer_id, campaign_id, frame_id });
      setEffect({});
    } catch (err) {
      console.error("Could not delete ar experience");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>File</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="arEffect">
            <TableCell component="th" scope="row">
              <Typography variant="body2" style={styles.label} gutterBottom>
                AR Effect
              </Typography>
            </TableCell>
            <TableCell align="right">
              {effect.file && effect.source != "loading" && (
                <Button
                  onClick={(e) => deleteAsset()}
                  variant="contained"
                  color="secondary"
                  style={{ fontSize: "10px", padding: "5px 5px" }}
                >
                  Delete
                </Button>
              )}
              {effect.source == "loading" && <CircularProgress size={20} />}
              {!effect.file && effect.source != "loading" && (
                <div {...getRootPropsEffect({ refKey: "innerRef" })}>
                  <input {...getInputPropsEffect()} />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: "10px", padding: "5px 5px" }}
                  >
                    Upload
                  </Button>
                </div>
              )}
            </TableCell>
          </TableRow>
          <TableRow key="arAudio">
            <TableCell component="th" scope="row">
              <Typography variant="body2" style={styles.label} gutterBottom>
                Audio
              </Typography>
            </TableCell>
            <TableCell align="right">
              {arAudioFile.file && arAudioFile.source != "loading" && (
                <Button
                  onClick={(e) => deleteArAudioFile()}
                  variant="contained"
                  color="secondary"
                  style={{ fontSize: "10px", padding: "5px 5px" }}
                >
                  Delete
                </Button>
              )}
              {arAudioFile.source == "loading" && (
                <CircularProgress size={20} />
              )}
              {!arAudioFile.file && arAudioFile.source != "loading" && (
                <div {...getRootPropsAudio({ refKey: "innerRef" })}>
                  <input {...getInputPropsAudio()} />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: "10px", padding: "5px 5px" }}
                  >
                    Upload
                  </Button>
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/**
 * Component showing on side of designer to configure settings of filter.
 *
 * @component
 */
export default function NestedList(props) {
  const classes = useStyles();
  const [thumbnailOpen, setThumbnailOpen] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [lottieOpen, setLottieOpen] = useState(false);
  const [arLensOpen, setArLensOpen] = useState(false);
  const [webArOpen, setWebArOpen] = useState(false);
  const [arExperienceOpen, setArExperienceOpen] = useState(false);
  const [arPlacementOpen, setArPlacementOpen] = useState(false);
  const [orientationOpen, setOrientationOpen] = useState(false);
  const [cityOpen, setCityOpen] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [freeformOpen, setFreeformOpen] = useState(false);
  const [attributionOpen, setAttributionOpen] = useState(false);
  const [filterPreviewOpen, setFilterPreviewOpen] = useState(false);
  const [placesDynamicOpen, setPlacesDynamicOpen] = useState(false);

  const [radioValue, setRadioValue] = useState(
    "" + props.canvasProps.settings.type
  );
  const [radioOrientationValue, setRadioOrientationValue] = useState(
    "" + props.canvasProps.settings.orienation
  );
  const [radioAlignmentValue, setRadioAlignmentValue] = useState(
    "" + props.canvasProps.settings.alignment
  );

  const handleRadioChange = (e, v) => {
    let type = e.target.getAttribute("name");
    let nextSettings = props.canvasProps.settings;
    if (type == "type") {
      setRadioValue(v);
      nextSettings.type = v;
      if (v == "0") {
        nextSettings.orienation = "0";
        nextSettings.alignment = "0";
        setRadioOrientationValue("0");
        setRadioAlignmentValue("0");
        props.setAlignment("vertical");
      } else if (v == "1") {
        nextSettings.orienation = "0";
        nextSettings.alignment = "0";
        setRadioOrientationValue("0");
        setRadioAlignmentValue("0");
        props.setAlignment("horizontal");
      } else if (v == "3") props.setAlignment("horizontal");
      else props.setAlignment("vertical");
    } else if (type == "orienation") {
      setRadioOrientationValue(v);
      nextSettings.orienation = v;
    } else {
      setRadioAlignmentValue(v);
      nextSettings.alignment = v;
    }
    props.updateCanvasProps("settings", nextSettings);
  };

  const handleClick = (id) => {
    props.setEyeDropperActive(false);
    const map = {
      thumbnail: [thumbnailOpen, setThumbnailOpen],
      lottie: [lottieOpen, setLottieOpen],
      image: [imageOpen, setImageOpen],
      ar: [arLensOpen, setArLensOpen],
      webAr: [webArOpen, setWebArOpen],
      arExperience: [arExperienceOpen, setArExperienceOpen],
      arPlacement: [arPlacementOpen, setArPlacementOpen],
      orientation: [orientationOpen, setOrientationOpen],
      city: [cityOpen, setCityOpen],
      date: [dateOpen, setDateOpen],
      time: [timeOpen, setTimeOpen],
      free_form: [freeformOpen, setFreeformOpen],
      places_text: [placesDynamicOpen, setPlacesDynamicOpen],
      attribution: [attributionOpen, setAttributionOpen],
    };
    Object.keys(map).forEach((x) =>
      x == id ? map[x][1](!map[x][0]) : map[x][1](false)
    );
  };

  const hideElementMap = {
    thumbnail: false,
    lottie: false,
    image: false,
    ar: false,
    webAr: false,
    arExperience: false,
    arPlacement: false,
    orientation: false,
    city: false,
    date: false,
    time: false,
    free_form: false,
    places_text: false,
    attribution: false,
  };

  if (props.campaign.campaign_id == 401 || props.campaign.campaign_id == 983) {
    hideElementMap.lottie = true;
    hideElementMap.image = true;
    hideElementMap.ar = true;
    hideElementMap.webAr = true;
    hideElementMap.orientation = true;
    hideElementMap.city = true;
    hideElementMap.date = true;
    hideElementMap.time = true;
    hideElementMap.free_form = true;
    hideElementMap.places_text = true;
  } else {
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <FormControlLabel
        value="start"
        style={{ width: "70%" }}
        control={
          <Switch
            color="primary"
            onChange={(e, v) => props.setBackground(v ? "lightgray" : "white")}
          />
        }
        label="Gray Background"
        labelPlacement="start"
      />
      <PreviewDialog
        background={props.background}
        stageRef={props.stageRef}
        canvasProps={props.canvasProps}
        handleCloseDialog={(e) => setFilterPreviewOpen(false)}
        alignment={props.alignment}
        setAlignment={props.setAlignment}
        dialogOpen={filterPreviewOpen}
      />
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Settings
          </ListSubheader>
        }
        className={classes.root}
      >
        <ListItem
          button
          className={hideElementMap.image ? "hidden" : ""}
          onClick={(e) => handleClick("image")}
        >
          <ListItemIcon>
            <Image />
          </ListItemIcon>
          <ListItemText primary="Content Type" />
          {imageOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={imageOpen} timeout="auto" unmountOnExit>
          <ImageUpload
            selectedFilter={props.selectedFilter}
            setSelectedFilter={props.setSelectedFilter}
            campaign={props.campaign}
            canvasProps={props.canvasProps}
            updateCanvasProps={props.updateCanvasProps}
            uploadedSticker={props.uploadedSticker}
            setUploadedSticker={props.setUploadedSticker}
            uploadedApng={props.uploadedApng}
            setUploadedApng={props.setUploadedApng}
            uploadedGif={props.uploadedGif}
            setUploadedGif={props.setUploadedGif}
            uploadedThumbnail={props.uploadedThumbnail}
            setUploadedThumbnail={props.setUploadedThumbnail}
            uploadedBackground={props.uploadedBackground}
            setUploadedBackground={props.setUploadedBackground}
            imageUploadErr={props.imageUploadErr}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.thumbnail ? "hidden" : ""}
          onClick={(e) => handleClick("thumbnail")}
        >
          <ListItemIcon>
            <Apps />
          </ListItemIcon>
          <ListItemText primary="Thumbnail" />
          {thumbnailOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={thumbnailOpen} timeout="auto" unmountOnExit>
          <ThumbnailUpload
            selectedFilter={props.selectedFilter}
            setSelectedFilter={props.setSelectedFilter}
            campaign={props.campaign}
            uploadedThumbnail={props.uploadedThumbnail}
            setUploadedThumbnail={props.setUploadedThumbnail}
            imageUploadErr={props.imageUploadErr}
            canvasProps={props.canvasProps}
            updateCanvasProps={props.updateCanvasProps}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.lottie ? "hidden" : ""}
          onClick={(e) => handleClick("lottie")}
        >
          <ListItemIcon>
            <PlayCircleOutline />
          </ListItemIcon>
          <ListItemText primary="Lottie/JSON" />
          {lottieOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={lottieOpen} timeout="auto" unmountOnExit>
          <LottieUpload
            selectedFilter={props.selectedFilter}
            uploadedLottie={props.uploadedLottie}
            setUploadedLottie={props.setUploadedLottie}
            canvasProps={props.canvasProps}
            updateCanvasProps={props.updateCanvasProps}
            campaign={props.campaign}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.webAr ? "hidden" : ""}
          onClick={(e) => handleClick("webAr")}
        >
          <ListItemIcon>
            <Filter8 />
          </ListItemIcon>
          <ListItemText primary="WebAR With 8th Wall" />
          {webArOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={webArOpen} timeout="auto" unmountOnExit>
          <WebARConfiguration
            canvasProps={props.canvasProps}
            updateCanvasProps={props.updateCanvasProps}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.ar ? "hidden" : ""}
          onClick={(e) => handleClick("ar")}
        >
          <ListItemIcon>
            <Face />
          </ListItemIcon>
          <ListItemText primary="AR Lens" />
          {arLensOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={arLensOpen} timeout="auto" unmountOnExit>
          <RadioGroup
            onChange={(e, v) => props.setArContentType(v)}
            row
            aria-label="position"
            name="position"
            style={{ justifyContent: "center", display: "none" }}
            defaultValue={props.arContentType == "ryot" ? "ryot" : "deep_ar"}
          >
            <FormControlLabel
              value="deep_ar"
              control={<Radio color="primary" />}
              label="DeepAR"
              labelPlacement="top"
            />
            <FormControlLabel
              value="ryot"
              control={<Radio color="primary" />}
              label="Ryot"
              labelPlacement="top"
            />
          </RadioGroup>
          <ARUpload
            setErr={props.setErr}
            selectedFilter={props.selectedFilter}
            setSelectedFilter={props.setSelectedFilter}
            isARLoading={props.isARLoading}
            setIsARLoading={props.setIsARLoading}
            retailer={props.retailer}
            campaign={props.campaign}
            selectedArBinaries={props.selectedArBinaries}
            setSelectedArBinaries={props.setSelectedArBinaries}
            setSelectedDeepArVersion={props.setSelectedDeepArVersion}
            setUploadedThumbnail={props.setUploadedThumbnail}
            setDeepArBgRadio={props.setDeepArBgRadio}
            deepArBgRadio={props.deepArBgRadio}
            imageUploadErr={props.imageUploadErr}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.arExperience ? "hidden" : ""}
          onClick={(e) => handleClick("arExperience")}
        >
          <ListItemIcon>
            <img
              style={{ width: "1.5em", height: "1.5em", opacity: ".5" }}
              src={process.env.PUBLIC_URL + "/images/viewInAr.png"}
            />
          </ListItemIcon>
          <ListItemText primary="AR Experience" />
          {arExperienceOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={arExperienceOpen} timeout="auto" unmountOnExit>
          <ArExperienceUpload
            worldViewDuple={[
              props.uploadedWorldViewEffect,
              props.setUploadedWorldViewEffect,
            ]}
            arAudioDuple={[props.uploadedArAudio, props.setUploadedArAudio]}
            loadAudio={props.loadAudio}
            canvasProps={props.canvasProps}
            setAreSettingsUpdated={props.setAreSettingsUpdated}
            updateCanvasProps={props.updateCanvasProps}
            campaign={props.campaign}
            retailer={props.retailer}
            selectedFilter={props.selectedFilter}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.arPlacement ? "hidden" : ""}
          onClick={(e) => handleClick("arPlacement")}
        >
          <ListItemIcon>
            <ScreenRotation />
          </ListItemIcon>
          <ListItemText primary="AR Placement" />
          {arPlacementOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={arPlacementOpen} timeout="auto" unmountOnExit>
          <ArPlacement
            retailerId={props.retailer.retailer_id || 0}
            campaignId={props.campaign.campaign_id}
            filterId={props.selectedFilter.frame_id}
            canvasProps={props.canvasProps}
            updateCanvasProps={props.updateCanvasProps}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.orientation ? "hidden" : ""}
          onClick={(e) => handleClick("orientation")}
        >
          <ListItemIcon>
            <Rotate90DegreesCcw />
          </ListItemIcon>
          <ListItemText primary="Vertical/Horizontal Settings" />
          {orientationOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={orientationOpen} timeout="auto" unmountOnExit>
          <FormControl style={{ marginLeft: "50px" }} component="fieldset">
            <FormLabel component="legend">Selected Orientation</FormLabel>
            <RadioGroup
              style={{ marginLeft: "50px" }}
              aria-label="orientation"
              name="type"
              value={"" + props.canvasProps.settings.type}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={"0"}
                control={
                  <Radio
                    disabled={props.filterOrientationType == "horizontal"}
                  />
                }
                label="Vertical"
              />
              <FormControlLabel
                value={"3"}
                control={
                  <Radio disabled={props.filterOrientationType == "vertical"} />
                }
                label="Horizontal/Vertical"
              />
              <FormControlLabel
                value={"2"}
                control={
                  <Radio
                    disabled={props.filterOrientationType == "horizontal"}
                  />
                }
                label="Vertical/Horizontal"
              />
              <FormControlLabel
                value={"1"}
                control={
                  <Radio disabled={props.filterOrientationType == "vertical"} />
                }
                label="Horizontal"
              />
            </RadioGroup>
          </FormControl>
          <Collapse
            in={["3", "2"].includes(radioValue)}
            timeout="auto"
            unmountOnExit
          >
            <FormControl
              style={{ marginLeft: "15px", display: "inline-block" }}
              component="fieldset"
            >
              <FormLabel component="legend">Orientation</FormLabel>
              <RadioGroup
                style={{ marginLeft: "50px" }}
                aria-label="orientation"
                name="orienation"
                value={radioOrientationValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel value={"0"} control={<Radio />} label="Top" />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label="Middle"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio />}
                  label="Bottom"
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              style={{
                marginLeft: "15px",
                display: "inline-block",
                width: "30%",
              }}
              component="fieldset"
            >
              <FormLabel component="legend">Alignment</FormLabel>
              <RadioGroup
                style={{ marginLeft: "50px" }}
                aria-label="orientation"
                name="orientation-alignment2"
                value={radioAlignmentValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value={"0"}
                  control={<Radio />}
                  label="Left"
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label="Center"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio />}
                  label="Right"
                />
              </RadioGroup>
            </FormControl>
          </Collapse>
        </Collapse>
        <ListItem
          button
          className={hideElementMap.city ? "hidden" : ""}
          onClick={(e) => handleClick("city")}
        >
          <ListItemIcon>
            <LocationCity />
          </ListItemIcon>
          <ListItemText primary="City" />
          {cityOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={cityOpen} timeout="auto" unmountOnExit>
          <TextSettings
            eyeDropperColor={props.eyeDropperColor}
            setEyeDropperActive={props.setEyeDropperActive}
            eyeDropperActive={props.eyeDropperActive}
            settings={props.canvasProps.variables.city}
            textToDisplay={"City"}
            onTextChange={props.setCityText}
            updateCanvasProps={props.updateCanvasProps}
            fonts={props.fonts}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.date ? "hidden" : ""}
          onClick={(e) => handleClick("date")}
        >
          <ListItemIcon>
            <Today />
          </ListItemIcon>
          <ListItemText primary="Date" />
          {dateOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={dateOpen} timeout="auto" unmountOnExit>
          <TextSettings
            eyeDropperColor={props.eyeDropperColor}
            setEyeDropperActive={props.setEyeDropperActive}
            eyeDropperActive={props.eyeDropperActive}
            settings={props.canvasProps.variables.date}
            textToDisplay={"Date"}
            updateCanvasProps={props.updateCanvasProps}
            fonts={props.fonts}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.time ? "hidden" : ""}
          onClick={(e) => handleClick("time")}
        >
          <ListItemIcon>
            <QueryBuilder />
          </ListItemIcon>
          <ListItemText primary="Time" />
          {timeOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={timeOpen} timeout="auto" unmountOnExit>
          <TextSettings
            eyeDropperColor={props.eyeDropperColor}
            setEyeDropperActive={props.setEyeDropperActive}
            eyeDropperActive={props.eyeDropperActive}
            settings={props.canvasProps.variables.time}
            textToDisplay={"Time"}
            updateCanvasProps={props.updateCanvasProps}
            fonts={props.fonts}
          />
        </Collapse>
        <ListItem
          button
          className={hideElementMap.free_form ? "hidden" : ""}
          onClick={(e) => handleClick("free_form")}
        >
          <ListItemIcon>
            <TextFields />
          </ListItemIcon>
          <ListItemText primary="Free Form Text" />
          {freeformOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={freeformOpen} timeout="auto" unmountOnExit>
          <TextSettings
            eyeDropperColor={props.eyeDropperColor}
            setEyeDropperActive={props.setEyeDropperActive}
            eyeDropperActive={props.eyeDropperActive}
            settings={props.canvasProps.variables.freeformtext}
            textToDisplay={"Free Form Text"}
            updateCanvasProps={props.updateCanvasProps}
            fonts={props.fonts}
          />
        </Collapse>
        {props.selectedFilter.frame_type?.includes("places") && (
          <>
            <ListItem
              button
              className={hideElementMap.places_text ? "hidden" : ""}
              onClick={(e) => handleClick("places_text")}
            >
              <ListItemIcon>
                <TextFields />
              </ListItemIcon>
              <ListItemText primary="Places Dynamic Text" />
              {placesDynamicOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={placesDynamicOpen} timeout="auto" unmountOnExit>
              <TextSettings
                eyeDropperColor={props.eyeDropperColor}
                setEyeDropperActive={props.setEyeDropperActive}
                eyeDropperActive={props.eyeDropperActive}
                settings={
                  props.canvasProps.nonPartnerConfig ||
                  placesCanvasProps.nonPartnerConfig
                }
                textToDisplay={"Places Dynamic"}
                updateCanvasProps={props.updateCanvasProps}
                fonts={props.fonts}
              />
            </Collapse>
          </>
        )}
        <ListItem
          button
          className={hideElementMap.attribution ? "hidden" : ""}
          onClick={(e) => handleClick("attribution")}
        >
          <ListItemIcon>
            <TextFields />
          </ListItemIcon>
          <ListItemText primary="Attribution" />
          {attributionOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={attributionOpen} timeout="auto" unmountOnExit>
          <div style={{ padding: "0 20px" }}>
            <FormControlLabel
              color="primary"
              control={
                <Checkbox
                  checked={props.attributionActive}
                  color="primary"
                  onChange={(e, v) => {
                    props.setAttributionActive(+v);
                  }}
                  name="attributionActive"
                />
              }
              label="Active"
            />
            <TextField
              value={props.attributionText}
              onChange={(e) => props.setAttributionText(e.target.value)}
              style={{ marginBottom: "10px" }}
              fullWidth
              size="small"
              id="attribution-text"
              label="Attribution Text"
              variant="outlined"
            />
            <TextField
              value={props.attributionLink}
              onChange={(e) => props.setAttributionLink(e.target.value)}
              fullWidth
              size="small"
              id="attribution-link"
              label="Attribution Link"
              variant="outlined"
            />
          </div>
        </Collapse>
      </List>
    </Drawer>
  );
}

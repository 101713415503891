const timezones = 
[
    {"name":"US/Pacific",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"GMT",                    "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Abidjan",         "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Accra",           "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Addis_Ababa",     "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Algiers",         "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Asmara",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Asmera",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Bamako",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Bangui",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Banjul",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Bissau",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Blantyre",        "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Brazzaville",     "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Bujumbura",       "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Cairo",           "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Casablanca",      "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Ceuta",           "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Conakry",         "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Dakar",           "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Dar_es_Salaam",   "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Djibouti",        "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Douala",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/El_Aaiun",        "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Freetown",        "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Gaborone",        "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Harare",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Johannesburg",    "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Juba",            "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Kampala",         "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Khartoum",        "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Kigali",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Kinshasa",        "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Lagos",           "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Libreville",      "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Lome",            "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Luanda",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Lubumbashi",      "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Lusaka",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Malabo",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Maputo",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Maseru",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Mbabane",         "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Mogadishu",       "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Monrovia",        "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Nairobi",         "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Africa/Ndjamena",        "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Niamey",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Nouakchott",      "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Ouagadougou",     "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Porto-Novo",      "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Sao_Tome",        "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Timbuktu",        "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Africa/Tripoli",         "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Africa/Tunis",           "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Africa/Windhoek",        "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"America/Adak",           "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"America/Anchorage",      "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"America/Anguilla",       "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Antigua",        "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Araguaina",      "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Buenos_Aires",               "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"Argentina/Catamarca",                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/ComodRivadavia",             "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Cordoba",                    "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Jujuy",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/La_Rioja",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Mendoza",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Rio_Gallegos",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Salta",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/San_Juan",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/San_Luis",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Tucuman",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Argentina/Ushuaia",                                          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Aruba",                                          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Asuncion",                                          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Atikokan",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Atka",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"America/Bahia",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Bahia_Banderas",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Barbados",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Belem",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Belize",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Blanc-Sablon",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Boa_Vista",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Boise",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Buenos_Aires",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Cambridge_Bay",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Campo_Grande",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Cancun",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Caracas",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Catamarca",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Cayenne",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Cayman",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Chicago",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Chihuahua",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Coral_Harbour",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Cordoba",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Costa_Rica",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Creston",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Cuiaba",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Curacao",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Danmarkshavn",          "offset":"-00:00",          "offsetzone":"GMT"},
    {"name":"America/Dawson",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"America/Dawson_Creek",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Denver",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Detroit",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Dominica",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Edmonton",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Eirunepe",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/El_Salvador",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Ensenada",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"America/Fort_Nelson",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Fort_Wayne",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Fortaleza",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Glace_Bay",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Godthab",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Goose_Bay",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Grand_Turk",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Grenada",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Guadeloupe",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Guatemala",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Guayaquil",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Guyana",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Halifax",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Havana",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Hermosillo",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Indianapolis",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Knox",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Marengo",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Petersburg",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Tell_City",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Vevay",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Vincennes",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Indiana/Winamac",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Indianapolis",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Inuvik",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Iqaluit",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Jamaica",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Jujuy",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Juneau",          "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"America/Kentucky/Louisville",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Kentucky/Monticello",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Knox_IN",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Kralendijk",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/La_Paz",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Lima",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Los_Angeles",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"America/Louisville",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Lower_Princes",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Maceio",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Managua",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Manaus",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Marigot",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Martinique",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Matamoros",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Mazatlan",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Mendoza",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Menominee",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Merida",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Metlakatla",          "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"America/Mexico_City",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Miquelon",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Moncton",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Monterrey",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Montevideo",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Montreal",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Montserrat",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Nassau",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/New_York",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Nipigon",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Nome",          "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"America/Noronha",          "offset":"-02:00",          "offsetzone":"GMT"},
    {"name":"America/North_Dakota/Beulah",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/North_Dakota/Center",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/North_Dakota/New_Salem",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Ojinaga",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Panama",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Pangnirtung",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Paramaribo",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Phoenix",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Port_of_Spain",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Port-au-Prince",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Porto_Acre",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Porto_Velho",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Puerto_Rico",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Punta_Arenas",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Rainy_River",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Rankin_Inlet",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Recife",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Regina",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Resolute",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Rio_Branco",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Rosario",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Santa_Isabel",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"America/Santarem",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Santiago",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Santo_Domingo",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Sao_Paulo",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/Scoresbysund",          "offset":"-01:00",          "offsetzone":"GMT"},
    {"name":"America/Shiprock",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"America/Sitka",          "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"America/St_Barthelemy",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/St_Johns",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"America/St_Kitts",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/St_Lucia",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/St_Thomas",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/St_Vincent",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Swift_Current",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Tegucigalpa",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Thule",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Thunder_Bay",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Tijuana",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"America/Toronto",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"America/Tortola",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Vancouver",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"America/Virgin",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"America/Whitehorse",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"America/Winnipeg",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"America/Yakutat",          "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"America/Yellowknife",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Casey",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Davis",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/DumontDUrville",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Macquarie",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Mawson",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/McMurdo",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Palmer",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Rothera",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/South_Pole",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Syowa",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Troll",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Antarctica/Vostok",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Arctic/Longyearbyen",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Asia/Aden",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Almaty",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Amman",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Anadyr",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Asia/Aqtau",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Aqtobe",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Ashgabat",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Ashkhabad",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Atyrau",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Baghdad",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Bahrain",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Baku",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Asia/Bangkok",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Barnaul",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Beirut",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Bishkek",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Brunei",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Calcutta",          "offset":"+05:30",          "offsetzone":"GMT"},
    {"name":"Asia/Chita",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Asia/Choibalsan",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Chongqing",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Chungking",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Colombo",          "offset":"+05:30",          "offsetzone":"GMT"},
    {"name":"Asia/Dacca",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Damascus",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Dhaka",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Dili",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Asia/Dubai",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Asia/Dushanbe",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Famagusta",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Gaza",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Harbin",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Hebron",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Ho_Chi_Minh",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Hong_Kong",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Hovd",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Irkutsk",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Istanbul",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Jakarta",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Jayapura",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Asia/Jerusalem",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Kabul",          "offset":"+04:30",          "offsetzone":"GMT"},
    {"name":"Asia/Kamchatka",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Asia/Karachi",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Kashgar",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Kathmandu",          "offset":"+05:45",          "offsetzone":"GMT"},
    {"name":"Asia/Katmandu",          "offset":"+05:45",          "offsetzone":"GMT"},
    {"name":"Asia/Khandyga",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Asia/Kolkata",          "offset":"+05:30",          "offsetzone":"GMT"},
    {"name":"Asia/Krasnoyarsk",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Kuala_Lumpu",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Kuching",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Kuwait",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Macao",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Macau",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Magadan",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Asia/Makassar",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Manila",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Muscat",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Asia/Nicosia",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Novokuznetsk",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Novosibirsk",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Omsk",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Oral",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Phnom_Penh",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Pontianak",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Pyongyang",          "offset":"+08:30",          "offsetzone":"GMT"},
    {"name":"Asia/Qatar",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Qyzylorda",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Rangoon",          "offset":"+06:30",          "offsetzone":"GMT"},
    {"name":"Asia/Riyadh",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Asia/Saigon",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Sakhalin",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Asia/Samarkand",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Seoul",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Asia/Shanghai",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Singapore",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Srednekolymsk",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Asia/Taipei",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Tashkent",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Tbilisi",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Asia/Tehran",          "offset":"+03:30",          "offsetzone":"GMT"},
    {"name":"Asia/Tel_Aviv",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Asia/Thimbu",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Thimphu",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Tokyo",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Asia/Tomsk",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Ujung_Pandang",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Ulaanbaatar",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Ulan_Bator",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Asia/Urumqi",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Asia/Ust-Nera",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Asia/Vientiane",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Asia/Vladivostok",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Asia/Yakutsk",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Asia/Yangon",          "offset":"+06:30",          "offsetzone":"GMT"},
    {"name":"Asia/Yekaterinburg",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Asia/Yerevan",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Azores",          "offset":"-01:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Bermuda",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Canary",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Cape_Verde",          "offset":"-01:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Faeroe",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Faroe",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Jan_Mayen",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Madeira",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Reykjavik",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/South_Georgia",          "offset":"-02:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/St_Helena",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Atlantic/Stanley",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"Australia/ACT",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"GMAustralia/AdelaideT",          "offset":"+09:30",          "offsetzone":"GMT"},
    {"name":"Australia/Brisbane",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/Broken_Hill",          "offset":"+09:30",          "offsetzone":"GMT"},
    {"name":"Australia/Canberra",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/Currie",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/Darwin",          "offset":"+09:30",          "offsetzone":"GMT"},
    {"name":"Australia/Eucla",          "offset":"+08:45",          "offsetzone":"GMT"},
    {"name":"Australia/Hobart",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/LHI",          "offset":"+10:30",          "offsetzone":"GMT"},
    {"name":"Australia/Lindeman",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Australia/Lord_Howe",          "offset":"+10:30",          "offsetzone":"GMT"},
    {"name":"Australia/Melbourne",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/North",          "offset":"+03:30",          "offsetzone":"GMT"},
    {"name":"Australia/NSW",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/Perth",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Australia/Queensland",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/South",          "offset":"+09:30",          "offsetzone":"GMT"},
    {"name":"Australia/Sydney",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/Tasmania",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/Victoria",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Australia/West",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Australia/Yancowinna",          "offset":"+09:30",          "offsetzone":"GMT"},
    {"name":"Brazil/Acre",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"Brazil/DeNoronha",          "offset":"-02:00",          "offsetzone":"GMT"},
    {"name":"Brazil/East",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"Brazil/West",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"Canada/Atlantic",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"Canada/Central",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"Canada/Eastern",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"Canada/East-Saskatchewan",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"Canada/Mountain",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"Canada/Newfoundland",          "offset":"-03:30",          "offsetzone":"GMT"},
    {"name":"Canada/Pacific",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"Canada/Saskatchewan",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"Canada/Yukon",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"CET",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Chile/Continental",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"Chile/EasterIsland",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"CST6CDT",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"Cuba",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"EET",               "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Egypt",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Eire",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"EST",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"EST5EDT",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+0",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+1",          "offset":"-01:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+10",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+11",          "offset":"-11:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+12",          "offset":"-12:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+2",          "offset":"-02:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+3",          "offset":"-03:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+4",          "offset":"-04:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+5",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+6",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+7",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+8",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT+9",          "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT0",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-0",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-1",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-10",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-11",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-12",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-13",          "offset":"+13:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-14",          "offset":"+14:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-2",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-3",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-4",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-5",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-6",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-7",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-8",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Etc/GMT-9",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Etc/Greenwich",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/UCT",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/Universal",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/UTC",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Etc/Zulu",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Amsterdam",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Andorra",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Astrakhan",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Europe/Athens",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Belfast",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Belgrade",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Berlin",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Bratislava",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Brussels",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Bucharest",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Budapest",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Busingen",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Chisinau",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Copenhagen",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Dublin",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Gibraltar",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Guernsey",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Helsinki",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Isle_of_Man",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Istanbul",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Europe/Jersey",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Kaliningrad",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Kiev",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Kirov",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Europe/Lisbon",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Ljubljana",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/London",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Europe/Luxembourg",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Madrid",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Malta",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Mariehamn",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Minsk",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Europe/Monaco",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Moscow",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Europe/Nicosia",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Oslo",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Paris",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Podgorica",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Prague",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Riga",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Rome",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Samara",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Europe/San_Marino",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Sarajevo",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Saratov",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Europe/Simferopol",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Europe/Skopje",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Sofia",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Stockholm",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Tallinn",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Tirane",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Tiraspol",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Ulyanovsk",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Europe/Uzhgorod",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Vaduz",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Vatican",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Vienna",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Vilnius",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Volgograd",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Europe/Warsaw",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Zagreb",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Europe/Zaporozhye",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Europe/Zurich",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"GB-Eire",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"GMT+0",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"GMT0",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"GMT-0",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Greenwich",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Hongkong",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"HST",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"Iceland",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Indian/Antananarivo",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Indian/Chagos",          "offset":"+06:00",          "offsetzone":"GMT"},
    {"name":"Indian/Christmas",          "offset":"+07:00",          "offsetzone":"GMT"},
    {"name":"Indian/Cocos",          "offset":"+06:30",          "offsetzone":"GMT"},
    {"name":"Indian/Comoro",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Indian/Kerguelen",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Indian/Mahe",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Indian/Maldives",          "offset":"+05:00",          "offsetzone":"GMT"},
    {"name":"Indian/Mauritius",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Indian/Mayotte",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Indian/Reunion",          "offset":"+04:00",          "offsetzone":"GMT"},
    {"name":"Iran",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Israel",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"Jamaica",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"Japan",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Kwajalein",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Libya",          "offset":"+02:00",          "offsetzone":"GMT"},
    {"name":"MET",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Mexico/BajaNorte",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"Mexico/BajaSur",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"Mexico/General",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"MST",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"MST7MDT",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"Navajo",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"NZ",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"NZ-CHAT",          "offset":"+12:45",          "offsetzone":"GMT"},
    {"name":"Pacific/Apia",          "offset":"+13:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Auckland",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Bougainville",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Chatham",          "offset":"+12:45",          "offsetzone":"GMT"},
    {"name":"Pacific/Chuuk",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Easter",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Efate",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Enderbury",          "offset":"+13:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Fakaofo",          "offset":"+13:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Fiji",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Funafuti",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Galapagos",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Gambier",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Guadalcanal",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Guam",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Honolulu",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Johnston",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Kiritimati",          "offset":"+14:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Kosrae",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Kwajalein",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Majuro",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Marquesas",          "offset":"-09:30",          "offsetzone":"GMT"},
    {"name":"Pacific/Midway",          "offset":"-11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Nauru",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Niue",          "offset":"-11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Norfolk",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Noumea",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Pago_Pago",          "offset":"-11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Palau",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Pitcairn",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Pohnpei",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Ponape",          "offset":"+11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Port_Moresby",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Rarotonga",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Saipan",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Samoa",          "offset":"-11:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Tahiti",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Tarawa",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Tongatapu",          "offset":"+13:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Truk",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Wake",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Wallis",          "offset":"+12:00",          "offsetzone":"GMT"},
    {"name":"Pacific/Yap",          "offset":"+10:00",          "offsetzone":"GMT"},
    {"name":"Poland",          "offset":"+01:00",          "offsetzone":"GMT"},
    {"name":"Portugal",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"PRC",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"PST8PDT",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"ROC",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"ROK",          "offset":"+09:00",          "offsetzone":"GMT"},
    {"name":"Singapore",          "offset":"+08:00",          "offsetzone":"GMT"},
    {"name":"Turkey",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"UTC",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"Universal",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"US/Alaska",          "offset":"-09:00",          "offsetzone":"GMT"},
    {"name":"US/Aleutian",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"US/Arizona",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"US/Central",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"US/Eastern",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"US/East-Indiana",          "offset":"-05:00",          "offsetzone":"GMT"},
    {"name":"US/Hawaii",          "offset":"-10:00",          "offsetzone":"GMT"},
    {"name":"US/Indiana-Starke",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"US/Michigan",          "offset":"-06:00",          "offsetzone":"GMT"},
    {"name":"US/Mountain",          "offset":"-07:00",          "offsetzone":"GMT"},
    {"name":"US/Pacific-New",          "offset":"-08:00",          "offsetzone":"GMT"},
    {"name":"US/Samoa",          "offset":"-11:00",          "offsetzone":"GMT"},
    {"name":"UTC",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"WET",          "offset":"+00:00",          "offsetzone":"GMT"},
    {"name":"W-SU",          "offset":"+03:00",          "offsetzone":"GMT"},
    {"name":"Zulu",          "offset":"+00:00",          "offsetzone":"GMT"}
]

export default timezones;
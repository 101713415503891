import React, {useState, useEffect, useRef} from 'react';
import ARExperienceFilters from './ARExperienceFilters';
import {Typography, Box, Tabs, Tab} from '@material-ui/core';
import TabPanel from '../TabPanel';
import {Storage} from 'aws-amplify';
import 'typeface-roboto';
import EditFilter from '../Common/EditFilter.js';
import DeleteItem from '../Common/DeleteItem';
import { Link } from "react-router-dom";
import GeoLocations from '../Common/GeoLocations/GeoLocations';
import { getEnvironmentCampaignIds, getAPICall } from '../../CommonFunctions';
import { 
    getCampaignLocationList, 
    materialTableCarouselFilters, 
} from '../../ApiUtility/ApiUtility';

const requestConfig = {
    customPrefix: {
        public: 'retailers',
        protected: 'retailers',
        private: 'retailers'
    }
};

/**
 * Component used to display the designer for AR Experiences.
 *
 * @component
 */


function ARExperiences (){
    const [isCreateFilterDialogOpen, setIsCreateFilterDialogOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleteFilterDialogOpen, setIsDeleteFilterDialogOpen] = useState(false);
    const [isCreateGeoLocationsDialogOpen, setIsCreateGeoLocationsDialogOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [isLinksDialogOpen, setIsLinksDialogOpen] = useState(false);
    const [campaignLocationLinks, setCampaignLocationLinks] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({});
    const [campaignFilters, setCampaignFilters] = useState([]);
    const [campaign, setCampaign] = useState({name: "World View AR", campaign_id: getEnvironmentCampaignIds().arExperiences, retailer_id: 0});
    
    const [value, setValue] = useState(0);


    const tableRef = useRef();
    

    function handleChange(e, i){
        setValue(i);
    }

    function handleViewLinksClick(frame){
        setSelectedFilter(frame);
        setIsLinksDialogOpen(true);
    }

    const handleEditClose = e => {
        setSelectedFilter({});
        setEditOpen(false);
    }

    const updateCampaignLocationList = () => {
        getCampaignLocationList(campaign.campaign_id).then((fetchedCampaignLocations) => {
            setCampaignLocationLinks(fetchedCampaignLocations);
        });
    }

    const saveArExperiencesFile = async (e) => {
        console.log('in save ar experiences')
        setIsSaving(true);
        let arExperiences = await getARExperiences();
        let experiences = arExperiences.response.items.map(x => {
            return {
                experience_id: x.frame_id,
                experience_name: x.name,
                thumb_url: `/retailers/0/${getEnvironmentCampaignIds().arExperiences}/frames/${x.frame_id}_thumb.png`,
                asset_path:	`/retailers/0/${getEnvironmentCampaignIds().arExperiences}/frames/${x.frame_id}/ar-experience.glb`,
                active:	x.active,
                enable_sound: 0,
                config_url:	"",
                created_on:	x.createdOn,
                updated_on:	x.updatedOn}
        })
        let nextArExperienceData = {experiences}
        await updateARConfigFile(nextArExperienceData);
        setIsSaving(false);
    }

    const updateARConfigFile = (data) => {
        return new Promise((resolve, reject) => {
            Storage.put(`/ar-experiences/config.json`, data, requestConfig)
            .then(r => resolve(r))
            .catch(err => console.log("err: ", err));
        })
    }

    const getARExperiences = async () => {
        return new Promise((resolve, reject) => {
            let url = `/CameraPlus/frame/links`;
            getAPICall(url, {
                "frameAssociations": [
                    {
                        "entity_type_name": "campaign",
                        "entity_id": getEnvironmentCampaignIds().arExperiences
                    }
                ]
            })
            .then(response => resolve(response.data))
        })
    }

    const refresh = () => {
        saveArExperiencesFile();
        tableRef.current.onQueryChange()
    }



    useEffect(()=>{
        updateCampaignLocationList();
    }, []);


    return (
        <>
            <EditFilter
                editOpen={editOpen}
                handleEditClose={handleEditClose}
                selectedFilter={selectedFilter} 
                setSelectedFilter={setSelectedFilter}
                campaign={campaign}
                retailer={{retailer_id:0}}
                refreshData={refresh}
                frameAssociations={[{"entity_type_name":"campaign","entity_id":campaign.campaign_id}]}
            />
            <DeleteItem 
                open={isDeleteFilterDialogOpen}
                setIsDeleteOpen={setIsDeleteFilterDialogOpen}
                handleDeleteClose={e => setIsDeleteFilterDialogOpen(false)}
                selectedItem={selectedFilter} 
                setSelectedItem={setSelectedFilter}
                refreshData={refresh}
                campaign={campaign}
            />
            <Box style={{width:'100%'}}>
                <Box style={{textAlign: "left"}} pl={2}>
                    <Link style={{color: 'black'}} to={"/other-setups/non-sponsor"}>
                        <Typography style={{display: "inline-block", verticalAlign:"middle"}} variant="h6">
                            AR Experiences
                        </Typography>
                    </Link>
                </Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab id="geo_Experiences" label="Experiences" /> 
                    <Tab id="geo_locations" label="Geo Locations" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ARExperienceFilters
                        open={isLinksDialogOpen} 
                        setIsLinksDialogOpen={setIsLinksDialogOpen}
                        campaignLocationLinks={campaignLocationLinks}
                        tableRef={tableRef}
                        setSelectedFilter={setSelectedFilter}
                        setEditOpen={setEditOpen}
                        selectedFilter={selectedFilter} 
                        campaignFilters={campaignFilters}
                        setIsCreateFilterDialogOpen={setIsCreateFilterDialogOpen}
                        setIsDeleteFilterDialogOpen={setIsDeleteFilterDialogOpen}
                        handleViewLinksClick={handleViewLinksClick}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <GeoLocations 
                        open={isLinksDialogOpen} 
                        setIsLinksDialogOpen={setIsLinksDialogOpen}
                        handleViewLinksClick={handleViewLinksClick}
                        campaign_id={campaign.campaign_id}
                        updateCampaignLocationList={updateCampaignLocationList}
                        campaignLocationLinks={campaignLocationLinks}
                        setIsCreateGeoLocationsDialogOpen={setIsCreateGeoLocationsDialogOpen}
                        isCreateGeoLocationsDialogOpen={isCreateGeoLocationsDialogOpen}
                        selectedFilter={selectedFilter} 
                        setSelectedFilter={setSelectedFilter}
                        itemsToSelect={materialTableCarouselFilters}
                    />
                </TabPanel>
            </Box>
        </>
    )
  }

  export default ARExperiences;
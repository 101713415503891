import React, {useState, useEffect, useRef} from 'react';
import MaterialTable, {MTableBody, MTablePagination} from 'material-table';
import {Typography} from '@material-ui/core';
import {Create, Delete} from '@material-ui/icons';
import EditSponsor from './EditSponsor';
import DeleteSponsorDialog from './DeleteSponsor';
import {Link, Route} from "react-router-dom";

import {getStatusFilterStatement, getAPICall, formatSearch} from '../../CommonFunctions';
import {pageHistoryAtom} from '../../App';
import {useAtom} from 'jotai';

const table_styling = {headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold', borderRight: '1px solid white'},
        cellStyle: {borderRight: '.5px solid rgba(224, 224, 224, 1)'}};

/**
 * Component for Listing and Managing Sponsors.
 *
 * @component
 */

function SponsorTable (){
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedSponsorId, setSelectedSponsorId] = useState();
    const [selectedSponsor, setSelectedSponsor] = useState({active: 1});
    const [pageSize, setPageSize] = useState(10);
    const [pageHistory, setPageHistory] = useAtom(pageHistoryAtom);
    const tableRef = useRef();

    let filterBy  = null;

    const closeCreateDialog = () => {
        setIsCreateDialogOpen(false);
        setSelectedSponsor({active: 1});
        setSelectedSponsorId();
    }

    const refreshData = () =>{
        
        tableRef.current.onQueryChange();
    }

    useEffect(() => {
        return e => {setPageHistory(x => {
            x.sponsor.active = true;
            return x;
        })}
    }, [])

    if(pageHistory.sponsor.active && (!pageHistory.sponsor.hasOwnProperty("pageNumber") || pageHistory.sponsor.pageNumber === 0)){
        setPageHistory(x => {
            delete x.sponsor.active;
            x.sponsor.pageNumber = 0;
            return x;
        });
    }

    return (
        <div style={{width: "95%", margin: "2.5% auto"}}>
            <DeleteSponsorDialog
                open={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                selectedSponsorId={selectedSponsorId}
                setSelectedSponsorId={setSelectedSponsorId}
                refreshData={refreshData}
            /> 
            <EditSponsor
                open={isCreateDialogOpen}
                setIsCreateDialogOpen={setIsCreateDialogOpen}
                closeCreateDialog={closeCreateDialog}
                selectedSponsor={selectedSponsor}
                refreshData={refreshData}
            />
            <MaterialTable
                title="Sponsors"
                tableRef= { tableRef }
                options={{
                    filtering: true,
                    pageSize,
                    pageSizeOptions: [10, 20, 30]
                }}
                components={{
                    Pagination: props => {
                        delete props.classes.caption;
                        delete props.classes.selectRoot;
                        delete props.classes.toolbar;
                        return <MTablePagination {...props} onPageChange={props.onChangePage}/>
                    },
                    Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                        let nextFilterBy = value.filter(x => x !== filterBy);
                        filterBy = nextFilterBy[0];
                        props.onFilterChanged(columnId, [filterBy]);
                    }}/>
                }}
                onChangePage={p => {
                    console.log("in page change: ", p);
                    setPageHistory(x => {
                        x.sponsor.pageNumber = p;
                        return x;
                    })
                }}
                columns={[
                    { title: 'Id', field: 'retailerId', filtering: false, ...table_styling
                    },
                    { title: 'Sponsor Name', field: 'name', filtering: false, ...table_styling
                    },
                    { title: 'Status', field: 'active', lookup: { 0: 'Inactive', 1: 'Active' },
                        ...table_styling,
                    },
                    {
                        title: 'Actions', filtering: false,
                        render: rowData => (
                            <>
                            <Typography>
                                <Link onClick={e => {
                                    setSelectedSponsor(rowData);
                                    setIsCreateDialogOpen(true);
                                }}>
                                    <Create style={{color: "black"}}/>
                                        <span 
                                            style={{verticalAlign: "bottom",lineHeight: '36px', cursor: "pointer"}}>
                                            Edit
                                        </span>
                                </Link>&nbsp;&nbsp;
                                <Link 
                                    href="/delete_campaign"
                                    onClick={event => {
                                        event.preventDefault();
                                        setSelectedSponsorId(rowData.retailerId)
                                        setIsDeleteDialogOpen(true);
                                    }}
                                >
                                    <Delete 
                                        style={{color: "black"}}
                                    />
                                        <span 
                                            style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                            Delete
                                        </span>
                                </Link>&nbsp;&nbsp;
                                <Link to={`/sponsored/${rowData.retailerId}?sponsor_name=${rowData.name}`}
                                    style={{float: 'right'}}
                                >
                                        <span 
                                            style={{verticalAlign: "bottom",lineHeight: '36px', cursor: "pointer"}}>
                                            Details >>
                                        </span>
                                </Link>
                            </Typography>
                                <Route  path={"/other-setups/non-sponsor/edit_campaign?campaign_id="+rowData.campaign_id}>
                                    Here route
                                </Route>
                            </>
                        ),
                        headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold'}
                    },
                ]}
                actions={[
                    {
                      icon: 'add',
                      tooltip: 'Add New Sponsor',
                      isFreeAction: true,
                      onClick: (event) => setIsCreateDialogOpen(true)
                    }
                ]}
                data={query =>
                     new Promise((resolve, reject) => {
                        if(pageHistory.sponsor.active && pageHistory.sponsor.pageNumber) {
                            console.log("pageHistory: ", pageHistory);
                            query.page = pageHistory.sponsor.pageNumber;
                            setPageHistory(x => {
                                delete x.sponsor.active;
                                return x;
                            });
                        };
                        setPageSize(query.pageSize);
                        let {active} = getStatusFilterStatement(query.filters)
                        let url = `/CameraPlus/retailer/v2/list`
                        url += '?size=' + query.pageSize
                        url += '&from=' + (query.page * query.pageSize)
                        url += '&keyword=' + formatSearch(query.search);
                        if(query.filters[0]) url += `&active=${typeof active == "undefined" ? "" : active}`;
                        getAPICall(url)
                        .then(response => response.data)
                        .then(result => {
                            
                            resolve({
                                data: result.response.items,
                                page: pageHistory.sponsor.active ? (pageHistory.sponsor.pageNumber || 0) : query.page || 0,
                                totalCount: result.response.total
                            })
                        })
                    })
                  }
            />
        </div>
    )
  }

  export default SponsorTable;
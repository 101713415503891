import axios from "axios";
import moment from "moment";
import {
  getAPICall,
  scaleDownloadedCanvasProps,
  scaleCanvasPropsForUpload,
  dataURItoBlob,
  getEnvironment,
  getEnvironmentCampaignIds,
  readFileAsDataURL,
  formatSearch,
} from "../CommonFunctions";
import { Storage } from "aws-amplify";

const requestConfig = {
  customPrefix: {
    public: "BLOBS",
    protected: "BLOBS",
    private: "BLOBS",
  },
};

export function deleteCampaign(campaign_id) {
  return getAPICall(
    `/CameraPlus/retailer/campaign/delete?campaign_id=${campaign_id}`
  );
}

export function deleteSponsor(sponsor_id) {
  return getAPICall(`/CameraPlus/retailer/delete?id=${sponsor_id}`);
}

export function deleteLocation(location_id) {
  return getAPICall(`/CameraPlus/location/delete?id=${location_id}`);
}

export function searchFilters(url) {
  return getAPICall(url);
}

export function getFrameTags(part) {
  return getAPICall(`/CameraPlus/frame/tags/search?part=${part}&q`);
}

export function getFramesByIds(frameIds) {
  return getAPICall(`/CameraPlus/frame/search`, { frameIds });
}

/**
 * Sets App Badge Title Indicator.
 *
 * @param {string} action_type - Badge Title Indicator Type - "clear_all" or "notify".
 * @param {number} expiration - gmt time in milliseconds.
 * @param {string} url - Firebase deeplink URL.
 * @return {string} Formatted title with location name and rating.
 */
export function setAppBadgeTitleIndicator({ action_type, expiration, url }) {
  let searchParams = new URLSearchParams();
  if (action_type) searchParams.append("action_type", action_type);
  if (expiration) searchParams.append("expiration", expiration);
  if (url) searchParams.append("url", url);
  return getAPICall(
    `/CameraPlus/fcm/notification/send?${searchParams.toString()}`
  );
}

export function saveFrameTags(filterId, frameTags) {
  let modFrameTags = frameTags.map((x) => ({
    tag_name: x.tag_name,
    relevance: x.relevance,
  }));
  return getAPICall("/CameraPlus/frame/tags/save", {
    data: [{ frame_id: filterId, active: 1, tags: modFrameTags }],
  });
}

export function deleteFrameTags(filterId, tag_id) {
  return getAPICall("/CameraPlus/frame/tags/delete", {
    data: [{ tag_id, active: 1, frameIds: [filterId] }],
  });
}

export function getTags() {
  return getAPICall("/CameraPlus/tags/search?q");
}

export function getCollectionsList(type) {
  return getAPICall(`/CameraPlus/campaign/${type}/list`);
}

export function saveCollection(data, type) {
  return getAPICall(`/CameraPlus/campaign/${type}/save`, data);
}

export function deleteCollection(campaignId, type) {
  return getAPICall(
    `/CameraPlus/campaign/${type}/delete?campaign_id=${campaignId}`,
    {}
  );
}

export function getArPlacementCard({ key }) {
  let requestConfig = {
    customPrefix: {
      public: "retailers",
      protected: "retailers",
      private: "retailers",
    },
  };
  return Storage.get(key, requestConfig);
}

export async function deleteArPlacementCard({
  retailerId,
  campaignId,
  filterId,
}) {
  return await getAPICall(
    `/CameraPlus/sys/s3/fs/delete/retailers/${retailerId}/${campaignId}/frames/${filterId}/arPlacementCard.png`
  );
}

export function uploadArPlacementPromoCard({
  retailerId,
  campaignId,
  filterId,
  file,
}) {
  let requestConfig = {
    customPrefix: {
      public: "retailers",
      protected: "retailers",
      private: "retailers",
    },
  };
  return Storage.put(
    `/${retailerId}/${campaignId}/${filterId}/arPlacementCard.png`,
    file,
    requestConfig
  );
}

export function saveCollectionThumbnail(retailer_id, campaign_id, file, type) {
  let requestConfig = {
    customPrefix: {
      public: "retailers",
      protected: "retailers",
      private: "retailers",
    },
  };
  return Storage.put(
    `/${retailer_id}/${campaign_id}/${type}_logo.png`,
    file,
    requestConfig
  );
}

export function getCollectionThumbnail(retailer_id, campaign_id, type) {
  let requestConfig = {
    customPrefix: {
      public: "retailers",
      protected: "retailers",
      private: "retailers",
    },
  };
  return Storage.get(
    `/${retailer_id}/${campaign_id}/${type}_logo.png`,
    requestConfig
  );
}

export function deleteCollectionThumbnail(retailer_id, campaign_id, type) {
  let requestConfig = {
    customPrefix: {
      public: "retailers",
      protected: "retailers",
      private: "retailers",
    },
  };
  return getAPICall(
    `/CameraPlus/sys/s3/fs/delete/retailers/${retailer_id}/${campaign_id}/${type}_logo.png`
  );
}

export async function setGDriveHeader() {
  getAPICall("/gauth")
    .then((r) => {
      var o = XMLHttpRequest.prototype.open;
      XMLHttpRequest.prototype.open = function () {
        var res = o.apply(this, arguments);
        // var err = new Error();
        if (arguments[1].includes("/drive/v3/files/"))
          this.setRequestHeader(
            "Authorization",
            `Bearer ${r.data.access_token}`
          );
        // else if(arguments[1].includes("sys/s3/fs/")) this.setRequestHeader('x-amz-security-token',  token);
        return res;
      };
    })
    .catch((err) => console.log("gauth err: ", err));
}

export function getCampaigns(sponsor_id) {
  return new Promise((resolve, reject) => {
    getAPICall(
      `/CameraPlus/retailer/campaign/list/?retailer_id=${sponsor_id}&active=0&app_type=LIV_HD_CAM`
    )
      .then((result) => {
        resolve(result.data.response.items);
      })
      .catch((error) => {
        console.log(error.response);
      });
  });
}

export function getCampaignInfo(campaignId) {
  return new Promise((resolve, reject) => {
    getAPICall(`/CameraPlus/retailer/campaign/info?campaign_id=${campaignId}`)
      .then((result) => {
        resolve(result.data.response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  });
}

export function getLocationInfo(locationId) {
  return new Promise((resolve, reject) => {
    getAPICall(`/CameraPlus/location/info?id=${locationId}`)
      .then((result) => {
        resolve(result.data.response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  });
}

export function testCameraPlus() {
  getAPICall("/CameraPlus").then((x) => console.log("x: ", x));
}

export function createCampaign({ name, retailer_id }) {
  return getAPICall(`/CameraPlus/retailer/campaign/save`, {
    name,
    retailer_id,
    campaignForApp: "LIV_HD_CAM",
    geoRadius: "1.0",
    startDate: "2016-01-01T00:00:00.000+0000",
    endDate: "2116-12-31T23:59:00.000+0000",
    active: 1,
    optin_algorithm: 1,
    optin_menu: 1,
  });
}

export function updateCampaign({
  name,
  optin_algorithm,
  optin_menu,
  active,
  campaign_id,
  job,
  text_header,
}) {
  const start_date = !job ? null : job.start_date;
  const end_date = !job ? null : job.end_date;
  return getAPICall(`/CameraPlus/retailer/campaign/save`, {
    name,
    campaignForApp: "LIV_HD_CAM",
    geoRadius: "1.0",
    startDate: start_date || "2016-01-01T00:00:00.000+0000",
    endDate: end_date || "2116-12-31T23:59:00.000+0000",
    campaign_id,
    active,
    optin_algorithm,
    optin_menu,
    job,
    text_header,
  });
}

export function saveSponsor({
  active,
  address,
  city,
  country,
  enableDemo,
  name,
  state,
  zipCode,
  retailerId,
}) {
  return getAPICall(`/CameraPlus/retailer/save`, {
    retailerId,
    active,
    address,
    city,
    contract_end: "2016-12-31T23:59:00.000+0000",
    contract_start: "2016-01-01T00:00:00.000+0000",
    country,
    enableDemo,
    name,
    state,
    zipCode,
  });
}

export function getCampaignFilters(campaign_id) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/links`, {
      frameAssociations: [
        {
          entity_type_name: "campaign",
          entity_id: campaign_id || getEnvironmentCampaignIds().carousel,
        },
      ],
    }).then((result) => {
      resolve(result.data.response.items);
    });
  });
}

export function getFrameInfo(frameId) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/info?id=${frameId}`)
      .then((r) => {
        resolve(r.data.response.data || {});
      })
      .catch((err) => reject(err));
  });
}

export function getPremiumAttributionInfo(queryParam) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/attribution/info?${new URLSearchParams(
        queryParam
      ).toString()}`
    )
      .then((r) => {
        resolve(r.data.response.data || {});
      })
      .catch((err) => reject(err));
  });
}

export function savePremiumAttribution(data) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/attribution/${data.campaign_id ? "campaign" : "frame"}/save`,
      data
    )
      .then((r) => {
        resolve(r.data.response.data || {});
      })
      .catch((err) => reject(err));
  });
}

export function getCarouselFilters() {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/links`, {
      frameAssociations: [
        {
          entity_type_name: "campaign",
          entity_id: getEnvironmentCampaignIds().carousel,
        },
      ],
    }).then((result) => {
      resolve(result.data.response.items);
    });
  });
}

export function saveFilter({
  name,
  frame_tags,
  frame_id,
  frame_type,
  job,
  active,
  frame_content_type,
  movable,
  frameAssociations,
  optin_algorithm,
  optin_menu,
  frame_hint,
}) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/save`, {
      name,
      frame_tags,
      frame_id,
      frame_type,
      active,
      optin_algorithm,
      optin_menu,
      frameAssociations,
      frame_content_type,
      job,
      frame_hint,
      movable,
    })
      .then((r) => resolve(r.data.response.data))
      .catch((err) => reject(err));
  });
}

export function getCampaignLocationList(campaign_id) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/retailer/campaign/association/list?campaign_id=${campaign_id}`
    )
      .then((result) => {
        let campaign_locations = result.data.response.items.map(
          (x) => x.location
        );
        resolve(campaign_locations);
      })
      .catch((err) => reject(err));
  });
}

export function getFonts() {
  return new Promise(async function (resolve, reject) {
    // let url = await Storage.get("list/files/fonts");
    getAPICall(`/CameraPlus/sys/s3/fs/list/files/fonts?sort=0`)
      // axios.get(url)
      .then((result) => {
        let fonts = [];
        let head = document.head || document.getElementsByTagName("head")[0];
        result.data.response.items.forEach((x) => {
          let name_ = x.name.split(".");
          fonts.push(name_[0]);
          let css = `@font-face { font-family: ${
            name_[0]
          };src: url('${getEnvironment().replace(
            "http:",
            "https:"
          )}.cameraplus.co/CameraPlus/sys/s3/fs/files/fonts/${
            x.name
          }') format('truetype')}`;

          let style = document.createElement("style");
          style.type = "text/css";
          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }
          head.appendChild(style);
        });
        resolve(fonts);
      });
  });
}

export function getCanvasProps(filter_id, width) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/gc/sys/objblob/frame/${filter_id}/canvas_props?dt=${moment().format()}`
    )
      .then((r) => {
        if (!r.data) reject("No canvas props");
        let scaled_canvas_props = scaleDownloadedCanvasProps(r.data, width);
        resolve(scaled_canvas_props);
      })
      .catch((err) => reject(err));
  });
}

//Needs attention
export async function saveCanvasProps(filter_id, data) {
  return new Promise(async function (resolve, reject) {
    let one;
    let two;
    let r = await getAPICall(
      `/CameraPlus/gc/sys/objinst/frame/${filter_id}/info`
    );
    let data_cloned = JSON.parse(JSON.stringify(data));
    let props = scaleCanvasPropsForUpload(data_cloned);
    let tb_props = {
      frame: props.frame,
      ...props.photo_layer,
      sticker: props.upload_images.sticker,
      variables: props.variables,
      providerUrl: props.providerUrl,
    };
    if (props.nonPartnerConfig)
      tb_props.nonPartnerConfig = props.nonPartnerConfig;
    if (props.web_ar) tb_props.web_ar = props.web_ar;
    if (props.thumbnail) tb_props.thumbnail = props.thumbnail;
    if (props.arAudio) tb_props.arAudio = props.arAudio;
    if (props.arPlacement) tb_props.arPlacement = props.arPlacement;
    if (r.data.response.data) {
      one = Storage.put(
        `/${r.data.response.data.dir_name}/${filter_id}_canvas_props`,
        props,
        requestConfig
      );
      two = getAPICall(
        `/CameraPlus/gc/sys/objblob/frame/${filter_id}/tb_props`,
        tb_props
      );
      // two = Storage.put(`/${r.data.response.data.dir_name}/${filter_id}_tb_props`, tb_props, requestConfig);
    } else {
      one = getAPICall(
        `/CameraPlus/gc/sys/objblob/frame/${filter_id}/canvas_props`,
        props
      );
      two = getAPICall(
        `/CameraPlus/gc/sys/objblob/frame/${filter_id}/tb_props`,
        tb_props
      );
    }
    Promise.all([one, two])
      .then((r) => {
        saveLastModified(filter_id);
        resolve(r.data);
      })
      .catch((err) => reject(err));
  });
}

export function getLocationLinks(frame_id) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/location/list`, {
      frame_id: frame_id,
      frameAssociations: [{ entity_type_name: "location" }],
    }).then((result) => resolve(result.data.response.items));
  });
}

export function saveCampaignLocationAssociation(campaign_id, location_id) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/retailer/campaign/association/save`, [
      { campaign_id, location_id },
    ])
      .then((result) => resolve(result.data))
      .catch((err) => reject(err));
  });
}

export function deleteCampaignLocationAssociation(campaign_id, location_id) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/retailer/campaign/association/delete?campaign_id=${campaign_id}&location_id=${location_id}`
    )
      .then((result) => resolve(result.data))
      .catch((err) => reject(err));
  });
}

export function saveLocation(data) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/location/save`, data)
      .then((r) => resolve(r.data.response.data))
      .catch((err) => reject(err));
  });
}

export function getLocationList(type) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/location/list`, { location_type: type })
      .then((r) => resolve(r))
      .catch((err) => reject(err));
  });
}

export async function testFormDataCall(binary) {
  getAPICall(`/gauth?testDownload=true`);
}

export async function saveFilterImages(filter_id, images) {
  return new Promise(async function (resolve, reject) {
    let r = await getAPICall(
      `/CameraPlus/gc/sys/objinst/frame/${filter_id}/info`
    );
    await Promise.allSettled(
      images.map(async (x, i) => {
        if (x.key == "gif" || x.key == "apng") {
          if (r.data.response.code == 404) return "";
          return Storage.put(
            `/${r.data.response.data.dir_name}/${filter_id}_${x.key}`,
            images[i].file,
            requestConfig
          );
        } else {
          return getAPICall(
            `/upload?testUpload=true&path=gc%2Fsys%2Fobjblob%2Fframe%2F${filter_id}%2F${
              x.key
            }&content_type=${x.contentType || "image%2Fpng"}&content_encoding=${
              x.contentEncoding
            }`,
            {
              file: images[i].file.split
                ? images[i].file.split("base64,")[1]
                : images[i].file,
            }
          );
        }
      })
    );
    resolve();
  });
}

export async function saveThumbnailImage(
  filter_id,
  campaign_id,
  retailer_id,
  image
) {
  return new Promise(async function (resolve, reject) {
    console.log("retailer_id: ", retailer_id);
    if (image.constructor != Array) image = [image];
    let calls = image.map((x) => {
      let [base_64_prepend, image_data] = x.split("base64,");
      let content_type = base_64_prepend.includes("gif") ? "gif" : "png";
      return getAPICall(
        `/upload?testUpload=true&path=sys/s3/fs/retailers/${retailer_id}/${campaign_id}/frames/${filter_id}_thumb.${content_type}&content_type=image/${content_type}`,
        { file: image_data }
      );
    });
    try {
      await Promise.allSettled(calls);
      resolve();
    } catch (err) {
      reject();
    }
  });
}

export function saveAREffects(retailerId, campaignId, formData) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/sys/s3/fs/retailers/${retailerId}/${campaignId}/frames`,
      formData
    )
      .then((result) => {
        resolve(result.status);
      })
      .catch((err) => reject(err));
  });
}

export function deleteAREffect(retailerId, campaignId, filter_id, name) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/sys/s3/fs/delete/retailers/${retailerId}/${campaignId}/frames/${filter_id}_${name}`
    )
      .then((r) => resolve(r.data))
      .catch((err) => reject(err));
  });
}

export function deleteARExperience({ retailer_id, campaign_id, frame_id }) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/sys/s3/fs/delete/retailers/${retailer_id}/${campaign_id}/frames/${frame_id}/ar-experience.glb`
    )
      .then((r) => resolve(r.data))
      .catch((err) => reject(err));
  });
}

export function deleteARAudio({ retailer_id, campaign_id, frame_id, path }) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/sys/s3/fs/delete/retailers/${retailer_id}/${campaign_id}/frames/${frame_id}/${path}`
    )
      .then((r) => resolve(r.data))
      .catch((err) => reject(err));
  });
}

export function saveARJSON(retailerId, campaignId, filter_id, json) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/sys/s3/fs/retailers/${retailerId}/${campaignId}/frames/${filter_id}_scene_info.json`,
      json
    )
      .then((result) => {
        resolve(result.status);
      })
      .catch((err) => reject(err));
  });
}

export function getARJSON(retailerId, campaignId, filter_id) {
  return new Promise(function (resolve, reject) {
    getAPICall(
      `/CameraPlus/sys/s3/fs/retailers/${retailerId}/${campaignId}/frames/${filter_id}_scene_info.json`
    )
      .then((result) => {
        let data = { deep_ar_version: result.data.deep_ar_version, scenes: [] };
        if (result.data.scenes.length == 0) resolve(data);
        result.data.scenes.forEach((b, i) => {
          getAPICall(
            `/upload?getPresigned=true&path=retailers/${retailerId}/${campaignId}/frames/${filter_id}_${b}`
          )
            .then((x) => {
              data.scenes.push({ url: x.data.url, name: b });
              if (data.scenes.length == result.data.scenes.length)
                resolve(data);
            })
            .catch((err) => reject(err));
          // ({name: b})
        });
        // resolve(result.data);
      })
      .catch((err) => reject(err));
  });
}

export async function getStaticAssetPath(filter_id, type) {
  return new Promise(async function (resolve, reject) {
    let r = await getAPICall(
      `/CameraPlus/gc/sys/objinst/frame/${filter_id}/info`
    );
    if (r.data.response.code == 404) {
      reject("");
      return "";
    }
    let asset_result = await getAPICall(
      `/upload?getPresigned=true&path=BLOBS/${r.data.response.data.dir_name}/${filter_id}_${type}`
    );
    resolve(asset_result.data.url);
  });
}

export function getTemplateImagePath(path) {
  return new Promise(async function (resolve, reject) {
    let asset_result = await getAPICall(
      `/upload?getPresigned=true&path=${path}`
    );
    resolve(asset_result.data.url);
  });
}

// export function saveTemplateImage({retailer_id=0, campaign_id=0, frame_id, dataURL}){
//     if(campaign_id == 237 || campaign_id == 571) campaign_id = 0;
//     return new Promise(function(resolve, reject){
//         getAPICall(`/upload?testUpload=true&path=sys/s3/fs/retailers/${retailer_id}/${campaign_id}/frames/${frame_id}_tb.png&content_type=image/png`,
//             {file: dataURL})
//         .then(r => resolve(r.status))
//         .catch(err => reject(err));
//     })
// }

export function saveTemplateImage({
  retailer_id = 0,
  campaign_id = 0,
  frame_id,
  dataURL,
}) {
  let requestConfig = {
    customPrefix: {
      public: "retailers",
      protected: "retailers",
      private: "retailers",
    },
  };
  if (campaign_id == 237 || campaign_id == 571) campaign_id = 0;
  return Storage.put(
    `/${retailer_id}/${campaign_id}/frames/${frame_id}_tb.png`,
    dataURItoBlob(dataURL),
    requestConfig
  );
}

export async function loadFilterImage({
  filter_id,
  type,
  campaign_id = 0,
  retailer_id = 0,
  path,
}) {
  let mod_campaign_id = campaign_id;
  if (campaign_id == 237 || campaign_id == 571) mod_campaign_id = 0;
  return new Promise(async function (resolve, reject) {
    let filter_url;
    if (
      type !== "template" &&
      !type.includes("ti-") &&
      type !== "ar-experience"
    ) {
      try {
        filter_url = await getStaticAssetPath(filter_id, type);
      } catch (err) {
        reject();
      }
    } else if (type == "ti-png") {
      let result = await getAPICall(
        `/upload?getPresigned=true&path=sys/s3/fs/retailers/${retailer_id}/${campaign_id}/frames/${filter_id}_thumb.png`
      );
      filter_url = result.data.url;
    } else if (type == "ti-gif") {
      let result = await getAPICall(
        `/upload?getPresigned=true&path=sys/s3/fs/retailers/${retailer_id}/${campaign_id}/frames/${filter_id}_thumb.gif`
      );
      filter_url = result.data.url;
    } else if (type == "template") {
      let result = await getAPICall(
        `/upload?getPresigned=true&path=sys/s3/fs/retailers/${retailer_id}/${mod_campaign_id}/frames/${filter_id}_tb.png`
      );
      filter_url = result.data.url;
    } else if (type == "ar-experience") {
      let result = await getAPICall(
        `/upload?getPresigned=true&path=sys/s3/fs/retailers/${retailer_id}/${mod_campaign_id}/frames/${filter_id}/ar-experience.glb`
      );
      filter_url = result.data.url;
    }
    axios
      .get(filter_url)
      .then((r) => resolve(filter_url))
      .catch((err) => reject(err));
  });
}

export async function deleteFilter(filter_id, type) {
  return new Promise(async function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/delete?id=${filter_id}`)
      .then((r) => resolve(r.data))
      .catch((err) => reject(err));
  });
}

export async function deleteFilterImage({
  filter_id,
  type,
  retailerId,
  campaignId,
}) {
  return new Promise(async function (resolve, reject) {
    if (type == "gif" || type == "apng") {
      let r = await getAPICall(
        `/CameraPlus/gc/sys/objinst/frame/${filter_id}/info`
      );
      if (r.data.response.code != 404) {
        // await Storage.remove(
        //   `/${r.data.response.data.dir_name}/${filter_id}_${type}`,
        //   requestConfig
        // );
        getAPICall(
          `/CameraPlus/sys/s3/fs/delete/BLOBS/${r.data.response.data.dir_name}/${filter_id}_${type}`
        );
      }
    }
    if (type == "ti") {
      Promise.allSettled([
        getAPICall(
          `/CameraPlus/sys/s3/fs/delete/retailers/${retailerId}/${campaignId}/frames/${filter_id}_thumb.png`
        ),
        getAPICall(
          `/CameraPlus/sys/s3/fs/delete/retailers/${retailerId}/${campaignId}/frames/${filter_id}_thumb.gif`
        ),
      ])
        .then((r) => resolve(r))
        .catch((err) => reject(err));
    } else {
      // getAPICall(`/CameraPlus/sys/s3/fs/delete/retailers/${retailerId}/${campaignId}/frames/${filter_id}_${name}`)
      getAPICall(
        `/CameraPlus/gc/sys/objblob/frame/${filter_id}/${type}/delete?_=${moment().format()}`
      )
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    }
  });
}

export function saveFrameAssociations({
  campaign_id,
  frame_id,
  selectedLocations: locations,
}) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/association/save`, {
      campaign_id,
      frame_id,
      locations,
    })
      .then((r) => resolve(r))
      .catch((err) => reject(err));
  });
}

export function updateGeolocationLinks({ campaign_id, location_id, frames }) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/association/save`, {
      campaign_id,
      location_id,
      frames,
    })
      .then((r) => resolve(r.data.response))
      .catch((err) => reject(err));
  });
}

export function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
}

export function saveLastModified(frame_id) {
  return getAPICall(`/CameraPlus/frame/lastmodified/save?id=${frame_id}`);
}

export function materialTableCarouselFilters(query, applyChecked, campaignId) {
  return new Promise((resolve, reject) => {
    let url = `/CameraPlus/frame/links`;
    url += "?size=" + query.pageSize;
    url += "&from=" + query.page * query.pageSize;
    url += "&keyword=" + formatSearch(query.search);
    getAPICall(url, {
      frameAssociations: [
        {
          entity_type_name: "campaign",
          entity_id: campaignId || getEnvironmentCampaignIds().carousel,
        },
      ],
    })
      .then((response) => response.data)
      .then((result) => {
        resolve({
          data: applyChecked(query, result.response.items),
          page: query.page || 0,
          totalCount: result.response.total,
        });
      });
  });
}

export function getCarouselDetails() {
  return new Promise((resolve, reject) => {
    getAPICall(
      `/CameraPlus/retailer/campaign/list` +
        "?retailer_id=0&active=0&app_type=LIV_HD_CAM&keyword=LIV_HD_CAM_CAROUSEL"
    )
      .then((r) => resolve(r.data.response.items[0]))
      .catch((err) => reject(err));
  });
}

export function getARDetails() {
  return new Promise((resolve, reject) => {
    getAPICall(
      `/CameraPlus/retailer/campaign/list` +
        "?retailer_id=0&active=0&app_type=LIV_HD_CAM&keyword=LIV_HD_CAM_DEEPAR"
    )
      .then((r) => resolve(r.data.response.items[0]))
      .catch((err) => reject(err));
  });
}

export function materialTableCampaignLocationList(
  query,
  applyChecked,
  campaign_id
) {
  // let campaign_locations = result.data.response.items.map(x=> x.location);
  //         resolve(campaign_locations);
  return new Promise((resolve, reject) => {
    let url = `/CameraPlus/retailer/campaign/association/list?campaign_id=${campaign_id}`;
    url += "&size=" + query.pageSize;
    url += "&from=" + query.page * query.pageSize;
    url += "&keyword=" + formatSearch(query.search);
    getAPICall(url)
      .then((response) => response.data)
      .then((result) => {
        resolve({
          data: applyChecked(
            query,
            result.response.items.map((x) => x.location)
          ),
          page: query.page || 0,
          totalCount: result.response.total,
        });
      });
  });
}

export function materialTableSponsorList(query, applyChecked, campaign_id) {
  // let campaign_locations = result.data.response.items.map(x=> x.location);
  //         resolve(campaign_locations);
  return new Promise((resolve, reject) => {
    let url = `/CameraPlus/retailer/v2/list`;
    url += "&size=" + query.pageSize;
    url += "&from=" + query.page * query.pageSize;
    url += "&keyword=" + formatSearch(query.search);
    getAPICall(url, { withDelete: false, withInActive: true })
      .then((response) => response.data)
      .then((result) => {
        resolve({
          data: applyChecked(
            query,
            result.response.items.map((x) => x.location)
          ),
          page: query.page || 0,
          totalCount: result.response.total,
        });
      });
  });
}
// http://qa.cameraplus.co/CameraPlus/places/category/list?pid=0
export function getPlacesCategories() {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/places/category/list?pid=0`)
      .then((r) => {
        resolve(r.data.response.items);
      })
      .catch((err) => reject(err));
  });
}

export function getPlacesActiveFilters(body) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/list/count`, body)
      .then((r) => {
        resolve(r.data.response.data);
      })
      .catch((err) => reject(err));
  });
}

export function getLocations() {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/location/list`, { location_type: "location" })
      .then((r) => {
        resolve(r.data.response.items);
      })
      .catch((err) => reject(err));
  });
}

export function getLocationAssociations(data) {
  return new Promise(function (resolve, reject) {
    getAPICall(`/CameraPlus/frame/list`, data)
      .then((r) => {
        resolve(r.data.response.items);
      })
      .catch((err) => reject(err));
  });
}

export function getGDriveThumbnail(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
}

export function cloudSearchQuery(v, from) {
  return getAPICall(
    `/CameraPlus/aws/cloudsearch/query?q=${v}&size=10&from=${from}`
  );
}

export function saveRanks(data) {
  return getAPICall(`/CameraPlus/frame/rank/update`, data);
}

export function getArEffects() {
  return axios.get(
    "https://script.google.com/macros/s/AKfycbwxfNrUVciRBySfzMVgTcF_6LXOjclik67RQEB48Qbpf-uNSDBi9DvuUK80xKMTNKcyTA/exec"
  );
}

export function getArBinaries(effectId) {
  return axios.get(
    `https://script.google.com/macros/s/AKfycbwxfNrUVciRBySfzMVgTcF_6LXOjclik67RQEB48Qbpf-uNSDBi9DvuUK80xKMTNKcyTA/exec?effectId=${effectId}&getThumbnail=true`
  );
}

// https://script.google.com/macros/s/AKfycbwxfNrUVciRBySfzMVgTcF_6LXOjclik67RQEB48Qbpf-uNSDBi9DvuUK80xKMTNKcyTA/exec

import React, { useState, useEffect } from "react";
import {
  ArcRotateCamera,
  Vector3,
  HemisphericLight,
  SceneLoader,
  Sound,
  Tools,
} from "@babylonjs/core";
// import { Sound } from "@babylonjs/core/Audio/sound";
import { Button } from "@material-ui/core/";
import { VolumeOff, VolumeUp } from "@material-ui/icons";
import SceneComponent from "./SceneComponent"; // uses above component in same directory

let box;

let sound;
let sceneReference;
const onSceneReady = (scene, model_content, audioEffect) => {
  sceneReference = scene;
  try {
    // This creates and positions a free camera (non-mesh)
    var camera = new ArcRotateCamera(
      "camera1",
      Math.PI / 2,
      Math.PI / 2,
      6.0,
      Vector3.Zero(),
      scene
    );
    camera.wheelPrecision = 50;

    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero());

    const canvas = scene.getEngine().getRenderingCanvas();

    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;
    let desiredContent = model_content;
    if (model_content.includes("base64")) {
      let data = "data:base64," + model_content.split("base64,")[1];
      var raw_content = Tools.DecodeBase64(data);
      var blob = new Blob([raw_content]);
      var url = URL.createObjectURL(blob);
      desiredContent = url;
    }
    if (audioEffect) {
      sound = new Sound("audioEffect", audioEffect, scene, function () {}, {
        volume: 0.5,
        loop: true,
        autoplay: true,
      });
    }

    SceneLoader.Append(
      "",
      desiredContent,
      scene,
      function (scene) {
        scene.getMeshByID("__root__").position = new window.BABYLON.Vector3(
          0,
          -0.7,
          0
        );
      },
      undefined,
      undefined,
      ".glb"
    );
  } catch (err) {
    console.log("err: ", err);
  }
};

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = (scene, audioEffect) => {};

/**
 * Component used to display 3d models with Babylon.
 *
 * @component
 */

export default (props) => {
  const [muted, setMuted] = useState(false);

  const handleMute = (shouldMute) => {
    let volume = sound.getVolume();
    if (volume === 0 && !shouldMute) {
      sound.setVolume(0.5);
      setMuted(false);
    } else {
      sound.setVolume(0);
      setMuted(true);
    }
  };

  return (
    <div>
      {props.audioEffect && (
        <Button
          style={{ position: "absolute", right: 0, color: "white" }}
          id="volume"
          autoFocus
          color="inherit"
          onClick={(e) => handleMute()}
        >
          {muted ? <VolumeOff /> : <VolumeUp />}
        </Button>
      )}
      <SceneComponent
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender}
        id="my-canvas"
        width={props.canvasWidth}
        height={props.canvasHeight}
        effectSource={props.effectSource}
        effectUrl={props.effectUrl}
        audioEffect={props.audioEffect}
        engineOptions={{ audioEngine: true }}
      />
    </div>
  );
};

import React from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { withStyles } from "@material-ui/core/styles";
import { arrayMoveImmutable } from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { saveCollection } from "../../../ApiUtility/ApiUtility";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const sortZeroLast = (a, b) => {
  if (a.sort_order == 0) return 1;
  else if (b.sort_order == 0) return -1;
  return a.sort_order - b.sort_order;
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

/**
 * Component used to set the order of Popular collections.
 *
 * @component
 */

const SortableList = (props) => {
  let [collections, setCollections] = props.collectionsDuple;

  const onDrop = ({ removedIndex, addedIndex }) => {
    setCollections((items) => {
      let nextCollection = arrayMoveImmutable(items, removedIndex, addedIndex);
      nextCollection.forEach((x, i) => (x.sort_order = i + 1));
      saveCollection(
        nextCollection.map((x) => ({
          sort_order: x.sort_order,
          campaign_id: x.campaign_id,
        })),
        props.type
      );
      return nextCollection;
    });
  };

  return (
    <Dialog
      onClose={props.handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={props.dialogOpen}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={props.handleCloseDialog}
      >
        <span style={{ textTransform: "capitalize" }}>{props.type}</span>{" "}
        Collections Order
      </DialogTitle>
      <DialogContent style={{ minWidth: "300px" }} dividers>
        <List>
          <Container
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={onDrop}
          >
            {props.type &&
              collections.sort(sortZeroLast).map((c) => (
                <Draggable key={c.campaign_id}>
                  <ListItem style={{ cursor: "grab" }} className="drag-handle">
                    <ListItemText primary={c.campaign.name} />
                    <ListItemSecondaryAction>
                      <ListItemIcon>
                        <DragHandleIcon
                          style={{ cursor: "grab" }}
                          className="drag-handle"
                        />
                      </ListItemIcon>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Draggable>
              ))}
          </Container>
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SortableList;

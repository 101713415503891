import { getAPICall } from "../CommonFunctions";


/**
 * This is a function.
 *
 */
export function getAppList() {
  return new Promise((resolve, reject) => {
    getAPICall(`/CameraPlus/customer/list`)
      .then((r) => resolve(r.data.response.items))
      .catch((err) => reject(err));
  });
}

export function getExcludedApps({ frameId, campaignId }) {
  let path;
  if (frameId) path = `frame/list?frame_id=${frameId}`;
  else if (campaignId) path = `campaign/list?campaign_id=${campaignId}`;
  return new Promise((resolve, reject) => {
    getAPICall(`/CameraPlus/exclude/config/${path}`)
      .then((r) => resolve(r.data.response.items))
      .catch((err) => reject(err));
  });
}

export function saveToExcludedList({ frameId, campaignId, customerId }) {
  let path = frameId ? `frame` : `campaign`;
  let data = [
    {
      customer_id: customerId,
    },
  ];
  if (frameId) data[0].frame_id = frameId;
  else if (campaignId) data[0].campaign_id = campaignId;
  return new Promise((resolve, reject) => {
    getAPICall(`/CameraPlus/exclude/config/${path}/save`, data)
      .then((r) => resolve(r.data.response.items))
      .catch((err) => reject(err));
  });
}

export function removeFromExcludedList({ frameId, campaignId, customerId }) {
  let path = frameId
    ? `frame/delete?frame_id=${frameId}`
    : `campaign/delete/?campaign_id=${campaignId}`;
  return new Promise((resolve, reject) => {
    getAPICall(
      `/CameraPlus/exclude/config/${path}&customer_id=${customerId}`,
      {}
    )
      .then((r) => resolve(r.data.response.items))
      .catch((err) => reject(err));
  });
}

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDropzone } from "react-dropzone";
import ImageDialog from "../ImageDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  deleteArPlacementCard,
  uploadArPlacementPromoCard,
  getArPlacementCard,
} from "../../../ApiUtility/ApiUtility";

const styles = {
  uploadButton: {
    fontSize: "10px",
    padding: "5px 5px",
  },
  locationTypeInput: {
    width: "200px",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "center",
  },
  select: {
    width: "100%",
  },
}));

function UploadArPlacementCard(props) {
  const { retailerId, campaignId, filterId, canvasProps, updateCanvasProps } =
    props;
  console.log("change");
  const onDrop = async (file) => {
    let response = await uploadArPlacementPromoCard({
      retailerId,
      campaignId,
      filterId,
      file: file[0],
    });
    if (response.key) {
      let nextArPlacementProps = {
        ...canvasProps.arPlacement,
        promoCardKey: response.key,
      };
      updateCanvasProps("arPlacement", nextArPlacementProps);
    } else props.alertUser("Error uploading card");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (f) => onDrop(f),
  });

  return (
    <div {...getRootProps({ refKey: "innerRef" })}>
      <input {...getInputProps()} />
      <Button variant="contained" color="primary" style={styles.uploadButton}>
        Upload Image
      </Button>
    </div>
  );
}

/**
 * Component used to control uploading and deleting DeepAR Effects.
 * Hooks up to GoogleDrive to get effects and Thumbnails.
 *
 * @component
 */

export default function ARPlacement(props) {
  const { retailerId, campaignId, filterId, canvasProps, updateCanvasProps } =
    props;
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [promoCardUrl, setPromoCardUrl] = useState("");

  const handleDelete = async () => {
    let response = await deleteArPlacementCard({
      retailerId,
      campaignId,
      filterId,
    });
    let nextArPlacementProps = Object.assign({}, props.canvasProps.arPlacement);
    delete nextArPlacementProps.promoCardKey;
    if (response) {
      updateCanvasProps("arPlacement", nextArPlacementProps);
    }
  };

  const handleTooltipChange = (e) => {
    let nextArPlacement = {
      ...props.canvasProps.arPlacement,
      tooltip: e.target.value,
    };
    updateCanvasProps("arPlacement", nextArPlacement);
  };

  function alertUser(text) {
    setAlertText(text);
    setTimeout(() => {
      setAlertText("");
    }, 3000);
  }

  async function getSetPromoCardUrl() {
    const key = canvasProps.arPlacement?.promoCardKey;
    if (!key) return;
    let response = await getArPlacementCard({ key });
    if (response) setPromoCardUrl(response);
  }

  useEffect(() => {
    console.log("in arplacement promocardkey");
    getSetPromoCardUrl();
  }, [canvasProps.arPlacement?.promoCardKey]);

  return (
    <>
      <ImageDialog
        dialogOpen={dialogOpen}
        dialogType={"Ar Placement Promo Card"}
        handleCloseDialog={(e) => setDialogOpen(false)}
        img_src={promoCardUrl}
      />
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Alert
          className={!props.imageUploadErr ? "hidden" : ""}
          variant="outlined"
          severity="error"
        >
          {props.imageUploadErr}
        </Alert>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Asset</TableCell>
              <TableCell align="left">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="ARPlacementCard">
              <TableCell align="left">
                {!canvasProps.arPlacement?.promoCardKey ? (
                  <UploadArPlacementCard
                    canvasProps={canvasProps}
                    updateCanvasProps={updateCanvasProps}
                    filterId={filterId}
                    campaignId={campaignId}
                    retailerId={retailerId}
                    alertUser={alertUser}
                  />
                ) : (
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => setDialogOpen(true)}
                  >
                    View
                  </Link>
                )}
              </TableCell>
              <TableCell align="left">
                {canvasProps.arPlacement?.promoCardKey && (
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleDelete()}
                  >
                    Delete
                  </Link>
                )}
              </TableCell>
            </TableRow>
            <TableRow key="tooltipText">
              <TableCell component="th" scope="row">
                <TextField
                  id="tooltip-text-select"
                  className={classes.select}
                  variant="outlined"
                  size="small"
                  select
                  value={canvasProps.arPlacement?.tooltip}
                  onChange={handleTooltipChange}
                  style={{ marginBottom: "10px", marginTop: 10 }}
                  label="Tooltip Text"
                >
                  <MenuItem
                    id="tooltip-option-vertical"
                    value={"Point camera at a wall"}
                  >
                    Vertical
                  </MenuItem>
                  <MenuItem
                    id="tooltip-option-horizontal"
                    value={"Point camera at the floor"}
                  >
                    Horizontal
                  </MenuItem>
                </TextField>
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

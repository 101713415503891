import React, {useState, useEffect} from 'react';
import MaterialTable, {MTablePagination} from 'material-table';
import {Link, Typography, Box} from '@material-ui/core';
import {Create, Delete, Place, CropFree} from '@material-ui/icons';
import DeleteItem from '../Common/DeleteItem';
import {getLocations} from '../../ApiUtility/ApiUtility';
import EditLocation from './EditLocation';
import {createClient} from '../../CommonFunctions';

const table_styling = {headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold', borderRight: '1px solid white'},
        cellStyle: {borderRight: '.5px solid rgba(224, 224, 224, 1)'}};


/**
 * Component used to list and manage registered geofences.
 *
 * @component
 */

function GeoSettings(props){

    const [pageSize, setPageSize] = useState(10);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [editOpen, setEditOpen] = useState(false);
    const [isDeleteLocationDialogOpen, setIsDeleteLocationDialogOpen] = useState(false);
    const [locationClient, setLocationClient] = useState();

    const handleEditClose = e => {
        setSelectedLocation({});
        setEditOpen(false);
    }

    const refreshData = e => getLocations().then(setLocations);

    useEffect(() => {
        refreshData();
        createClient().then(client => {
            console.log({client});
            setLocationClient(client);
        });
    }, []);

    return(
        <Box style={{width: '90%', margin: '15px auto 0', position: 'relative'}}>
            <EditLocation
                editOpen={editOpen}
                handleEditClose={handleEditClose}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                refreshData={refreshData}
                locationClient={locationClient}
            />
            <DeleteItem 
                open={isDeleteLocationDialogOpen}
                setIsDeleteOpen={setIsDeleteLocationDialogOpen}
                handleDeleteClose={e => setIsDeleteLocationDialogOpen(false)}
                selectedItem={selectedLocation} 
                setSelectedItem={setSelectedLocation}
                refreshData={refreshData}
                campaign={selectedLocation}
            />
            <MaterialTable
                title="Locations"
                tableRef={props.tableRef}
                options={{
                    pageSize,
                    pageSizeOptions: [10, 20, 30]
                }}
                components={{
                    Pagination: props => {
                        delete props.classes.caption;
                        delete props.classes.selectRoot;
                        delete props.classes.toolbar;
                        return <MTablePagination {...props} onPageChange={props.onChangePage}/>
                    }
                }}
                columns={[
                    { title: 'ID', field: 'location_id', ...table_styling },
                    { title: 'Location Name', field: 'location_name', ...table_styling },
                    { title: 'City', field: 'city_name', ...table_styling },
                    { title: 'State', field: 'state_name', ...table_styling },
                    { title: 'GeoType', field: 'zipcode', ...table_styling,
                        render: rowData => rowData.geoFence.data.length == 1 ? <Place/> : <CropFree/>
                    },
                    {
                        title: 'Actions', filtering: false,
                        render: rowData => (
                            <Typography>
                                <Link 
                                    style={{cursor: "pointer"}}
                                    onClick={ e => {
                                        setSelectedLocation(rowData);
                                        setEditOpen(true)
                                    }}
                                >
                                    <Create style={{color: "black"}}/>
                                        <span 
                                            style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                            Edit
                                        </span>
                                </Link>&nbsp;&nbsp;
                                <Link 
                                    href="/delete_campaign"
                                    onClick={event => {
                                        event.preventDefault();
                                        setSelectedLocation(rowData);
                                        setIsDeleteLocationDialogOpen(true);
                                    }}
                                >
                                    <Delete 
                                        style={{color: "black"}}
                                        className="delete_carousel_filter"
                                    />
                                        <span 
                                            style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                            Delete
                                        </span>
                                </Link>
                            </Typography>
                        ),
                        headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold'}
                    }
                ]}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add New Location',
                        isFreeAction: true,
                        onClick: (e) => setEditOpen(true)
                    }
                ]}
                data={locations}
            />
        </Box>
    )
}

export default GeoSettings;
/**
 * Default properties assigned to all filters except places filters on creation.
 *
 */

const defaultCanvasProps = {
  upload_images: {
    positionedImage: {
      x: "0",
      y: "0",
    },
    sticker: {
      x: "0",
      y: "0",
    },
  },
  photo_layer: {
    x: 0,
    y: 0,
    w: 1080,
    h: 1440,
    photolayer: "behind",
  },
  variables: {
    city: {
      textFont: "Arial",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "169",
      lineBreak: "false",
    },
    date: {
      textFont: "Arial",
      format: "MM/DD/YY",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "169",
      lineBreak: "false",
    },
    time: {
      textFont: "Arial",
      format: "h:mm",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "150",
      lineBreak: "false",
    },
    freeformtext: {
      textFont: "Arial",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "150",
      text: "Free Form Text",
      lineBreak: "false",
    },
  },
  settings: {
    type: 0,
    orienation: 0,
    alignment: 0,
  },
  frame: {
    width: "1080",
    height: "1440",
  },
};

export default defaultCanvasProps;

/**
 * Default properties assigned to places filters on creation.
 *
 */

export const placesCanvasProps = {
  upload_images: {
    positionedImage: {
      x: "0",
      y: "0",
    },
    sticker: {
      x: "0",
      y: "0",
    },
  },
  nonPartnerConfig: {
    showPin: true,
    textFont: "Arial",
    textColor: "d42fa8",
    textSize: "50",
    textSizeMin: "40",
    x: "0",
    y: "0",
    strokeColor: "transparent",
    strokeWidth: "0",
    shadowColor: "ffffff",
    shadowBlur: 0,
    shadowOffsetX: "0",
    shadowOffsetY: "0",
    shadowOpacity: 1,
    alignment: "left",
    length: "150",
    lineBreak: "false",
  },
  photo_layer: {
    x: 0,
    y: 0,
    w: 1080,
    h: 1440,
    photolayer: "behind",
  },
  variables: {
    city: {
      textFont: "Arial",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "169",
      lineBreak: "false",
    },
    date: {
      textFont: "Arial",
      format: "MM/DD/YY",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "169",
      lineBreak: "false",
    },
    time: {
      textFont: "Arial",
      format: "h:mm",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "150",
      lineBreak: "false",
    },
    freeformtext: {
      textFont: "Arial",
      textColor: "d42fa8",
      textSize: "50",
      textSizeMin: "15",
      x: "0",
      y: "0",
      strokeColor: "transparent",
      strokeWidth: "0",
      shadowColor: "ffffff",
      shadowBlur: 0,
      shadowOffsetX: "0",
      shadowOffsetY: "0",
      shadowOpacity: 1,
      display: "false",
      alignment: "left",
      length: "150",
      text: "Free Form Text",
      lineBreak: "false",
    },
  },
  settings: {
    type: 0,
    orienation: 0,
    alignment: 0,
  },
  frame: {
    width: "1080",
    height: "1440",
  },
};

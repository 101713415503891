import React, {useState, useEffect} from 'react';
import MaterialTable, {MTablePagination} from 'material-table';
import {Link, Typography, CircularProgress} from '@material-ui/core';
import {ChevronRight, KeyboardArrowDown, DoubleArrow} from '@material-ui/icons';
import { getPlacesActiveFilters} from '../../ApiUtility/ApiUtility';
import { getEnvironmentCampaignIds} from '../../CommonFunctions';

const table_styling = {headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold', borderRight: '1px solid white'},
        cellStyle: {borderRight: '.5px solid rgba(224, 224, 224, 1)'}};


/**
 * Component for listing Foursquare categories/subcategories. Links to screen for setting filters up under categories.
 *
 * @component
 */        

function CategoriesTable(props){

    const [pageSize, setPageSize] = useState(10);
    const [activeCategories, setActiveCategories] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const formatCategories = async() => {
        setIsLoading(true);
        if(!props.categories[0] || activeCategories.length == 0){
            if(props.categories.length > 0) setIsLoading(false);
            let categories_copy = props.categories.slice(0);
            await getCategoryCounts(categories_copy)
            return setCategoryList(categories_copy);
        } 
        let index;
        let newCategories;
        let nextCategories = props.categories.slice(0);
        activeCategories.forEach((activeCategory, categoryIndex) => {
            index = nextCategories.findIndex(c => c.id == activeCategory);
            newCategories = nextCategories[index].categories.map(x => {
                x.level = categoryIndex + 1;
                return x;
            })
            nextCategories = [
                ...nextCategories.slice(0, index+1),
                ...newCategories,
                ...nextCategories.slice(index+1)
            ];
        })
        await getCategoryCounts(nextCategories);
        setIsLoading(false)
        setCategoryList(nextCategories);
    }

    const addActiveCategories = (rowData) => {
        let nextCategories = activeCategories.slice(0);
        nextCategories.push(rowData.id); 
        setActiveCategories(nextCategories);
    }
    
    const removeActiveCategories = (rowData) => {
        let index = activeCategories.findIndex(x => x == rowData.id);
        setActiveCategories(activeCategories.slice(0, index));
    }

    const getCategoryCounts = async (categories) => {
        let categoryIds = categories.filter(x => x.active_count == null).map(x => x.id);
        let countsCalls = await Promise.allSettled(categoryIds.map(x => {
            return getPlacesActiveFilters({"frameAssociations":
                [{"entity_type_name":"campaign","entity_id": getEnvironmentCampaignIds().places},
                {"entity_type_name":"category","entity_id":x}]
            })
        }));
        categoryIds.forEach((cid, i) => categories.find(c => c.id == cid).active_count = countsCalls[i].value)
    }

    useEffect(() => {
        formatCategories();
    }, [activeCategories, props.categories]);

    useEffect(() => {
        categoryList.forEach(x => delete x.active_count);
        let nextCategories = categoryList.slice(0);
        setIsLoading(true);
        getCategoryCounts(nextCategories).then(() => {
            setIsLoading(false)
            setCategoryList(nextCategories);
        });
    }, [props.activeCategory])

    return(
        <>
        {isLoading && <CircularProgress size={24} style={{position: "absolute", top: '12px', zIndex:10}}/>}
        <MaterialTable
            title="Categories and Subcategories"
            tableRef={props.tableRef}
            options={{
                pageSize,
                pageSizeOptions: [10, 20, 30]
            }}
            components={{
                Pagination: props => {
                    delete props.classes.caption;
                    delete props.classes.selectRoot;
                    delete props.classes.toolbar;
                    return <MTablePagination {...props} onPageChange={props.onChangePage}/>
                }
             }}
            columns={[
                { 
                    title: 'Category Name', 
                    field: 'category_name', 
                    ...table_styling,
                    render: rowData => {
                        let nameStyle = {cursor: "pointer"};
                        let chevronStyle = {verticalAlign: 'bottom'}
                        if(rowData.parent_id){
                            nameStyle.marginLeft = 10 * rowData.level + 'px';
                            nameStyle.fontSize =  1 - (.05 * rowData.level) + 'rem';
                            chevronStyle.fontSize = 1.5 - (.2 * rowData.level) + 'rem';
                        } 
                        return (<Typography
                                style={nameStyle}
                                onClick={ e => {
                                    if(activeCategories.includes(rowData.id)) removeActiveCategories(rowData);
                                    else addActiveCategories(rowData);
                                }}
                            >
                                {rowData.category_name}
                                {rowData.categories.length > 0 && !activeCategories.includes(rowData.id)  && <ChevronRight style={chevronStyle}/>}
                                {rowData.categories.length > 0 && activeCategories.includes(rowData.id) && <KeyboardArrowDown style={chevronStyle}/>}
                            </Typography>)
                        }
                },
                { title: 'No. of Active Filters', field: 'active_count', ...table_styling},
                { title: 'Details', field: 'state_name', ...table_styling,
                render: rowData => {
                    let chevronStyle = {verticalAlign: 'top'};
                    let nameStyle = {cursor: "pointer"};
                    if(rowData.parent_id){
                        nameStyle.marginLeft = 10 * rowData.level + 'px';
                        nameStyle.fontSize =  1 - (.05 * rowData.level) + 'rem';
                        chevronStyle.fontSize = 1.5 - (.2 * rowData.level) + 'rem';
                    } 
                    return (
                        <Typography>
                            <Link 
                                style={nameStyle}
                                onClick={ e => {
                                    props.setActiveCategory(rowData);
                                    props.placesFiltersTableRef.current.onQueryChange();
                                    // props.placesFiltersTableRef.current.onQueryChange();
                                }}
                            >
                                Details
                                <DoubleArrow style={chevronStyle}/>
                            </Link>&nbsp;&nbsp;
                        </Typography>
                    )}},
            ]}
            data={categoryList}
        />
        </>
    )
}

export default CategoriesTable;
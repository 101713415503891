import React, { useState, useEffect } from "react";
import { Stage, Layer, Image, Label } from "react-konva";
import FilterDesignerControls from "./FilterDesignerControl";
import CanvasText from "./CanvasText";
import useImage from "use-image";
import {
  parseStringToInt,
  getXOffset,
  getYOffset,
  transformCanvasProps,
} from "../../CommonFunctions";
import { getStaticAssetPath } from "../../ApiUtility/ApiUtility";
import LottiePreview from "./LottiePreview";
import "../../App.css";
import ARPreview from "./FilterDesignerComponents/ARPreview";
import GIFImage from "./FilterDesignerComponents/GIF";
import APNGImage from "./FilterDesignerComponents/APNG";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import BabylonModelViewer from "./FilterDesignerComponents/BabylonModelViewer";

let canvasTop;
let canvasLeft;

/**
 * Component used to set display static stickers on preview canvas;
 *
 * @component
 */
const StickerImage = (props) => {
  let scale = (window.innerHeight * 0.6375) / 1080;
  const { uploadedSticker } = props;

  const [imageUrl, setImageUrl] = useState("");
  const [imageNode, setImageNode] = useState(null);
  const [stickerHeight, setStickerHeight] = useState();
  const [stickerWidth, setStickerWidth] = useState();

  if (
    props.alignment == "horizontal" &&
    props.filterOrientationType == "vertical"
  )
    scale *= 0.75;
  else if (props.alignment == "horizontal") scale *= 0.75;
  else if (
    props.alignment == "vertical" &&
    props.filterOrientationType == "horizontal"
  )
    scale = 0.88;

  const [image, status] = useImage(imageUrl, "Anonymous");

  const handleStickerPosition = (sticker_coordinates) => {
    let { filterOrientationType } = props;
    if (filterOrientationType == "horizontal") scale /= 0.75;
    let nextSettings = JSON.parse(
      JSON.stringify(props.canvasProps.upload_images)
    );
    let modCanvasProps = JSON.parse(JSON.stringify(props.canvasProps));
    let x_offset = getXOffset({
      alignment: props.alignment,
      canvasProps: modCanvasProps,
      filterOrientationType,
    });
    let y_offset = getYOffset({
      alignment: props.alignment,
      canvasProps: modCanvasProps,
      filterOrientationType,
    });

    nextSettings.positionedImage.x = sticker_coordinates.x - x_offset;
    nextSettings.positionedImage.y = sticker_coordinates.y - y_offset;
    if (!nextSettings.sticker) nextSettings.sticker = {};
    nextSettings.sticker.x = sticker_coordinates.x - x_offset;
    nextSettings.sticker.y = sticker_coordinates.y - y_offset;

    props.updateCanvasProps("upload_images", nextSettings);
  };

  useEffect(() => {
    if (uploadedSticker.url) {
      setImageUrl(uploadedSticker.url);
    } else {
      setImageUrl("");
      setImageNode(null);
    }
  }, [uploadedSticker]);

  useEffect(() => {
    if (!imageNode) return;
    setStickerHeight(imageNode.height * scale);
    setStickerWidth(imageNode.width * scale);
  }, [imageNode]);

  useEffect(() => {
    if (status == "loaded") setImageNode(image);
  }, [status]);

  if (!!props.hide) return <Label />;

  return (
    <Image
      x={parseStringToInt(props.canvasProps.upload_images.positionedImage.x)}
      y={parseStringToInt(props.canvasProps.upload_images.positionedImage.y)}
      height={stickerHeight}
      width={stickerWidth}
      draggable
      image={imageNode}
      onDragEnd={(e) => {
        handleStickerPosition({
          x: parseInt(e.target.x()),
          y: parseInt(e.target.y()),
        });
      }}
    />
  );
};

/**
 * Component used to preview full size background images on canvas.
 *
 * @component
 */
const FilterImage = (props) => {
  let scale = 1;
  const { uploadedBackgroundUrl, selectedFilter } = props;

  const [imageUrl, setImageUrl] = useState("");
  const [imageNode, setImageNode] = useState(null);

  if (
    props.alignment == "horizontal" &&
    props.filterOrientationType == "vertical"
  )
    scale = 0.75;
  else if (
    props.alignment == "vertical" &&
    props.filterOrientationType == "horizontal"
  )
    scale = 1 / 0.75;

  const handleHorizontalImage = () => {
    let type = props.canvasProps.settings.type;
    let nextSettings = props.canvasProps.settings;
    if (type != 1 && type != 3) {
      nextSettings.type = 1;
      props.updateCanvasProps("settings", nextSettings);
      props.setAlignment("horizontal");
    } else {
      props.setFilterOrientationType("horizontal");
    }
  };

  const handleVerticalImage = () => {
    let type = props.canvasProps.settings.type;
    let nextSettings = props.canvasProps.settings;
    if (type != 0 && type != 2) {
      nextSettings.type = 0;
      props.updateCanvasProps("settings", nextSettings);
      props.setAlignment("vertical");
    } else {
      props.setFilterOrientationType("vertical");
    }
  };

  const getXOffset = (props) => {
    const {
      canvasProps: {
        settings: { type, alignment },
      },
    } = props;
    if (type == 0 || type == 1) return 0;
    else if (type == 3 && props.alignment == "vertical")
      return 0 - (window.innerHeight * 0.85) / (2 / alignment);
    else if (type == 2 && props.alignment == "horizontal")
      return (
        0 +
        (window.innerHeight * 0.85 * scale -
          window.innerHeight * 0.6375 * scale) /
          (2 / alignment)
      );
  };

  const getYOffset = (props) => {
    const {
      canvasProps: {
        settings: { type, orienation },
      },
    } = props;
    if (type == 0 || type == 1) return 0;
    else if (type == 3 && props.alignment == "vertical")
      return (
        (window.innerHeight * 0.85 - window.innerHeight * 0.6375) /
        (2 / orienation)
      );
    else if (type == 2 && props.alignment == "horizontal")
      return (
        -1 *
        ((window.innerHeight * 0.85 * scale -
          window.innerHeight * 0.6375 * scale) /
          (2 / orienation))
      );
  };

  const [image, status] = useImage(imageUrl, "Anonymous");

  useEffect(() => {
    const checkSetHostedBackground = async () => {
      if (!uploadedBackgroundUrl.source) return;
      let url = await getStaticAssetPath(selectedFilter.frame_id, "bi");
      props.setUploadedBackground({ source: "remote", file: url });
      setImageUrl(url);
    };
    checkSetHostedBackground();
  }, [selectedFilter.frame_id]);

  useEffect(() => {
    if (!!uploadedBackgroundUrl.source) {
      console.log("uploadedBackgroundUrl: ", uploadedBackgroundUrl);
      setImageUrl(uploadedBackgroundUrl.file);
    } else {
      setImageUrl("");
      setImageNode(null);
    }
  }, [uploadedBackgroundUrl]);

  useEffect(() => {
    console.log("status: ", status);
    if (status == "loaded" && image.height == 1080 && image.width == 1440) {
      setImageNode(image);
      handleHorizontalImage();
    } else if (
      status == "loaded" &&
      image.height !== 1440 &&
      image.width !== 1080
    ) {
      props.setUploadedBackground(false);
      props.setImageUploadErr("Image must be 1440 X 1080");
      props.setUploadedBackgroundUrl(null);
    } else if (
      status == "loaded" &&
      image.height == 1440 &&
      image.width == 1080
    ) {
      handleVerticalImage();
      setImageNode(image);
    }
  }, [status]);
  return (
    <Image
      x={getXOffset(props)}
      y={getYOffset(props)}
      key={1}
      width={
        props.orientation_type == 1 || props.orientation_type == 3
          ? ((window.innerHeight * 0.85) / 0.75) * scale
          : window.innerHeight * 0.6375 * scale
      }
      height={window.innerHeight * 0.85 * scale}
      image={imageNode}
    />
  );
};

/**
 * Main Designer component that shows filter previews and contains controls for the various filter elements.
 *
 * @component
 */
export default function FilterDialog(props) {
  const orientation_type = props.canvasProps.settings.type;

  const [uploadedBackgroundUrl, setUploadedBackgroundUrl] = useState(null);
  const [imageUploadErr, setImageUploadErr] = useState(false);
  const [filterOrientationType, setFilterOrientationType] = useState(null);
  const [freeformtext, setFreeformtext] = useState("Free Form Text");
  const [cityText, setCityText] = useState("City");
  const [background, setBackground] = useState("white");
  const [eyeDropperActive, setEyeDropperActive] = useState(false);
  const [eyeDropperColor, setEyeDropperColor] = useState({});
  const [showHoveringSwatch, setShowHoveringSwatch] = useState(false);
  const [isARLoading, setIsARLoading] = useState(false);

  const getModCanvasProps = () => {
    if (!props.canvasProps.settings.type) return props.canvasProps;
    let modCanvasProps = JSON.parse(JSON.stringify(props.canvasProps));
    let x_offset = getXOffset({
      alignment: props.alignment,
      canvasProps: modCanvasProps,
      filterOrientationType,
    });
    let y_offset = getYOffset({
      alignment: props.alignment,
      canvasProps: modCanvasProps,
      filterOrientationType,
    });
    let d = null;
    let scaleIn = null;
    return transformCanvasProps(
      modCanvasProps,
      filterOrientationType,
      props.alignment,
      x_offset,
      y_offset,
      d,
      scaleIn
    );
  };

  const eyeDropperOnMove = (e) => {
    if (!eyeDropperActive) return;
    let { top, left } = props.stageRef.current.content.getBoundingClientRect();
    let swatchX = e.evt.pageX;
    let swatchY = e.evt.pageY;
    let { x, y } = props.stageRef.current.getPointerPosition();
    let ctx = props.stageRef.current.content.children[0].getContext("2d");
    let scale =
      parseFloat(props.stageRef.current.content.children[0].style.width) /
      props.stageRef.current.content.children[0].width;
    let pxData = ctx.getImageData(x / scale, y / scale, 5, 5);
    let rgb = { r: pxData.data[0], g: pxData.data[1], b: pxData.data[2] };
    // setEyeDropperColor(rgb);
    document.getElementById("color").style.left = `${
      swatchX + 15 + canvasLeft - left
    }px`;
    document.getElementById("color").style.top = `${
      swatchY + 15 + canvasTop - top
    }px`;
    document.getElementById(
      "color"
    ).style.background = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    document.getElementById(
      `${eyeDropperActive}Sample`
    ).style.background = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
  };

  const eyeDropperOnClick = (e) => {
    if (!eyeDropperActive) return;
    let { x, y } = props.stageRef.current.getPointerPosition();
    let ctx = props.stageRef.current.content.children[0].getContext("2d");
    let scale =
      parseFloat(props.stageRef.current.content.children[0].style.width) /
      props.stageRef.current.content.children[0].width;
    let pxData = ctx.getImageData(x / scale, y / scale, 5, 5);
    let rgb = { r: pxData.data[0], g: pxData.data[1], b: pxData.data[2] };
    setEyeDropperColor(rgb);
  };
  let { uploadedBackground, uploadedGif, uploadedApng } = props;
  let containerWidth = props.alignment == "horizontal" ? "113.33vh" : "63.75vh";

  useEffect(() => {
    setTimeout(() => {
      if (props.stageRef.current) props.stageRef.current.draw();
    }, 2000);
  }, [props.fonts]);

  useEffect(() => {
    canvasTop = props.stageRef.current.content.getBoundingClientRect().top;
    canvasLeft = props.stageRef.current.content.getBoundingClientRect().left;
  }, [props.stageRef]);

  useEffect(() => {
    setEyeDropperActive(false);
  }, [props.editOpen]);

  let hideCanvasStyle;
  if (!props.uploadedWorldViewEffect.file) {
    hideCanvasStyle = {};
  } else {
    hideCanvasStyle = { zIndex: -3, top: 0, position: "absolute" };
  }
  return (
    <div>
      {showHoveringSwatch && (
        <div
          id="color"
          style={{
            height: "20px",
            width: "20px",
            border: "1px solid black",
            position: "absolute",
            zIndex: 1200,
          }}
        />
      )}
      <FilterDesignerControls
        setErr={props.setErr}
        eyeDropperColor={eyeDropperColor}
        setEyeDropperColor={setEyeDropperColor}
        eyeDropperActive={eyeDropperActive}
        setEyeDropperActive={setEyeDropperActive}
        background={background}
        setBackground={setBackground}
        selectedFilter={props.selectedFilter}
        setSelectedFilter={props.setSelectedFilter}
        canvasProps={props.canvasProps}
        updateCanvasProps={props.updateCanvasProps}
        filterOrientationType={filterOrientationType}
        campaign={props.campaign}
        retailer={props.retailer}
        isARLoading={isARLoading}
        setAreSettingsUpdated={props.setAreSettingsUpdated}
        setIsARLoading={setIsARLoading}
        loadAudio={props.loadAudio}
        uploadedWorldViewEffect={props.uploadedWorldViewEffect}
        setUploadedWorldViewEffect={props.setUploadedWorldViewEffect}
        setUploadedArAudio={props.setUploadedArAudio}
        uploadedArAudio={props.uploadedArAudio}
        selectedArBinaries={props.selectedArBinaries}
        setSelectedArBinaries={props.setSelectedArBinaries}
        setSelectedDeepArVersion={props.setSelectedDeepArVersion}
        uploadedSticker={props.uploadedSticker}
        setUploadedSticker={props.setUploadedSticker}
        uploadedApng={props.uploadedApng}
        setUploadedApng={props.setUploadedApng}
        uploadedGif={props.uploadedGif}
        setUploadedGif={props.setUploadedGif}
        uploadedBackground={props.uploadedBackground}
        setUploadedBackground={props.setUploadedBackground}
        uploadedThumbnail={props.uploadedThumbnail}
        setUploadedThumbnail={props.setUploadedThumbnail}
        uploadedAREffects={props.uploadedAREffects}
        setUploadedAREffects={props.setUploadedAREffects}
        uploadedARBeaut={props.uploadedARBeaut}
        setUploadedARBeaut={props.setUploadedARBeaut}
        setUploadedLottie={props.setUploadedLottie}
        setDeepArBgRadio={props.setDeepArBgRadio}
        deepArBgRadio={props.deepArBgRadio}
        uploadedLottie={props.uploadedLottie}
        imageUploadErr={imageUploadErr}
        fonts={props.fonts}
        stageRef={props.stageRef}
        alignment={props.alignment}
        setAlignment={props.setAlignment}
        freeformtext={freeformtext}
        setFreeformtext={setFreeformtext}
        cityText={cityText}
        setCityText={setCityText}
        attributionActive={props.attributionActive}
        setAttributionActive={props.setAttributionActive}
        attributionText={props.attributionText}
        setAttributionText={props.setAttributionText}
        attributionLink={props.attributionLink}
        setAttributionLink={props.setAttributionLink}
      />
      <div
        style={{
          position: "absolute",
          left: "34vw",
          width: containerWidth,
          height: "85vh",
        }}
      >
        {!!props.attributionActive && !!props.attributionText && (
          <div
            style={{
              position: "absolute",
              top: "30px",
              left: "30px",
              height: "5%",
              borderRadius: "20px",
              background: "rgb(78,78,78)",
              display: "flex",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <InfoOutlinedIcon
              style={{
                color: "white",
                height: "17px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            />
            <span
              style={{ color: "white", fontSize: "12px", marginRight: "10px" }}
            >
              {props.attributionText}
            </span>
          </div>
        )}
        {props.uploadedWorldViewEffect.file && (
          <BabylonModelViewer
            canvasWidth={window.innerHeight * 0.6375}
            canvasHeight={window.innerHeight * 0.85}
            effectSource={props.uploadedWorldViewEffect.source}
            effectUrl={props.uploadedWorldViewEffect.file}
            audioEffect={props.uploadedArAudio.file}
          />
        )}
        {!props.uploadedWorldViewEffect.file && (
          <ARPreview
            canvasWidth={
              props.alignment == "horizontal"
                ? (window.innerHeight * 0.85) / 0.75
                : window.innerHeight * 0.6375
            }
            canvasHeight={window.innerHeight * 0.85}
            campaign={props.campaign}
            selectedFilter={props.selectedFilter}
            selectedArBinaries={props.selectedArBinaries}
            setSelectedArBinaries={props.setSelectedArBinaries}
            setSelectedDeepArVersion={props.setSelectedDeepArVersion}
            setIsARLoading={setIsARLoading}
          />
        )}
        <LottiePreview
          canvasWidth={
            props.alignment == "horizontal"
              ? (window.innerHeight * 0.85) / 0.75
              : window.innerHeight * 0.6375
          }
          canvasHeight={window.innerHeight * 0.85}
          uploadedLottie={props.uploadedLottie}
          selectedFilter={props.selectedFilter}
          canvasProps={props.canvasProps}
          updateCanvasProps={props.updateCanvasProps}
          setSelectedFilter={props.setSelectedFilter}
        />

        <Stage
          style={{
            border: "1px solid rgb(0, 188, 212)",
            marginBottom: "100px",
            background,
            ...hideCanvasStyle,
          }}
          className={eyeDropperActive ? "pippette_cursor" : ""}
          onMouseMove={eyeDropperOnMove}
          onMouseEnter={(e) => setShowHoveringSwatch(true)}
          onMouseLeave={(e) => setShowHoveringSwatch(false)}
          onClick={eyeDropperOnClick}
          width={
            props.alignment == "horizontal"
              ? (window.innerHeight * 0.85) / 0.75
              : window.innerHeight * 0.6375
          }
          height={window.innerHeight * 0.85}
          ref={props.stageRef}
        >
          <Layer>
            <FilterImage
              alignment={props.alignment}
              filterOrientationType={filterOrientationType}
              canvasProps={getModCanvasProps()}
              updateCanvasProps={props.updateCanvasProps}
              setAlignment={props.setAlignment}
              setFilterOrientationType={setFilterOrientationType}
              uploadedBackgroundUrl={uploadedBackground}
              selectedFilter={props.selectedFilter}
              setUploadedBackground={props.setUploadedBackground}
              setImageUploadErr={setImageUploadErr}
              setUploadedBackgroundUrl={setUploadedBackgroundUrl}
              orientation_type={orientation_type}
            />
            <StickerImage
              uploadedSticker={props.uploadedSticker}
              alignment={props.alignment}
              hide={props.hiddenCanvasElements.includes("sticker")}
              selectedFilter={props.selectedFilter}
              setSelectedFilter={props.setSelectedFilter}
              canvasProps={getModCanvasProps()}
              updateCanvasProps={props.updateCanvasProps}
              filterOrientationType={filterOrientationType}
            />
            <APNGImage
              uploadedApng={uploadedApng}
              alignment={props.alignment}
              hide={props.hiddenCanvasElements.includes("gif")}
              selectedFilter={props.selectedFilter}
              setSelectedFilter={props.setSelectedFilter}
              canvasProps={getModCanvasProps()}
              updateCanvasProps={props.updateCanvasProps}
              filterOrientationType={filterOrientationType}
            />
            <GIFImage
              uploadedGif={uploadedGif}
              alignment={props.alignment}
              hide={props.hiddenCanvasElements.includes("gif")}
              selectedFilter={props.selectedFilter}
              setSelectedFilter={props.setSelectedFilter}
              canvasProps={getModCanvasProps()}
              updateCanvasProps={props.updateCanvasProps}
              filterOrientationType={filterOrientationType}
            />
            {/* {props.fonts.length == 0 ?  */}
            <CanvasText
              text={
                // props.canvasProps.variables.city.text ||
                cityText
              }
              name={"city"}
              hide={props.hiddenCanvasElements.includes("city")}
              alignment={props.alignment}
              settings={getModCanvasProps().variables.city}
              canvasProps={props.canvasProps}
              updateCanvasProps={props.updateCanvasProps}
              filterOrientationType={filterOrientationType}
            />
            <CanvasText
              text={props.canvasProps.variables.date.format}
              name={"date"}
              hide={props.hiddenCanvasElements.includes("date")}
              alignment={props.alignment}
              settings={getModCanvasProps().variables.date}
              canvasProps={props.canvasProps}
              updateCanvasProps={props.updateCanvasProps}
              filterOrientationType={filterOrientationType}
              fonts={props.fonts}
            />
            <CanvasText
              text={props.canvasProps.variables.time.format}
              name={"time"}
              hide={props.hiddenCanvasElements.includes("time")}
              alignment={props.alignment}
              settings={getModCanvasProps().variables.time}
              canvasProps={props.canvasProps}
              updateCanvasProps={props.updateCanvasProps}
              filterOrientationType={filterOrientationType}
            />
            <CanvasText
              text={
                props.canvasProps.variables.freeformtext.text || freeformtext
              }
              name={"freeformtext"}
              hideOutline={props.hiddenCanvasElements.includes("freeform")}
              alignment={props.alignment}
              settings={getModCanvasProps().variables.freeformtext}
              canvasProps={props.canvasProps}
              updateCanvasProps={props.updateCanvasProps}
              filterOrientationType={filterOrientationType}
            />
            {props.selectedFilter.frame_type?.includes("places") && (
              <CanvasText
                text={"Places Dynamic"}
                name={"nonPartnerConfig"}
                hide={props.hiddenCanvasElements.includes("placesText")}
                alignment={props.alignment}
                settings={getModCanvasProps().nonPartnerConfig}
                canvasProps={props.canvasProps}
                updateCanvasProps={props.updateCanvasProps}
                filterOrientationType={filterOrientationType}
              />
            )}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

import React, {useState, useEffect, useMemo, useRef} from 'react';
import axios from 'axios';
import useImage from 'use-image';
import { Image, Label } from 'react-konva';
import {parseStringToInt, getXOffset, getYOffset} from '../../../CommonFunctions';
import {getStaticAssetPath} from '../../../ApiUtility/ApiUtility';
import '../../../App.css';
import parseAPNG from 'apng-js';
import {decode} from 'base64-arraybuffer';


/**
 * Component used to display the APNGs.
 *
 * @component
 */

const APNGImage = (props) => {
    let scale = (props.canvasHeight || window.innerHeight*.6375)/1080;
    const {uploadedApng, selectedFilter} = props;

    const [imageUrl, setImageUrl] = useState("");
    const [imageNode, setImageNode] = useState(null);

    const imageRef = useRef(null);
    const canvas = useMemo(() => {
        const node = document.createElement("canvas");
        return node;
    }, [imageUrl]);

  if(props.alignment == "horizontal" && props.filterOrientationType == "vertical") scale *= .75;
  else if(props.alignment == "horizontal") scale *= .75;
  else if(props.alignment == "vertical" && props.filterOrientationType == "horizontal") scale = .88;

  const [image, status] = useImage(imageUrl, 'Anonymous');


  const handleApngPosition = (apngCoordinates) => {
    let {filterOrientationType} = props;
    if(filterOrientationType == "horizontal") scale /= .75;
    let nextSettings = JSON.parse(JSON.stringify(props.canvasProps.upload_images));
    let modCanvasProps = JSON.parse(JSON.stringify(props.canvasProps));
    let x_offset = getXOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType}); 
    let y_offset = getYOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType});

    if(!nextSettings.apng) nextSettings.apng = {};
    nextSettings.apng.x = apngCoordinates.x - x_offset;
    nextSettings.apng.y = apngCoordinates.y - y_offset;
    nextSettings.sticker = nextSettings.apng;
    nextSettings.positionedImage = nextSettings.apng;
    props.updateCanvasProps('upload_images', nextSettings);
  }

    const startApng = async (player) => {
        let imageUrlBuffer;
        if(!uploadedApng.url) return;
        if(!!uploadedApng.url && !!uploadedApng.url.includes("https")){
            let remoteApngResponse = await axios.get(uploadedApng.url, { responseType: 'arraybuffer' });
            imageUrlBuffer = remoteApngResponse.data;
        }else if(!!uploadedApng.url){
            imageUrlBuffer = decode(uploadedApng.url.split("base64,")[1]);
        }
        let apng = parseAPNG(imageUrlBuffer);
        if (apng instanceof Error) {
            // handle error
        }
        else{
            canvas.height = apng.height;
            canvas.width = apng.width;
            let ctx = canvas.getContext('2d')
            console.log("apng: ", apng);
            apng.getPlayer(ctx, true).then(p => {
                player = p;
                p.on("frame", (frame) => {
                    if(imageRef.current) imageRef.current.getLayer().draw();
                })
            });
        }
    }

  useEffect(() => {
    const checkSetHostedApng = async () => {
      if(!!uploadedApng.url) return;
      let url  = await getStaticAssetPath(selectedFilter.frame_id, "apng");
      setImageUrl(url);
    }
      checkSetHostedApng();
  }, [selectedFilter.frame_id]);

  useEffect(() => {
    if(!!uploadedApng.url) {
      setImageUrl(uploadedApng.url);
    }else{
      setImageUrl("")
    }
  },[uploadedApng])

  useEffect(()=> {
    if(!imageNode) return;
  }, [imageNode]);

  useEffect(()=> {
    if(status == "loaded") setImageNode(image);
  },[status]);

    useEffect(() => {
        // save animation instance to stop it on unmount
        let player;
        if(player) player.stop();
        startApng(player);
        if(player) return () => player.stop();
    }, [imageUrl]);


  if(!!props.hide) return <Label/>
  if(!uploadedApng.url) return <Label/>
  

  return (
    <Image 
      x={parseStringToInt(props.canvasProps.upload_images.apng?.x || 0)}
      y={parseStringToInt(props.canvasProps.upload_images.apng?.y || 0)}
      height={canvas.height * scale}
      width={canvas.width * scale}
      draggable={props.canvasHeight ? false : true}
      image={canvas}
      ref={imageRef} 
      onDragEnd={e => {
        handleApngPosition({x: parseInt(e.target.x()), y: parseInt(e.target.y())});
      }}
    />
  )
};

export default APNGImage;
import React, { useState, useEffect } from "react";
import {
  TextField,
  Paper,
  CircularProgress,
  MenuItem,
  FormControl,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { setAppBadgeTitleIndicator } from "../../ApiUtility/ApiUtility";
import { isValid } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "center",
  },
  select: {
    width: "100%",
  },
}));

/**
 * Component used to set data for App Badge Title Indicator.
 *
 *
 * @component
 */

export default function AppBadge(props) {
  const classes = useStyles();
  const [deeplinkUrl, setDeeplinkUrl] = useState("");
  const [selectedDate, handleDateChange] = useState(null);
  const [actionType, setActionType] = useState("");
  const [deeplinkUrlHint, setDeeplinkUrlHint] = useState("");
  const [notificationCallResponse, setNotificationCallResponse] = useState({});

  function handleActionTypeChange(e) {
    setActionType(e.target.value);
  }

  function updateDeepLinkUrl(e) {
    setDeeplinkUrl(e.target.value);
    if (!isValidUrl(e.target.value) && e.target.value !== "")
      setDeeplinkUrlHint("Invalid URL");
    else setDeeplinkUrlHint("");
  }

  function isValidUrl(url) {
    return url.includes("http://") || url.includes("https://");
  }

  async function handleSubmit() {
    const expiration = +selectedDate;
    let response = await setAppBadgeTitleIndicator({
      action_type: actionType,
      expiration,
      url: deeplinkUrl,
    });
    setNotificationCallResponse(response.data.response);
    setTimeout(() => {
      setNotificationCallResponse({});
    }, 3000);
  }

  return (
    <Paper>
      <FormControl
        component="fieldset"
        style={{ width: "30%", height: "80vh" }}
      >
        <Alert
          style={{ marginTop: 10 }}
          className={!notificationCallResponse.code ? "hidden" : ""}
          variant="outlined"
          severity={
            notificationCallResponse.code == "200" ? "success" : "error"
          }
        >
          {notificationCallResponse.message}
        </Alert>
        <h4>App Badge Title Indicator</h4>
        <TextField
          id="app-badge-action-type"
          className={classes.select}
          variant="outlined"
          size="small"
          select
          onChange={handleActionTypeChange}
          style={{ marginBottom: "10px", marginTop: 10 }}
          label="Action Type"
        >
          <MenuItem value={"clear_all"}>Clear All</MenuItem>
          <MenuItem value={"notify"}>Notify</MenuItem>
        </TextField>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            clearable
            inputVariant="outlined"
            label="Expiration Date"
            size="small"
            disablePast
            value={selectedDate}
            onChange={handleDateChange}
            fullWidth
          />
        </MuiPickersUtilsProvider>
        <TextField
          onChange={updateDeepLinkUrl}
          style={{ marginBottom: "20px", marginTop: 10 }}
          fullWidth
          size="small"
          id="webar-link"
          label="Deeplink Url"
          error={deeplinkUrlHint.length > 1}
          helperText={deeplinkUrlHint}
          variant="outlined"
        />
        <Button
          onClick={handleSubmit}
          color="primary"
          style={{ width: "50%", margin: "0 auto" }}
          variant="contained"
        >
          Submit
        </Button>
      </FormControl>
    </Paper>
  );
}

import { Engine, Scene } from "@babylonjs/core";
import React, { useEffect, useRef } from "react";
import "@babylonjs/inspector";

const onWheel = (e) => {
  e.stopPropagation();
  e.preventDefault();
  e.returnValue = false;
  return false;
};

/**
 * Component used to set up scene for Babylon viewer.
 *
 * @component
 */

export default (props) => {
  const reactCanvas = useRef(null);
  const {
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
  } = props;

  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(
        reactCanvas.current,
        antialias,
        engineOptions,
        adaptToDeviceRatio
      );
      const scene = new Scene(engine, sceneOptions);
      if (scene.isReady()) {
        console.log("in is ready");
        props.onSceneReady(scene, props.effectUrl, props.audioEffect);
      } else {
        scene.onReadyObservable.addOnce((scene) =>
          props.onSceneReady(scene, props.effectUrl, props.audioEffect)
        );
      }

      engine.runRenderLoop(() => {
        if (typeof onRender === "function") {
          onRender(scene);
        }
        try {
          scene.render();
        } catch (err) {}
      });

      const resize = () => {
        scene.getEngine().resize();
      };

      if (window) {
        window.addEventListener("resize", resize);
      }

      return () => {
        try {
          scene.getEngine().dispose();
        } catch (err) {
          console.log("in err dispose");
          scene.dispose();
          scene.getEngine().dispose();
        }

        if (window) {
          window.removeEventListener("resize", resize);
        }
      };
    }
  }, [reactCanvas, props.effectUrl, props.audioEffect]);

  useEffect(() => {
    reactCanvas.current.addEventListener("wheel", onWheel, { passive: false });

    return () => {
      reactCanvas.current.removeEventListener("wheel", onWheel);
    };
  }, []);

  return <canvas ref={reactCanvas} {...rest} />;
};

import React, { useState, useEffect } from "react";
import SettingsCard from "../../SettingsCard";
import {
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  Box,
  Link,
  TextField,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SchedulePicker from "./SchedulePicker";
import CollectionsSort from "./CollectionsSort";
import ImageDialog from "../../Common/ImageDialog";
import moment from "moment-timezone";
import { useDropzone } from "react-dropzone";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExclusiveContentConfiguration from "../../Common/ExclusiveContentConfiguration";
import axios from "axios";
import "../../../App.css";

import {
  updateCampaign,
  getPremiumAttributionInfo,
  savePremiumAttribution,
  saveCollection,
  deleteCollection,
  saveCollectionThumbnail,
  getCollectionThumbnail,
  deleteCollectionThumbnail,
  getCollectionsList,
} from "../../../ApiUtility/ApiUtility";
import {
  getRepeatEveryValue,
  getRepeatDayValue,
  getRepeatDayNames,
  verifyDates,
} from "../../../CommonFunctions";
import { popularCollectionsAtom, premiumCollectionsAtom } from "../../../App";
import { useAtom } from "jotai";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  media: {
    height: 140,
  },
  formLabel: {
    display: "block",
  },
  formControl: {
    minWidth: "70%",
  },
  saveButton: {
    postion: "relative",
    right: "25px",
  },
  saveButtonContainer: {
    textAlign: "right",
  },
  buttonProgress: {
    position: "absolute",
  },
  setToRepeat: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
  },
}));

const styles = {
  uploadButton: {
    fontSize: "10px",
    padding: "5px 5px",
    marginRight: 5,
  },
};

/**
 * Component for Adjusting Campaign Settings.
 *
 * @component
 */

export default function CampaignSettings(props) {
  const classes = useStyles();
  const [showCarousel, setShowCarousel] = useState(
    props.campaign.optin_algorithm
  );
  const [showMenuList, setShowMenuList] = useState(props.campaign.optin_menu);
  const [campaignName, setCampaignName] = useState(props.campaign.name);
  const [popularCollections, setPopularCollections] = useAtom(
    popularCollectionsAtom
  );
  const [isPopularCollection, setIsPopularCollection] = useState(
    popularCollections.some((x) => x.campaign_id == props.campaign.campaign_id)
  );
  const [premiumCollections, setPremiumCollections] = useAtom(
    premiumCollectionsAtom
  );
  const [isPremiumCollection, setIsPremiumCollection] = useState(
    premiumCollections.some((x) => x.campaign_id == props.campaign.campaign_id)
  );
  const [attributionActive, setAttributionActive] = useState(0);
  const [attributionText, setAttributionText] = useState("");
  const [attributionLink, setAttributionLink] = useState("");
  const [radioValue, setRadioValue] = useState(getActiveStatus(props.campaign));
  const [isSaving, setIsSaving] = useState(false);
  const [isRepeatOpen, setIsRepeatOpen] = useState(false);
  const [doStartValidation, setDoStartValidation] = useState(true);
  const [popularThumbnail, setPopularThumbnail] = useState({});
  const [premiumThumbnail, setPremiumThumbnail] = useState({});
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [sortDialogType, setSortDialogType] = useState(false);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);
  const [err, setErr] = useState({});

  const onDrop = async (file, type) => {
    await saveCollectionThumbnail(
      props.retailer_id,
      props.campaign.campaign_id,
      file[0],
      type
    );
    getCollectionThumbnail(
      props.retailer_id,
      props.campaign.campaign_id,
      type
    ).then((url) => {
      if (type == "popular") {
        axios
          .get(url)
          .then((x) => setPopularThumbnail({ source: "remote", url: url }))
          .catch((err) => console.log("err: ", err));
      } else {
        axios
          .get(url)
          .then((x) => setPremiumThumbnail({ source: "remote", url: url }))
          .catch((err) => console.log("err: ", err));
      }
    });
  };

  const {
    getRootProps: getRootPropsThumbnail,
    getInputProps: getInputPropsThumbnail,
  } = useDropzone({ onDrop: (f) => onDrop(f, "popular") });
  const {
    getRootProps: getRootPropsPremiumThumbnail,
    getInputProps: getInputPropsPremiumThumbnail,
  } = useDropzone({ onDrop: (f) => onDrop(f, "premium") });

  async function handleChange(e) {
    let checkbox_name = e.target.getAttribute("name");
    let checkbox_type = checkbox_name.includes("Popular")
      ? "popular"
      : "premium";
    if (checkbox_name == "carousel") setShowCarousel(showCarousel == 1 ? 0 : 1);
    else if (checkbox_name == "attributionActive")
      setAttributionActive(attributionActive == 1 ? 0 : 1);
    else if (checkbox_name.includes("Collection"))
      handleCollectionsChange(checkbox_type);
    else setShowMenuList(showMenuList == 1 ? 0 : 1);
  }

  const handleCollectionsChange = async (type) => {
    let [isCollection, setIsCollection] =
      type == "popular"
        ? [isPopularCollection, setIsPopularCollection]
        : [isPremiumCollection, setIsPremiumCollection];
    if (isCollection) {
      deleteCollection(props.campaign.campaign_id, type);
      if (type == "popular") {
        setPopularCollections((collections) =>
          collections.filter(
            (x) => x.campaign_id !== props.campaign.campaign_id
          )
        );
      } else {
        setPremiumCollections((collections) =>
          collections.filter(
            (x) => x.campaign_id !== props.campaign.campaign_id
          )
        );
      }
    } else {
      let newSortOrder =
        popularCollections[popularCollections.length - 1].sort_order + 1;
      await saveCollection(
        [{ campaign_id: props.campaign.campaign_id, sort_order: newSortOrder }],
        type
      );
      getCollectionsList(type).then((x) => {
        let items = x.data.response.items.sort(
          (a, b) => a.sort_order - b.sort_order
        );
        if (type == "popular") setPopularCollections(items);
        else {
          console.log("in set premium: ", items);
          setPremiumCollections(items);
        }
      });
    }
    setIsCollection(isCollection == 1 ? 0 : 1);
  };

  function getActiveStatus({ active, job }) {
    if (job) return "scheduled";
    if (active == 1) return "active";
    return "inactive";
  }

  function handleRadioChange(e) {
    let nextCampaign = Object.assign({}, props.campaign);
    setRadioValue(e.target.getAttribute("value"));
    if (e.target.getAttribute("value") == "inactive") nextCampaign.active = 0;
    else if (e.target.getAttribute("value") == "active")
      nextCampaign.active = 1;
    else nextCampaign.active = 0;
    if (e.target.getAttribute("value") !== "scheduled") delete nextCampaign.job;
    props.setCampaign(nextCampaign);
  }

  async function saveCampaign() {
    try {
      verifyDates(
        props.campaign.startDate,
        props.campaign.endDate,
        props.campaign.job,
        doStartValidation
      );
      setIsSaving(true);
      props.campaign.optin_algorithm = showCarousel;
      props.campaign.optin_menu = showMenuList;
      props.campaign.name = campaignName;
      await updateCampaign(props.campaign);
      await savePremiumAttribution({
        campaign_id: props.campaign.campaign_id,
        attribution_text: attributionText,
        attribution_link: attributionLink,
        owner: "Mobilize",
        active: attributionActive,
      });
      setTimeout(() => {
        setIsSaving(false);
      }, 1000);
      props.getCampaign();
    } catch (err) {
      setErr(err);
    }
  }

  function getRepeatDescription() {
    let repeat_every_value = getRepeatEveryValue(props.campaign);
    if (repeat_every_value == "daily")
      repeat_every_value += ` every ${getRepeatDayValue(
        props.campaign
      )} day(s)`;
    else if (repeat_every_value == "weekly")
      repeat_every_value += ` on ${getRepeatDayNames(props.campaign)}`;
    return ` - ${
      repeat_every_value.charAt(0).toUpperCase() + repeat_every_value.slice(1)
    }`;
  }

  const viewThumbnail = (type) => {
    setImageDialogOpen(type);
  };

  const deleteThumbnail = (type) => {
    deleteCollectionThumbnail(
      props.retailer_id,
      props.campaign.campaign_id,
      type
    ).then((r) => {
      if (type == "popular") setPopularThumbnail({});
      else setPremiumThumbnail({});
    });
  };

  useEffect(() => {
    if (!props.campaign.job) return setDoStartValidation(true);
    let {
      campaign: {
        job: { start_date, end_date },
      },
    } = props;
    if (moment(start_date) < moment() && moment() < moment(end_date))
      setDoStartValidation(false);
    else setDoStartValidation(true);
  }, []);

  useEffect(() => {
    getPremiumAttributionInfo({ campaign_id: props.campaign.campaign_id }).then(
      (r) => {
        console.log("before set: ", r);
        setAttributionActive(r.active);
        setAttributionText(r.attribution_text || "");
        setAttributionLink(r.attribution_link || "");
      }
    );
    getCollectionThumbnail(
      props.retailer_id,
      props.campaign.campaign_id,
      "popular"
    ).then((url) => {
      axios
        .get(url)
        .then((x) => setPopularThumbnail({ source: "remote", url: url }))
        .catch((err) => console.log("err: ", err));
    });
    getCollectionThumbnail(
      props.retailer_id,
      props.campaign.campaign_id,
      "premium"
    ).then((url) => {
      axios
        .get(url)
        .then((x) => setPremiumThumbnail({ source: "remote", url: url }))
        .catch((err) => console.log("err: ", err));
    });
  }, []);

  return (
    <>
      <ImageDialog
        dialogOpen={imageDialogOpen}
        dialogType={"Sticker"}
        handleCloseDialog={(e) => setImageDialogOpen(false)}
        img_src={
          imageDialogOpen == "popular"
            ? popularThumbnail.url
            : premiumThumbnail.url
        }
      />
      <CollectionsSort
        dialogOpen={sortDialogOpen}
        handleCloseDialog={(e) => setSortDialogOpen(false)}
        collectionsDuple={
          sortDialogType == "popular"
            ? [popularCollections, setPopularCollections]
            : [premiumCollections, setPremiumCollections]
        }
        type={sortDialogType}
      />
      <Card className={classes.root}>
        <Box className={classes.saveButtonContainer}>
          <Button
            className={classes.saveButton}
            variant="contained"
            onClick={saveCampaign}
          >
            Save{" "}
            {isSaving && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Box>
        <div style={{ textAlign: "left", marginLeft: "15px" }}>
          <TextField
            defaultValue={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            id="campaign-name"
            label="Campaign Name"
            variant="outlined"
          />
        </div>
        <CardContent>
          <SettingsCard title="Attribution">
            <form className={classes.root} noValidate autoComplete="off">
              <FormControlLabel
                color="primary"
                control={
                  <Checkbox
                    checked={attributionActive}
                    color="primary"
                    onChange={handleChange}
                    name="attributionActive"
                  />
                }
                label="Active"
              />
              <TextField
                value={attributionText}
                onChange={(e) => {
                  setAttributionText(e.target.value);
                  if (e.target.value == "") setAttributionActive(0);
                  else setAttributionActive(1);
                }}
                size="small"
                id="attribution-text"
                label="Attribution Text"
                variant="outlined"
              />
              <TextField
                value={attributionLink}
                onChange={(e) => setAttributionLink(e.target.value)}
                size="small"
                id="attribution-link"
                label="Attribution Link"
                variant="outlined"
              />
            </form>
          </SettingsCard>
          <SettingsCard title="Popular Collection (In Search)">
            <form className={classes.root} noValidate autoComplete="off">
              <FormControlLabel
                color="primary"
                control={
                  <Checkbox
                    checked={isPopularCollection}
                    color="primary"
                    data-type="popular"
                    onChange={handleChange}
                    name="isPopularCollection"
                  />
                }
                label="Active"
              />
              <div
                style={{ display: "inline-block" }}
                className={!popularThumbnail.url ? "" : "hidden"}
                {...getRootPropsThumbnail({ refKey: "innerRef" })}
              >
                <input {...getInputPropsThumbnail()} />
                <Button
                  variant="contained"
                  color="primary"
                  style={styles.uploadButton}
                >
                  Upload Thumbnail
                </Button>
              </div>
              <div
                style={{ display: "inline-block", marginRight: "10px" }}
                className={popularThumbnail.source == "remote" ? "" : "hidden"}
              >
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={(e) => viewThumbnail("popular")}
                >
                  View
                </Link>
                {` | `}
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={(e) => deleteThumbnail("popular")}
                >
                  Delete
                </Link>
              </div>
              <Button
                variant="contained"
                onClick={(e) => {
                  setSortDialogType("popular");
                  setSortDialogOpen(true);
                }}
                color="primary"
                style={styles.uploadButton}
              >
                View Order
              </Button>
            </form>
          </SettingsCard>
          <SettingsCard title="Display Configuration">
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  checked={showCarousel}
                  color="primary"
                  onChange={handleChange}
                  name="carousel"
                />
              }
              label="Show in Carousel"
            />
            <FormControlLabel
              className={classes.formLabel}
              color="primary"
              control={
                <Checkbox
                  checked={showMenuList}
                  color="primary"
                  onChange={handleChange}
                  name="menuList"
                />
              }
              label="Show in Menu List"
            />
            <FormControlLabel
              className={classes.formLabel}
              color="primary"
              control={
                <Checkbox
                  checked={isPremiumCollection}
                  color="primary"
                  data-type="premium"
                  onChange={handleChange}
                  name="premiumCollections"
                />
              }
              label="Show in Popular Collections (In Trending)"
            />
            <div
              style={{ display: "inline-block" }}
              className={!premiumThumbnail.url ? "" : "hidden"}
              {...getRootPropsPremiumThumbnail({ refKey: "innerRef" })}
            >
              <input {...getInputPropsPremiumThumbnail()} />
              <Button
                variant="contained"
                color="primary"
                style={styles.uploadButton}
              >
                Upload Thumbnail
              </Button>
            </div>
            <div
              style={{ display: "inline-block", marginRight: "10px" }}
              className={premiumThumbnail.source == "remote" ? "" : "hidden"}
            >
              <Link
                style={{ cursor: "pointer" }}
                onClick={(e) => viewThumbnail("premium")}
              >
                View
              </Link>
              {` | `}
              <Link
                style={{ cursor: "pointer" }}
                onClick={(e) => deleteThumbnail("premium")}
              >
                Delete
              </Link>
            </div>
            <Button
              variant="contained"
              onClick={(e) => {
                setSortDialogType("premium");
                setSortDialogOpen(true);
              }}
              color="primary"
              style={styles.uploadButton}
            >
              View Order
            </Button>
          </SettingsCard>
          <ExclusiveContentConfiguration
            campaignId={props.campaign.campaign_id}
          />
          <SettingsCard title="Schedule Settings">
            <FormControl className={classes.formControl} component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="scheduleSettings"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  color="primary"
                  value="active"
                  control={<Radio color="primary" />}
                  label="Active"
                  style={{ width: "10%" }}
                />
                <FormControlLabel
                  color="primary"
                  value="inactive"
                  control={<Radio color="primary" />}
                  label="Inactive"
                  style={{ width: "10%" }}
                />
                <div>
                  <FormControlLabel
                    color="primary"
                    value="scheduled"
                    control={<Radio color="primary" />}
                    label="Active on specific dates(will be randomized with other enabled templates)"
                  />
                  <div
                    onClick={(e) => setIsRepeatOpen(true)}
                    className={classes.setToRepeat}
                  >
                    <Link>
                      <RefreshIcon style={{ verticalAlign: "text-bottom" }} />{" "}
                      Set to repeat
                    </Link>
                    {getRepeatDescription()}
                  </div>
                </div>
              </RadioGroup>
              <Box className={radioValue == "scheduled" ? "" : "hidden"}>
                <SchedulePicker
                  setAreSettingsUpdated={(e) => console.log("update")}
                  err={err}
                  setErr={setErr}
                  radioValue={radioValue}
                  item={props.campaign}
                  setItem={props.setCampaign}
                  isRepeatOpen={isRepeatOpen}
                  setIsRepeatOpen={setIsRepeatOpen}
                />
              </Box>
            </FormControl>
          </SettingsCard>
        </CardContent>
      </Card>
    </>
  );
}

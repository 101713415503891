import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import {getCanvasProps, loadFilterImage, getFrameInfo, getCampaignInfo, getLocationInfo} from '../../ApiUtility/ApiUtility';
import ReactTooltip from 'react-tooltip';
import TagControl from '../Common/TagControl';
import cronstrue from 'cronstrue';
import FilterCardCanvas from './FilterCardCanvas';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import defaultCanvasProps from '../../data/defaultCanvasProps';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';

/**
 * Component to show preview of filter and filter information in the library tab.
 *
 * @component
 */

export default function FilterCard(props) {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [canvasProps, setCanvasProps] = useState(defaultCanvasProps);
    const [uploadedLottie, setUploadedLottie] = useState({})
    const [uploadedSticker, setUploadedSticker] = useState({})
    const [uploadedGif, setUploadedGif] = useState({})
    const [uploadedApng, setUploadedApng] = useState({});
    const [frameInfo, setFrameInfo] = useState([]);
    const [setupInfo, setSetupInfo] = useState("");
    
    const tooltipRef = useRef();
    const tooltipContainerRef = useRef();

    const loadAsset = (type) => {
      return new Promise((resolve, reject) => {
        loadFilterImage({filter_id: props.filter.frame_id, type}).then(f => {
          if(type == "lottie"){
            axios.get(f).then(r => {
              setUploadedLottie({source: 'remote', data: r.data});
            });
          }else if(type == "pi") setUploadedSticker({source: 'remote', url: f});
          else if(type == "gif") setUploadedGif({source: 'remote', url: f});
          else if(type == "apng") setUploadedApng({source: 'remote', url: f});
          resolve();
        }).catch(err => {
          reject(err)
        });
      })
    }

  const getStatus = (active, schedule_data) => {
    let now = moment();
    let schedule_end = !!schedule_data ? moment(schedule_data.end_date) : 0;
    let schedule_start = !!schedule_data ? moment(schedule_data.start_date) : Infinity;
    if(active === 1){
      if(now >= schedule_start && now <= schedule_end){
        return "Scheduled Active";
      }else{
        return "Active";
      }
    }else{
      if(now <= schedule_end){
        return "Scheduled Inactive";
      }else{
        return "Inactive";
      }
    }
  }


  const parseSetupInfo = async () => {
    let info;
    let type = frameInfo.frame_type;
    console.log("frameInfo: ", frameInfo);
    if(!type) return "";
    else if(type == "places-generic") return "Places Generic";
    else if(type == "places") return formatCategories(frameInfo);
    else if(type.includes("city")) return formatCityInfo(frameInfo);
    else if(type == "global") return "Global";
    else {
      info = await findCampaign(frameInfo.frameAssociations);
      return info;
    }
  }

  const formatCityInfo = async (frameInfo) => {
    let locationId = frameInfo.frameAssociations.find(x => x.entity_type_name == "location").entity_id;
    let locationInfo = await getLocationInfo(locationId); 
    if(locationInfo.location_name === "all_city") return "City Generic";
    return `City Specific - ${locationInfo.location_name}, ${locationInfo.state_name}`;
  }

  const formatCategories = (frameInfo) => {
    let categoryList = [];
    let categoryId = frameInfo.frameAssociations.find(x => x.entity_type_name == "category").entity_id;
    let cat = props.categories.find(c => c.id == categoryId);
    categoryList.push(cat.category_name);
    while(cat.parent_id){
      cat = props.categories.find(c => c.id == cat.parent_id);
      categoryList.push(cat.category_name);
    }
    return categoryList.reverse().join(" -> ")
  }

  const findCampaign = async (associations) => {
    let campaign = associations.find(x => x.entity_type_name == "campaign");
    let retailerInfo = "Generic";
    let campaignInfo = await getCampaignInfo(campaign.entity_id);
    let contentType = campaignToContentTypeMap[campaignInfo.name];
    if(contentType) return contentType;
    else if(campaignInfo.retailer_id) retailerInfo = `Sponsor ID: ${campaignInfo.retailer_id} - `
    return `${retailerInfo} Campaign ${campaignInfo.campaign_id}: ${campaignInfo.name}`;
  }

  const campaignToContentTypeMap = {
    LIV_HD_CAM_WORLD_AR: "AR Experiences",
    LIV_HD_CAM_DEEPAR: "AR Faces",
    LIV_HD_CAM_CAROUSEL: "Trending",
    LIV_HD_CAM_GLOBAL: "Global"
  }

 


  const parseLocationNames = (location_data) => {
    let location_names = [];
    for(var i in location_data){
        location_names.push(location_data[i].location_name);
    }
    return location_names.join(", ");
  }

  const closeTooltip = (e,v) => {
    ReactTooltip.hide(tooltipRef.current) 
    setTooltipOpen(false)
  }
  

  const openTooltip = (e,v) => {
      ReactTooltip.show(tooltipRef.current)
      setTooltipOpen(true)
  }

  let styles = {
    tooltipContainer:{
      // position: 'absolute'
      },
    tag: {
      fontSize: '15px',
      cursor: 'pointer'
    }
  }

  if(tooltipOpen) styles.tag.color = 'rgb(0, 188, 212)';
  if(tooltipRef.current) {
  let adjusted = (parseInt(tooltipContainerRef.current.tooltipRef.style.top) || 0) + window.scrollY + "px";
    tooltipContainerRef.current.tooltipRef.style.top = adjusted;
  }

  useEffect(() => {
    loadAsset("pi");
    loadAsset("lottie");
    loadAsset("gif");
    loadAsset("apng");
    getCanvasProps(props.filter.frame_id, parseFloat(props.width)).then(setCanvasProps)
    getFrameInfo(props.filter.frame_id).then(setFrameInfo);
  }, []);

  useEffect(() => {
    parseSetupInfo().then(setSetupInfo)    
  }, [frameInfo])

    return (
      <div
        style={{
          padding: "0px",
          display: "inline-block",
          "vertical-align": "top",
          marginRight: 15,
          width: props.width || '187.5px',
        }}
        data-filter-id={props.filter.filter_id}
      >
        <FilterCardCanvas 
          key={props.key}
          width={props.width || '187.5px'}
          height={props.height || '250px'}
          selectedFilter={props.filter}
          canvasProps={canvasProps}
          uploadedLottie={uploadedLottie}
          uploadedSticker={uploadedSticker}
          uploadedGif={uploadedGif}
          uploadedApng={uploadedApng}
        />
        <div
          data-filter-id={props.filter.filter_id}
        >
        <div
          className={(props.filter_preview ? "hidden" : "")}
        >
          <i
            className="fa fa-tag"
            aria-hidden="true"
            data-filter-id={props.filter.filter_id}
            data-filter-name={props.filter.name}
            onClick={props.setTags}
            style={{
              "font-size": "20px"
            }}>
          </i>
        </div>
          <div style={{fontSize: '13px'}}>
            <div
              id={`TooltipExample_${props.filter.filter_id}`}
              style={{
                "font-size": "1vw",
                marginBottom: "10px"
              }}
            >
              <div style={styles.tooltipContainer}>
                <ReactTooltip id={props.filter.frame_id + ""} className='tooltipHover' 
                  key={props.filter.frame_id}
                  ref={tooltipContainerRef}
                  scrollHide={false}
                  place='right'
                  event='dblclick'
                  effect='solid' type={'light'} border={true} borderColor='black'
                >
                <div className='tooltipHover'>
                  <div style={{textAlign: 'right', cursor: 'pointer'}}><CloseIcon onClick={closeTooltip}/></div>
                  <span>{props.filter.name}</span>
                  <TagControl selectedFilter={props.filter}/>
                </div>
              </ReactTooltip>
              </div>
              <p data-for={props.filter.frame_id + ""}  ref={tooltipRef} 
                data-tip='hello world' style={{cursor: 'pointer', wordWrap: 'break-word'}} onClick={openTooltip}>
                  <LocalOfferIcon  style={styles.tag}/>
                  {"  " + props.filter.name}
              </p>
            </div>
            Set Up Under: 
            <br/>
            {setupInfo}
            <div style={{marginBottom: 10}}/>
            Status: {getStatus(props.filter.active, props.filter.job)}
            <br/>
            <div className={props.filter.frame_content_type == "sticker" ? "" : "hidden"}>
              Sticker Position: {props.filter.movable == 1 ? "Movable" : "Fixed"}
              <br/>
            </div>
            Start Date / Time: {props.filter.job ? moment(props.filter.job.start_date).format("MM/DD/YYYY, HH:mm") : "N/A"}
            <br/>
            End Date / Time: {props.filter.job ? moment(props.filter.job.end_date).format("MM/DD/YYYY, HH:mm") : "N/A"}
            <br/>
            Schedule: {props.filter.job ? cronstrue.toString(props.filter.job.start_cron_expression) : "N/A"}
            <br/>
            Geo Locations: {props.filter.locations ? parseLocationNames(props.filter.locations) : "N/A"}
            <br/>
          </div>
        </div>
      </div>
    )
  }

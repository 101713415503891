import React, { useState, useEffect } from "react";
import NavBar from "./Components/NavBar";
import ResponsiveDrawer from "./Components/ResponsiveDrawer";
import Sponsor from "./Components/Sponsor/Sponsor";
import NonSponsorTable from "./Components/NonSponsor/NonSponsorTable";
import EditCampaign from "./Components/NonSponsor/EditCampaign";
import Places from "./Components/Places/Places";
import Library from "./Components/Library/Library";
import GeoSettings from "./Components/GeoSettings/GeoSettings";
import { Box, Button } from "@material-ui/core";
import AppBadge from "./Components/AppBadge/AppBadge";
import "@aws-amplify/ui/dist/style.css";
import { Auth, Hub, Storage, API } from "aws-amplify";
import {
  setGDriveHeader,
  testFormDataCall,
  getFrameTags,
  getTags,
  getCollectionsList,
} from "./ApiUtility/ApiUtility";
import { atom, useAtom } from "jotai";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";

export const tagsAtom = atom([]);
export const frameTagsAtom = atom([]);
export const popularCollectionsAtom = atom([]);
export const premiumCollectionsAtom = atom([]);
export const pageHistoryAtom = atom({
  nonSponsor: {},
  sponsor: {},
});
export const visitedLinksAtom = atom({ filters: [], campaigns: [] });

/**
 * @file App.js is the root file.
 * - Routes are defined here and controlled using ./NavBar
 * - **./ResponsiveDrawer** is used to navigate to different content types under the Menu.
 * - **./ApiUtility** Contains functions for interacting with Rest Endpoints.
 * - **./CommonFunctions** Contains utility functions used in multiple components.
 * - **./Components** Contains components and functions organized by content type.
 * - **./Components/Common** Contains Components shared amongst many content types.
 * - **./Components/Common/FilterDesigner** Contains components that are related to filter editing and previewing functionality for all content types.
 * @author Evan Lanning
 */

/**
 * Root component for app.
 *
 * @component
 */

const App = () => {
  const [user, setUser] = useState(null);
  const [tags, setTags] = useAtom(tagsAtom);
  const [frameTags, setFrameTags] = useAtom(frameTagsAtom);
  const [popularCollections, setPopularCollections] = useAtom(
    popularCollectionsAtom
  );
  const [premiumCollections, setPremiumCollections] = useAtom(
    premiumCollectionsAtom
  );

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    /**
     * On initial render calls getUser and sets userData to state if user is signed in.
     * Sets credentials for GDrive API calls.
     */
    getUser().then((userData) => {
      if (!!userData) {
        setUser(userData);
        setGDriveHeader();
      }
    });
  }, []);

  /**
   * return user data from Amplify Auth Library if user is signed in
   * @return {Promise} Promise object represents userData from Auth Library
   */
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  /**
   * On initial render, removes stored information used for to remember user's selections while setting up filters.
   * Gets and sets tags.
   * Splits frame tags calls due to large size of response.
   * Gets and sets popular & premium collections list.
   */
  useEffect(() => {
    localStorage.removeItem("scheduled_start_date");
    localStorage.removeItem("scheduled_end_date");
    localStorage.removeItem("scheduled_start_time");
    localStorage.removeItem("scheduled_end_time");
    localStorage.removeItem("scheduled_time_zone");
    getTags().then((x) => setTags(x.data.response.items));
    Promise.allSettled([getFrameTags("first"), getFrameTags("second")]).then(
      ([x, y]) => {
        setFrameTags([
          ...x.value.data.response.items,
          ...y.value.data.response.items,
        ]);
      }
    );

    getCollectionsList("popular").then((x) => {
      let items = x.data.response.items.sort(
        (a, b) => a.sort_order - b.sort_order
      );
      setPopularCollections(items);
    });
    getCollectionsList("premium").then((x) => {
      let items = x.data.response.items.sort(
        (a, b) => a.sort_order - b.sort_order
      );
      setPremiumCollections(items);
    });
  }, []);

  /**
   * Allows bypassing user sign in for development purposes.
   */
  if (!user && process.env.NODE_ENV == "development") {
    let username = process.env.REACT_APP_USER;
    let password = process.env.REACT_APP_PASSWORD;
    Auth.signIn({ username, password });
  }

  return user ? (
    <div className="App">
      <Router basename="/">
        <Box>
          <NavBar />
        </Box>
        <Route exact path="/geo-settings/">
          <GeoSettings />
        </Route>
        <Route exact path="/sponsored/">
          <Box>
            <Sponsor />
          </Box>
        </Route>
        <Route
          exact
          path="/sponsored/:sponsor_id"
          render={(props) => (
            <Box>
              <NonSponsorTable {...props} />
            </Box>
          )}
        />
        <Route
          exact
          path="/sponsored/:sponsor_id/campaigns"
          render={(props) => (
            <Box style={{ marginTop: "10px" }}>
              <EditCampaign {...props} />
            </Box>
          )}
        />
        <Route exact path="/other-setups/">
          <Box>
            <ResponsiveDrawer />
            {/* <NonSponsorTable/> */}
          </Box>
        </Route>
        <Route exact path="/other-setups/city/">
          <Box>
            <ResponsiveDrawer />
          </Box>
        </Route>
        <Route exact path="/other-setups/non-sponsor">
          <ResponsiveDrawer />
        </Route>
        <Route path="/other-setups/non-sponsor/edit_campaign">
          <ResponsiveDrawer />
        </Route>
        <Route path="/other-setups/global">
          <ResponsiveDrawer />
        </Route>
        <Route path="/other-setups/carousel">
          <ResponsiveDrawer />
        </Route>
        <Route path="/other-setups/ar">
          <ResponsiveDrawer />
        </Route>
        <Route path="/other-setups/ar-experiences">
          <ResponsiveDrawer />
        </Route>
        <Route exact path="/places/">
          <Places />
        </Route>
        <Route exact path="/library/">
          <Library />
        </Route>
        <Route exact path="/app-badge-settings/">
          <AppBadge />
        </Route>
      </Router>
      <Button
        style={{ position: "absolute", left: "0px" }}
        onClick={() => Auth.signOut()}
      >
        {" "}
        Sign Out{" "}
      </Button>
    </div>
  ) : (
    <Button onClick={() => Auth.federatedSignIn()}>Please Sign In</Button>
  );
};

export default App;

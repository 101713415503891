import React, {useState, useEffect} from 'react';
import MaterialTable, {MTableBody, MTablePagination} from 'material-table';
import {Link, Typography} from '@material-ui/core';
import ViewLinks from '../ViewLinks';
import {Create, Delete} from '@material-ui/icons';
import {visitedLinksAtom} from '../../../App';
import {useAtom} from 'jotai';

let queryFilter = [];
let filterBy = null;

/**
 * Component used to list Campaign GeoFilters.
 *
 * @component
 */

function CampaignGeoFilters(props){

    const [pageSize, setPageSize] = useState(10);
    const [visitedLinks, setVisitedLinks] = useAtom(visitedLinksAtom);
    

    const table_styling = {headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold', borderRight: '1px solid white'},
        cellStyle: {borderRight: '.5px solid rgba(224, 224, 224, 1)'}};

    useEffect(()=>{
        return e => queryFilter = [];
    }, []);
    
    return(
        <>
        <ViewLinks 
            open={props.open}
            campaign_id={props.campaign_id}
            setIsLinksDialogOpen={props.setIsLinksDialogOpen}
            campaignLocationLinks={props.campaignLocationLinks}
            setSelectedFilter={props.setSelectedFilter}
            selectedFilter={props.selectedFilter} 
            refreshData={props.refreshData}
        />
        <MaterialTable
            title="GeoFilters"
            options={{
                filtering: true,
                pageSize,
                pageSizeOptions: [10, 20, 30]
            }}
            onChangeRowsPerPage={(e, v) => console.log(e, v)}
            components={{
                Pagination: props => {
                    delete props.classes.caption;
                    delete props.classes.selectRoot;
                    delete props.classes.toolbar;
                    return <MTablePagination {...props} onPageChange={props.onChangePage}/>
                },
                Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                    let nextFilterBy = value.filter(x => x !== filterBy);
                    filterBy = nextFilterBy[0];
                    queryFilter = [filterBy];
                    props.onFilterChanged(columnId, [filterBy]);
                }}/>
            }}
            columns={[
                { title: 'Id', field: 'frame_id', filtering: false, ...table_styling},
                { title: 'Filter Name', field: 'name', filtering: false, ...table_styling},
                { title: 'Status', field: 'active_status', ...table_styling, defaultFilter: queryFilter,
                    lookup: { 0: 'Inactive', 1: 'Active', 2: 'Scheduled Inactive', 3: 'Scheduled Active' }
                },
                { title: 'Linked', field: 'optin_algorithm', filtering: false, ...table_styling, 
                    render: rowData => (
                        <Link 
                            data-frame-id={rowData.frame_id} 
                            data-name={rowData.name} 
                            onClick={props.handleViewLinksClick}
                            style={{cursor: "pointer"}}
                        >View Links</Link>
                    )
                },
                { title: 'Links Count', field: 'location_count', filtering: false, ...table_styling},
                {
                    title: 'Actions', filtering: false,
                    render: rowData => (
                        <Typography>
                            <Link 
                                className={visitedLinks.filters.includes(rowData.frame_id) ? "visited" : ""}
                                style={{cursor: 'pointer'}}
                                onClick={e => {
                                    props.setSelectedFilter(rowData);
                                    props.setEditOpen(true)
                                    setVisitedLinks(x => {
                                        x.filters.push(rowData.frame_id)
                                        return x;
                                    })
                                }}
                            >
                                <Create style={{color: "black"}}/>
                                    <span 
                                        style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                        Edit
                                    </span>
                            </Link>&nbsp;&nbsp;
                            <Link 
                                href="/delete_filter"
                                onClick={event => {
                                    
                                    event.preventDefault();
                                    props.setSelectedFilter(rowData);
                                    props.setIsDeleteOpen(true);
                                }}
                            >
                                <Delete 
                                    style={{color: "black"}}
                                    onClick={e => props.setIsDeleteOpen(true)}
                                />
                                    <span 
                                        style={{verticalAlign: "bottom",lineHeight: '36px'}}>
                                        Delete
                                    </span>
                            </Link>
                        </Typography>
                    ),
                    headerStyle: {backgroundColor: '#9cadc3', fontWeight: 'bold'}
                },
            ]}
            actions={[
                {
                    icon: 'add',
                    tooltip: 'Add Campaign Geo Filter',
                    isFreeAction: true,
                    onClick: (event) => {
                        props.setEditOpen(true)
                    }
                }
            ]}
            data={props.campaignFilters}
        />
        </>
    )
}

export default CampaignGeoFilters;
import React from "react";
import { TextField } from "@material-ui/core/";

/**
 * Component used in filter designer to set Web AR link for 8th Wall.
 *
 * @component
 */

export default function WebArConfiguration(props) {
  const updateWebArLink = (e) => {
    let link = e.target.value;
    props.updateCanvasProps("web_ar", { link });
  };

  return (
    <div style={{ padding: "0 20px" }}>
      <TextField
        value={props.canvasProps.web_ar?.link}
        onChange={updateWebArLink}
        style={{ marginBottom: "10px", marginTop: 10 }}
        fullWidth
        size="small"
        id="webar-link"
        label="Web Ar Link"
        variant="outlined"
      />
    </div>
  );
}


import React, {useState, useEffect} from 'react';
import {TextField, Chip, Slider} from '@material-ui/core/';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Slide from '@material-ui/core/Slide';
import {tagsAtom, frameTagsAtom }  from '../../App';
import {atom, useAtom} from 'jotai';

import { saveFrameTags, deleteFrameTags} from "../../ApiUtility/ApiUtility";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Component used to set filter tags.
 *
 * @component
 */

export default function TagControl(props) {
    const [currentTags, setCurrentTags] = useState([])
    const [tags, setTags] = useAtom(tagsAtom);
    const [frameTags, setFrameTags] = useAtom(frameTagsAtom);

    const filter = createFilterOptions();

    const updateFrameTags = (nextCurrentTags) => {
        let index = frameTags.findIndex(x => x.frame_id == props.selectedFilter.frame_id);
        let nextFrameTags = JSON.parse(JSON.stringify(frameTags));
        if(index != -1) nextFrameTags[index].tags = nextCurrentTags;
        else nextFrameTags.push({active: 1, frame_id: props.selectedFilter.frame_id, tags: nextCurrentTags})
        setFrameTags(nextFrameTags);
        setCurrentTags(nextCurrentTags);
    }

    useEffect(() => {
        setCurrentTags(frameTags.find(x => x.frame_id == props.selectedFilter.frame_id)?.tags || [])
    }, [props.selectedFilter.frame_id])
    
    return(
    <Autocomplete
        multiple
        id="tags-control"
        className='tooltipHover'
        disablePortal={true}
        freeSolo
        options={tags}
        value={currentTags}
        filterOptions={(options, params) => {
            let filtered = filter(options, params).filter(o => !currentTags.some(ct => ct.tag_name == o.tag_name)); 

            const { inputValue } = params;
            // Suggest the creation of a new value
            const optionIndex = filtered.findIndex((option) => inputValue === option.tag_name);
            if (inputValue !== '' && optionIndex == -1) filtered = [{tag_name: inputValue}, ...filtered];
            else if(optionIndex != -1){
                let matchingOption = filtered.splice(optionIndex, 1);
                filtered = [matchingOption[0], ...filtered];
            }
            return filtered;
        }}
        renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
            <div style={{marginRight: '15px'}}>
            <Chip
                label={option.tag_name}
                {...getTagProps({ index })}>
            </Chip>
            <Slider defaultValue={option.relevance || 0} step={1} marks min={0} max={5} 
                getAriaValueText={e => e}
                valueLabelDisplay="auto"
                onChangeCommitted={(e, v) => {
                let nextCurrentTags = JSON.parse(JSON.stringify(currentTags));
                let index = nextCurrentTags.findIndex(x => x.tag_name == option.tag_name);
                nextCurrentTags[index].relevance = v;
                console.log("nextCurrentTags: ", nextCurrentTags);
                saveFrameTags(props.selectedFilter.frame_id, nextCurrentTags);
                updateFrameTags(nextCurrentTags);
                }}
            />
            </div>
            ))
        }
        onChange={ (e, v, reason) => {
            if(reason == 'remove-option' && e.type == "keydown") return;
            if(currentTags.length > v.length){
                var result = currentTags.filter(e => !v.find(a => e.tag_name == a.tag_name));
                deleteFrameTags(props.selectedFilter.frame_id, result[0].tag_id);
            }else{
                v.forEach((t, i) => {
                    if (typeof t == "string") {
                        v[i] = {relevance: 0, tag_name: t}
                    }
                })
                saveFrameTags(props.selectedFilter.frame_id, v);
            }
            updateFrameTags(v)
        }}
        getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
            return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
            return option.inputValue;
            }
            // Regular option
            return option.tag_name;
        }}
        renderInput={(params) => (
            <TextField
            {...params}
            InputProps={{ ...params.InputProps, endAdornment : null }}
            variant="standard"
            label="Tags"
            placeholder="Add Tags"
            />
        )}
    />)
}
import React, { useState } from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import {
  Settings,
  PeopleOutline,
  Person,
  Room,
  Collections,
  PhoneIphone,
} from "@material-ui/icons/";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { testCameraPlus } from "../ApiUtility/ApiUtility";
import { pageHistoryAtom } from "../App";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";

function getTabValue() {
  const location = window.location.href;
  if (location.includes("geo-settings")) return 0;
  if (location.includes("sponsored")) return 1;
  if (location.includes("other-setups")) return 2;
  if (location.includes("places")) return 3;
  if (location.includes("library")) return 4;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "80vh",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    background: "#487898",
    color: "white !important",
  },
  tab: {
    color: "white !important",
  },
}));

/**
 * Top Navigation Component
 *
 * @component
 */

function NavBar(props) {
  const classes = useStyles();
  let [value, setValue] = useState(getTabValue());
  const [pageHistory, setPageHistory] = useAtom(pageHistoryAtom);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const resetPageHistory = () => {
    setPageHistory((x) => {
      delete x.nonSponsor.active;
      delete x.nonSponsor.pageNumber;
      return x;
    });
  };

  const handleClick = (event, url) => {
    if (!url) return;
    event.preventDefault();
    window.location.href = url;
  };

  return (
    <>
      <AppBar position="static" style={{ background: "#487898" }}>
        <Grid container>
          <Grid style={{ textAlign: "left" }} item xs={4}>
            <img
              src={process.env.PUBLIC_URL + "/MS_Logo_White.png"}
              onClick={testCameraPlus}
              style={{
                width: "147px",
                position: "absolute",
                top: "18px",
                paddingRight: "2px",
              }}
            />
            <div style={{ width: "147px", textAlign: "right" }}>v2.0</div>
          </Grid>
          <Grid item xs={8}>
            <Tabs
              variant="standard"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
              TabIndicatorProps={{ style: { background: "white" } }}
            >
              <Link
                disabled
                className={classes.tab}
                to={"/geo-settings"}
                onClick={(e) => setValue(0)}
              >
                <Tab label="Geo Settings" icon={<Settings />} />
              </Link>
              <Link
                className={classes.tab}
                to={"/sponsored"}
                onClick={(e) => {
                  setValue(1);
                  resetPageHistory();
                }}
              >
                <Tab
                  label="Sponsored"
                  icon={<PeopleOutline />}
                  onClick={handleClick}
                />
              </Link>
              <Link
                id="other_setups_link"
                className={classes.tab}
                to={"/other-setups/city"}
                onClick={(e) => setValue(2)}
              >
                <Tab label="Menu" icon={<Person />} onClick={handleClick} />
              </Link>
              <Link
                className={classes.tab}
                to={"/places"}
                onClick={(e) => setValue(3)}
              >
                <Tab label="Places" icon={<Room onClick={handleClick} />} />
              </Link>
              <Link
                className={classes.tab}
                to={"/library"}
                onClick={(e) => setValue(4)}
              >
                <Tab
                  label="Library"
                  icon={<Collections onClick={handleClick} />}
                />
              </Link>
              <Link
                disabled
                className={classes.tab}
                to={"/app-badge-settings"}
                onClick={(e) => setValue(5)}
              >
                <Tab label="App Badge" icon={<PhoneIphone />} />
              </Link>
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}

export default NavBar;

import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import {deleteFilter, deleteLocation} from '../../ApiUtility/ApiUtility';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    textAlign: "center"
  },
}));

/**
 * Component used to delete filters and campaigns.
 *
 * @component
 */

function DeleteItemDialog(props) {
  
  const [wasItemDeleted, setWasItemDeleted] = useState(false);

  const classes = useStyles();
  const { open } = props;

  const handleClose = () => {
    if(wasItemDeleted) props.refreshData();
    props.setIsDeleteOpen(false);
    setTimeout(()=>{
      setWasItemDeleted(false);
    }, 1000);
  };

  const handleDeleteClick = async e => {
    let deleteItem = props.deleteItem;
    if(!deleteItem) deleteItem = props.selectedItem.location_id ? deleteLocation : deleteFilter;
    try{
      let response = await deleteItem(props.selectedItem.frame_id || props.selectedItem.location_id);
      setWasItemDeleted(true);
      props.setSelectedItem({});
      
    }catch(err){
      
    }
    props.refreshData();
  }

  return (
    <Dialog style={{textAlign: 'center'}} 
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {wasItemDeleted ? 'Item Was Successfully Deleted' : 'Are you sure you want to delete this Item?'}
      </DialogTitle>
      <div className={wasItemDeleted ? `${classes.root} hidden` : `${classes.root}`}>
        <Button id="yes_delete_filter" variant="contained" color="primary" onClick={handleDeleteClick}>
          Yes
        </Button>
        <Button 
          variant="contained" color="secondary"
          onClick={event => props.setIsDeleteOpen(false)}
        >
          No
        </Button>
      </div>
      <div className={wasItemDeleted ? `${classes.root}` : `${classes.root} hidden`}>
        <Button id="ok_filter_deleted" variant="contained" onClick={handleClose}>
          Ok
        </Button>
      </div>
    </Dialog>
  );
}

export default DeleteItemDialog;

import React, {useState, useEffect } from 'react';
import {Tabs, Tab, Paper, TextField, CircularProgress} from '@material-ui/core';
import TabPanel from '../TabPanel';
import 'typeface-roboto';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {searchFilters, getFramesByIds, cloudSearchQuery, getPlacesCategories} from '../../ApiUtility/ApiUtility';
import FilterCard from './FilterCard';
import {frameTagsAtom} from '../../App';
import {useAtom} from 'jotai';

/**
 * Component allowing the searching and tagging of all content.
 *
 * @component
 */

function Library (){
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [value, setValue] = useState(0);
    const [autoCompleteValue, setAutoCompleteValue] = useState("");
    const [frameTags, setFrameTags] = useAtom(frameTagsAtom);
    const [categories, setCategories] = useState([]);

    const [dataSource, setDataSource] = useState([]);
    const [queriedCount, setQueriedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [loadMoreActive, setLoadMoreActive] = useState(false);

    const handleAutocompleteChange = async (e, v, reason) => {
        let endpoint;
        if(value == 0) endpoint = `/CameraPlus/frame/search?q=${v}`;
        else if(value == 1) endpoint = `/CameraPlus/tags/search?q=${v}`;
        else if(value == 2) endpoint = `/CameraPlus/aws/cloudsearch/suggest?q=${v}`;
        if(reason !== 'clear') setAutoCompleteValue(v);
        else{
            setAutoCompleteValue("");
            v = "";
        } 
        if(v == "" || reason == 'clear') return setDataSource([]);
        let r = await searchFilters(endpoint);
        let items = formatOptions(r.data.response.items);
        if(value == 2 && items.findIndex(x => x.name == v) == -1) items = [{name: v}, ...items];
        setDataSource(items);
    }

    function formatOptions(items) {
        let itemsFormatted;
        if(value == 1) {
            itemsFormatted = items.map(x => {
                x.name = x.tag_name;
                return x;
            })
        }else if(value == 2){
            itemsFormatted = items.map(x => {
                x.name = x.suggestion;
                return x;
            })
        }else {
            itemsFormatted = items;
        }
        return itemsFormatted;
    }

    function handleChange(e, i){
        setSelectedFilters([]);
        setAutoCompleteValue("");
        setValue(i);
    }


    const setFiltersByTags = async (v) => {
        let frameIds = frameTags.filter(ft => ft.tags.findIndex(t => t.tag_name == v.name) !== -1).map(ft => ft.frame_id);
        if(frameIds.length == 0 ) return setSelectedFilters([]);
        let selectedFilters = await getFramesByIds(frameIds);
        setSelectedFilters(selectedFilters.data.response.items);
    }

    const setFilltersByUXSearch  = async (v) => {
        setLoadMoreActive(true);
        let cloudsearchFilters = await cloudSearchQuery(v.name || autoCompleteValue, selectedFilters.length);
        let frameIds = cloudsearchFilters.data.response.items.map(x => parseInt(x.fields.frame_id[0]));
        let nextSelectedFilters = await getFramesByIds(frameIds);
        let sortedItems = frameIds.map(x => nextSelectedFilters.data.response.items.find(i => i.frame_id == x));
        setLoadMoreActive(false);
        setTotalCount(cloudsearchFilters.data.response.total)
        setQueriedCount(c => c + 10);
        setSelectedFilters(x => [...x, ...sortedItems]);
    }

    const getLabel = () => {
        if(value == 0) return "Filter";
        if(value == 1) return "Tag";
        if(value == 2) return "Search Term";
    }

    const renderFilters = () => {
        return selectedFilters.map(x => {
            return (
            <FilterCard 
                key={x.frame_id} 
                categories={categories}
                filter={x}
                height={'250px'}
                width={'187.5px'}
            />)
        })
    }

    useEffect(() => {
        getPlacesCategories().then( cats => {
            let categoriesFlattened = [];

            function flattenCategories(category){
                categoriesFlattened = [category, ...categoriesFlattened];
                if(category.categories.length == 0) return;
                for(var i in category.categories){
                    flattenCategories(category.categories[i]);
                }
            }

            for(var i in cats){
                flattenCategories(cats[i]);
            }
            setCategories(categoriesFlattened);
        })
    }, [])


    return (
        <>
            <div style={{position: 'relative', marginBottom: '10px'}}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab id="geo_filters" label="Filters" /> 
                <Tab id="rankings" label="Tags" />
                <Tab id="geo_locations" label="UX Search" />
            </Tabs>
            </div>
            <div style={{width: '20%', margin: '0 auto'}}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dataSource}
                getOptionLabel={(option) => option.name}
                onInputChange={handleAutocompleteChange}
                inputValue={autoCompleteValue}
                onChange={(e,v) => {
                    if(!v) return setSelectedFilters([]);
                    if(value == 0) setSelectedFilters(x => [v, ...x]);
                    if(value == 1) setFiltersByTags(v);
                    if(value == 2) {
                        setFilltersByUXSearch(v);
                    }

                }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} 
                label={getLabel()} />}
            />
            </div>
            <Paper style={{minHeight: '70vh', position: 'relative'}}>
                <TabPanel value={value} index={0}>
                    {renderFilters()}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {renderFilters()}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {renderFilters()}
                </TabPanel>
                {(value == 2 && selectedFilters.length > 0 && queriedCount < totalCount && !loadMoreActive)  && 
                    <div style={{position: 'absolute', bottom: '0px', textDecoration: 'underline', width: '100%', 
                    color: 'blue', cursor: 'pointer', textAlign: 'center'}}
                    onClick={setFilltersByUXSearch}
                    >Load More</div>}
                {loadMoreActive && <CircularProgress size={24} 
                />}
            </Paper>
            
        </>
    )
  }

  export default Library;
import React, {useEffect, useRef } from "react";
import { getEnvironment, usePrevious } from "../../../CommonFunctions";

let deepAR;

const qaKey =
  "d7e129fd9c96894f76988274260ede69a3d5f34d8f2dc0c2ef687b0bdc649d1c80c205caffe7797b";
const prodKey =
  "201c8925e1c1a9e47ae3e8d2906fba33851f3e0b1e15cbd2ade8c29fd3525200c807398deb430584";

/**
 * Component used to preview AR effects from DeepAR.
 *
 * @component
 */

export default function ARPreview(props) {
  const canvasRef = useRef();
  const prevSelectedBinaries = usePrevious(props.selectedArBinaries);

  const setDeepAREffects = () => {
    if (props.selectedArBinaries.length > 1) props.setIsARLoading(true);
    let gdrive_binary_url =
      "https://www.googleapis.com/drive/v3/files/{fileId}?supportsAllDrives=true&includeItemsFromAllDrives=true&mimeType=application/octet-stream&alt=media";
    props.selectedArBinaries.forEach((b, i) => {
      if (b.id)
        deepAR.switchEffect(
          0,
          `slot${i}`,
          gdrive_binary_url.replace("{fileId}", b.id),
          () => {
            if (i == props.selectedArBinaries.length - 1)
              props.setIsARLoading(false);
          }
        );
      else {
        deepAR.switchEffect(0, `slot${i}`, b.url, () => {
          if (i == props.selectedArBinaries.length - 1)
            props.setIsARLoading(false);
        });
      }
    });
  };
  const clearArBinaries = (count) => {
    let i = 0;
    while (i < count) {
      deepAR.clearEffect(`slot${i}`);
      i += 1;
      if (i > 20) break;
    }
  };

  const initDeepAR = () => {
    deepAR = window.DeepAR({
      canvasWidth: props.canvasWidth || 500,
      canvasHeight: props.canvasHeight || 500,
      licenseKey: getEnvironment().includes("qa") ? qaKey : prodKey,
      canvas: canvasRef.current,
      numberOfFaces: 1,
      libPath: "/deepar_lib",
      segmentationInfoZip: "segmentation.zip",
      onInitialize: function () {
        deepAR.startVideo(true);
        setDeepAREffects();
      },
    });
    window.checkDeepAR = deepAR;

    deepAR.downloadFaceTrackingModel("/deepar_lib/models-68-extreme.bin");
  };

  useEffect(() => {
    return () => {
      if (!!deepAR)
        try {
          deepAR.stopVideo();
        } catch (err) {
          console.log("err: ", err);
        }
      props.setSelectedDeepArVersion(null);
      props.setSelectedArBinaries([]);
    };
  }, []);

  useEffect(() => {
    if (!!canvasRef.current && props.campaign.name === "AR") initDeepAR();
  }, [canvasRef]);

  useEffect(() => {
    if ((!deepAR || !!canvasRef.current) && props.selectedArBinaries.length > 0)
      initDeepAR();
    else if (deepAR && props.selectedArBinaries.length > 0) {
      setDeepAREffects();
      deepAR.startVideo(true);
    }
    if (props.selectedArBinaries.length == 0 && prevSelectedBinaries)
      clearArBinaries(prevSelectedBinaries.length);
    if (
      props.selectedArBinaries.length == 0 &&
      props.campaign.name !== "AR" &&
      !!deepAR
    ) {
      deepAR.stopVideo();
    }
  }, [props.selectedArBinaries]);

  return (
    <div
      style={{
        visibility:
          props.campaign.name !== "AR" && props.selectedArBinaries.length == 0
            ? "hidden"
            : "initial",
        position: "absolute",
        width: props.canvasWidth + "px",
        height: props.canvasHeight + "px",
      }}
    >
      <canvas
        id="deepar-canvas"
        ref={canvasRef}
        style={{
          width: props.canvasWidth + "px",
          height: props.canvasHeight + "px",
        }}
      />
    </div>
  );
}

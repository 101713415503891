import React, {useRef, useEffect} from 'react';
import { Stage, Layer, Image, Text, Label, Tag } from 'react-konva';
import {getXOffset, getYOffset} from '../../CommonFunctions';
import moment from 'moment';

/**
 * Component used to display text on filter designer Konva canvas.
 *
 * @component
 */

export default function TextLayer(props){
  const {filterOrientationType} = props;
  const labelRef = useRef();

  let scale = (props.canvasHeight || window.innerHeight*.6375)/1080;
    if(props.alignment == "horizontal" && filterOrientationType == "vertical") scale *= .75;
    else if(props.alignment == "horizontal") scale *= .75;
    else if(props.alignment == "vertical" && filterOrientationType == "horizontal") scale = .88;
    if(filterOrientationType == "horizontal") scale /= .75;

  const getTextToDisplay = () => {
    if(["city", "freeformtext", "nonPartnerConfig"].includes(props.name)) return props.text;
    else return moment().format(props.text);
  }

  const shouldDisplayBorder = () => {
    if(props.name == "freeformtext" && props.hideOutline) return 0;
    else return 1;
  }

  const handleDrag = (e) => {
    let scale = window.innerHeight*.6375/1080;
    if(props.alignment == "horizontal" && filterOrientationType == "vertical") scale *= .75;
    else if(props.alignment == "horizontal") scale *= .75;
    else if(props.alignment == "vertical" && filterOrientationType == "horizontal") scale = .88;
    if(filterOrientationType == "horizontal") scale /= .75;
    let nextSettings = Object.assign({}, props.settings);
    let modCanvasProps = JSON.parse(JSON.stringify(props.canvasProps));
    let x_offset = getXOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType}); 
    let y_offset = getYOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType});

    nextSettings.x = parseInt(e.target.x()) / (scale / (window.innerHeight*.6375/1080)) - (x_offset / (scale / (window.innerHeight*.6375/1080)));
    nextSettings.y = parseInt(e.target.y()) / (scale / (window.innerHeight*.6375/1080)) - (y_offset / (scale / (window.innerHeight*.6375/1080)));

    props.updateCanvasProps(props.name, nextSettings);
  }

  useEffect(() => {
    if(labelRef.current) labelRef.current.draw();
  }, [props.fonts])

  if(!props.settings) return <Label/>;

  if(JSON.parse(props.settings?.display || props.name == "nonPartnerConfig") && !props.hide){
        return (
          <Label
            x={parseInt(props.settings.x) || 0}
            y={parseInt(props.settings.y) || 0}
            ref={labelRef}
            width={parseInt(props.settings.length)}
            draggable={props.canvasHeight ? false : true}
            onDragEnd={handleDrag}
          >
            <Tag 
              strokeWidth={ shouldDisplayBorder() } // border width
              stroke="black"
              width={parseInt(props.settings.length) || 0}
            />
            <Text
              text={getTextToDisplay()}
              shadowColor={"#" + props.settings.shadowColor}
              shadowBlur={props.settings.shadowBlur}
              shadowOpacity={props.settings.shadowOpacity}
              shadowOffsetX={props.settings.shadowOffsetX}
              shadowOffsetY={props.settings.shadowOffsetY}
              strokeWidth={parseInt(props.settings.strokeWidth)}
              stroke={"#" + props.settings.strokeColor}
              fontFamily={props.settings.textFont}
              fontSize={parseInt(props.settings.textSize) * scale * .75 || 0}
              fill={"#" + props.settings.textColor}
              width={parseInt(props.settings.length) || 0}
              align={props.settings.alignment}
            />
          </Label>
      )
  }else{
      return <Label/>
  }
  };
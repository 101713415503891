import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Amplify, Storage, API } from "aws-amplify";
import awsconfig from "./aws-exports";
import { createBrowserHistory } from "history";
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

if (window.location.origin !== "https://cameraplus.studio") {
  awsconfig.Storage = {
    AWSS3: {
      bucket: "cameraplus-qa", //REQUIRED -  Amazon S3 bucket name
      region: "us-east-1", //OPTIONAL -  Amazon service region
    },
  };
}
Amplify.configure(awsconfig);

const history = createBrowserHistory();

let app = document.getElementById("root");

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) history.replace(path);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect } from "react";
import MaterialTable, { MTableBody, MTablePagination } from "material-table";
import { Typography, Box } from "@material-ui/core";
import { Create, Delete, ChevronLeft } from "@material-ui/icons";
import DeleteCampaignDialog from "./DeleteCampaignDialog";
import CreateCampaignDialog from "./CreateCampaignDialog";
import CheckIcon from "@material-ui/icons/Check";
import { Route, Link } from "react-router-dom";

import {
  applySchedActiveStatus,
  getStatusFilterStatement,
  getAPICall,
  formatSearch,
} from "../../CommonFunctions";
import { getUrlVars } from "../../ApiUtility/ApiUtility";
import { pageHistoryAtom, premiumCollectionsAtom } from "../../App";
import { useAtom } from "jotai";

let queryFilter = [];

/**
 * Component for listing and managing Non Sponsored Campaigns.
 *
 * @component
 */

function NonSponsorTable(props) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [pageHistory, setPageHistory] = useAtom(pageHistoryAtom);
  const [premiumCollections, setPremiumCollections] = useAtom(
    premiumCollectionsAtom
  );

  const tableRef = React.createRef();
  const retailerId = props.match ? props.match.params.sponsor_id : 0;
  let filterBy = null;

  useEffect(() => {
    return (e) => {
      queryFilter = [];
      setPageHistory((x) => {
        pageHistory.nonSponsor.active = true;
        return x;
      });
    };
  }, []);

  const getEditCampaignLink = () => {
    if (retailerId == 0)
      return "/other-setups/non-sponsor/edit_campaign?campaign_id=";
    else return `/sponsored/${retailerId}/campaigns?campaign_id=`;
  };

  const refreshData = () => {
    tableRef.current.onQueryChange();
  };

  const campaignTableTitle = () => {
    if (!retailerId) return "Campaigns";
    else {
      return (
        <Box style={{ textAlign: "left" }} pl={2}>
          <Link style={{ color: "black" }} to={"/sponsored"}>
            <ChevronLeft style={{ verticalAlign: "middle" }} fontSize="large" />
            <Typography
              style={{ display: "inline-block", verticalAlign: "middle" }}
              variant="h6"
            >
              {decodeURIComponent(getUrlVars()["sponsor_name"])} --- Campaigns
            </Typography>
          </Link>
        </Box>
      );
    }
  };
  if (
    pageHistory.nonSponsor.active &&
    (!pageHistory.nonSponsor.hasOwnProperty("pageNumber") ||
      pageHistory.nonSponsor.pageNumber === 0)
  ) {
    setPageHistory((x) => {
      delete x.nonSponsor.active;
      x.nonSponsor.pageNumber = 0;
      return x;
    });
  }

  return (
    <>
      <DeleteCampaignDialog
        open={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        selectedCampaignId={selectedCampaignId}
        setSelectedCampaignId={setSelectedCampaignId}
        refreshData={refreshData}
      />
      <CreateCampaignDialog
        retailerId={retailerId}
        open={isCreateDialogOpen}
        setIsCreateDialogOpen={setIsCreateDialogOpen}
        refreshData={refreshData}
      />
      <MaterialTable
        title={campaignTableTitle()}
        tableRef={tableRef}
        options={{
          filtering: true,
          pageSize,
          pageSizeOptions: [10, 20, 30],
        }}
        components={{
          Pagination: (props) => {
            delete props.classes.caption;
            delete props.classes.selectRoot;
            delete props.classes.toolbar;
            return (
              <MTablePagination {...props} onPageChange={props.onChangePage} />
            );
          },
          Body: (props) => (
            <MTableBody
              {...props}
              onFilterChanged={(columnId, value) => {
                let nextFilterBy = value.filter((x) => x !== filterBy);
                filterBy = nextFilterBy[0];
                props.onFilterChanged(columnId, [filterBy]);
              }}
            />
          ),
        }}
        onChangePage={(p) => {
          setPageHistory((x) => {
            x.nonSponsor.pageNumber = p;
            return x;
          });
        }}
        columns={[
          {
            title: "Id",
            field: "campaign_id",
            filtering: false,
            headerStyle: {
              backgroundColor: "#9cadc3",
              fontWeight: "bold",
              borderRight: "1px solid white",
            },
            cellStyle: { borderRight: ".5px solid rgba(224, 224, 224, 1)" },
          },
          {
            title: "Campaign Name",
            field: "name",
            filtering: false,
            headerStyle: {
              backgroundColor: "#9cadc3",
              fontWeight: "bold",
              borderRight: "1px solid white",
            },
            cellStyle: { borderRight: ".5px solid rgba(224, 224, 224, 1)" },
          },
          {
            title: "Status",
            field: "active_status",
            headerStyle: {
              backgroundColor: "#9cadc3",
              fontWeight: "bold",
              borderRight: "1px solid white",
            },
            cellStyle: { borderRight: ".5px solid rgba(224, 224, 224, 1)" },
            lookup: {
              0: "Inactive",
              1: "Active",
              2: "Scheduled Inactive",
              3: "Scheduled Active",
            },
            defaultFilter: queryFilter,
          },
          {
            title: "Show in Carousel",
            field: "optin_algorithm",
            filtering: false,
            headerStyle: {
              backgroundColor: "#9cadc3",
              fontWeight: "bold",
              borderRight: "1px solid white",
            },
            cellStyle: { borderRight: ".5px solid rgba(224, 224, 224, 1)" },
            render: (rowData) =>
              rowData.optin_algorithm == 1 && (
                <CheckIcon style={{ color: "green" }} />
              ),
          },
          {
            title: "Show in Menu List",
            field: "optin_menu",
            filtering: false,
            headerStyle: {
              backgroundColor: "#9cadc3",
              fontWeight: "bold",
              borderRight: "1px solid white",
            },
            cellStyle: { borderRight: ".5px solid rgba(224, 224, 224, 1)" },
            render: (rowData) =>
              rowData.optin_menu == 1 && (
                <CheckIcon style={{ color: "green" }} />
              ),
          },
          {
            title: "Show As Popular Collection (Trending)",
            field: "optin_menu",
            filtering: false,
            headerStyle: {
              backgroundColor: "#9cadc3",
              fontWeight: "bold",
              borderRight: "1px solid white",
            },
            cellStyle: { borderRight: ".5px solid rgba(224, 224, 224, 1)" },
            render: (rowData) =>
              premiumCollections.find(
                (x) => x.campaign_id == rowData.campaign_id
              ) && <CheckIcon style={{ color: "green" }} />,
          },
          {
            title: "Actions",
            filtering: false,
            render: (rowData) => (
              <>
                <Typography>
                  <Link to={getEditCampaignLink() + rowData.campaign_id}>
                    <Create style={{ color: "black" }} />
                    <span
                      style={{ verticalAlign: "bottom", lineHeight: "36px" }}
                    >
                      Edit
                    </span>
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    href="/delete_campaign"
                    onClick={(event) => {
                      event.preventDefault();
                      setSelectedCampaignId(rowData.campaign_id);
                      setIsDeleteDialogOpen(true);
                    }}
                  >
                    <Delete style={{ color: "black" }} />
                    <span
                      style={{ verticalAlign: "bottom", lineHeight: "36px" }}
                    >
                      Delete
                    </span>
                  </Link>
                </Typography>
                <Route
                  path={
                    "/other-setups/non-sponsor/edit_campaign?campaign_id=" +
                    rowData.campaign_id
                  }
                >
                  Here route
                </Route>
              </>
            ),
            headerStyle: { backgroundColor: "#9cadc3", fontWeight: "bold" },
          },
        ]}
        actions={[
          {
            icon: "add",
            tooltip: "Add Campaign",
            isFreeAction: true,
            onClick: (event) => setIsCreateDialogOpen(true),
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            if (
              pageHistory.nonSponsor.active &&
              pageHistory.nonSponsor.pageNumber
            ) {
              query.page = pageHistory.nonSponsor.pageNumber;
              setPageHistory((x) => {
                delete x.nonSponsor.active;
                return x;
              });
            }
            let { active, jobFlag } = getStatusFilterStatement(query.filters);
            queryFilter = query.filters[0].value;
            setPageSize(query.pageSize);
            let url = `/CameraPlus/retailer/campaign/list?retailer_id=${retailerId}&app_type=LIV_HD_CAM`;
            url += "&size=" + query.pageSize;
            url += "&from=" + query.page * query.pageSize;
            url += "&keyword=" + formatSearch(query.search);
            if (query.filters[0].value[0])
              url += `&active=${active == 0 ? 2 : 1}`;
            else url += "&active=0";
            if (query.filters[0])
              url += `&jobFlag=${typeof jobFlag == "undefined" ? "" : jobFlag}`;
            getAPICall(url)
              .then((result) => {
                resolve({
                  data: applySchedActiveStatus(
                    result.data.response.items || []
                  ),
                  page: pageHistory.nonSponsor.active
                    ? pageHistory.nonSponsor.pageNumber || 0
                    : query.page || 0,
                  totalCount: result.data.response.total,
                });
              });
          })
        }
      />
    </>
  );
}

export default NonSponsorTable;

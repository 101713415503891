import React, {useState, useEffect, useMemo, useRef} from 'react';
import useImage from 'use-image';
import { Image, Label } from 'react-konva';
import { parseStringToInt, getXOffset, getYOffset} from '../../../CommonFunctions';
import {getStaticAssetPath} from '../../../ApiUtility/ApiUtility';
import '../../../App.css';
import 'gifler';

/**
 * Component used to display animated sticker GIFs.
 *
 * @component
 */

const GifImage = (props) => {
    let scale = (props.canvasHeight || window.innerHeight*.6375)/1080;
    const {uploadedGif, selectedFilter} = props;

    const [imageUrl, setImageUrl] = useState("");
    const [imageNode, setImageNode] = useState(null);
    const [gifHeight, setGifHeight] = useState();
    const [gifWidth, setGifWidth] = useState();

    const imageRef = useRef(null);
    const canvas = useMemo(() => {
        const node = document.createElement("canvas");
        return node;
    }, [uploadedGif, selectedFilter]);

  if(props.alignment == "horizontal" && props.filterOrientationType == "vertical") scale *= .75;
  else if(props.alignment == "horizontal") scale *= .75;
  else if(props.alignment == "vertical" && props.filterOrientationType == "horizontal") scale = .88;

  const [image, status] = useImage(imageUrl, 'Anonymous');


  const handleGifPosition = (gifCoordinates) => {
    let {filterOrientationType} = props;
    if(filterOrientationType == "horizontal") scale /= .75;
    let nextSettings = JSON.parse(JSON.stringify(props.canvasProps.upload_images));
    let modCanvasProps = JSON.parse(JSON.stringify(props.canvasProps));
    let x_offset = getXOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType}); 
    let y_offset = getYOffset({alignment: props.alignment, canvasProps: modCanvasProps, filterOrientationType});

    if(!nextSettings.gif) nextSettings.gif = {};
    nextSettings.gif.x = gifCoordinates.x - x_offset;
    nextSettings.gif.y = gifCoordinates.y - y_offset;
    nextSettings.sticker = nextSettings.gif; 
    nextSettings.positionedImage = nextSettings.gif; 
    props.updateCanvasProps('upload_images', nextSettings);
  }

  useEffect(() => {
    const checkSetHostedGif = async () => {
      if(!!uploadedGif.url) return;
      let url  = await getStaticAssetPath(selectedFilter.frame_id, "gif");
      setImageUrl(url);
      }
      checkSetHostedGif();
    }, [selectedFilter.frame_id]);

  useEffect(() => {
    if(!!uploadedGif.url) {
      setImageUrl(uploadedGif.url);
    }
  },[uploadedGif])

  useEffect(()=> {
    if(!imageNode) return;
    setGifHeight(imageNode.height * scale);
    setGifWidth(imageNode.width * scale);
  }, [imageNode]);

  useEffect(()=> {
    if(status == "loaded") setImageNode(image);
  },[status]);

    useEffect(() => {
        // save animation instance to stop it on unmount;
        let anim;
        if(!!uploadedGif.url){
            window.gifler(uploadedGif.url).get(a => {
                anim = a;
                anim.animateInCanvas(canvas);
                anim.onDrawFrame = (ctx, frame) => {
                    ctx.drawImage(frame.buffer, frame.x, frame.y);
                    if(imageRef.current) imageRef.current.getLayer().draw();
                };
            });
        }
        if(anim) return () => anim.stop();
    }, [canvas, uploadedGif]);

  if(!!props.hide) return <Label/>
  if(!uploadedGif.url) return <Label/>
  

  return (
    <Image 
      x={parseStringToInt(props.canvasProps.upload_images.gif?.x || 0)}
      y={parseStringToInt(props.canvasProps.upload_images.gif?.y || 0)}
      height={gifHeight}
      width={gifWidth}
      draggable={props.canvasHeight ? false : true}
      image={canvas}
      ref={imageRef} 
      onDragEnd={e => {
        handleGifPosition({x: parseInt(e.target.x()), y: parseInt(e.target.y())});
      }}
    />
  )
};

export default GifImage;
import React, {useState} from 'react';
import {Typography, Button, Table, TableBody, TableCell, Box, TextField,
    TableContainer, TableHead, TableRow, Paper, Link, IconButton} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Close} from '@material-ui/icons';
import {useDropzone} from 'react-dropzone';
import ImageDialog from './ImageDialog';
import { readUploadedImage, parseStringToInt } from '../../CommonFunctions';
import { deleteFilterImage } from '../../ApiUtility/ApiUtility';

const styles = {
    container: {
        height: '50px'
    },
    label: {
        marginRight: '10px',
        display: "inline-block"
    },
    uploadButton: {
        fontSize: '10px',
        padding: '5px 5px'
    },
    closeButton: {
        padding: '0px'
    },
    section_container: {
        marginTop: '10px',
        marginBottom: '10px'
    }
}

/**
 * Component used to upload Lottie Files.
 *
 * @component
 */

export default function LottieUpload(props) {

    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");

    //handle both sticker and filter types 
    const onDrop = async (file) => {
        let text = await readUploadedImage(file[0], "text");
        console.log({text});
        props.setUploadedLottie({source: 'local', data: JSON.parse(text)});
    }

    const {getRootProps: getRootPropsLottie, getInputProps: getInputPropsLottie} = useDropzone({onDrop: f => onDrop(f)});
    
    const handleCloseDialog = e => {
        setImageDialogOpen(false);
    }

    const handleClick = x => {
        setImageDialogOpen(true);
    }

    const handleDelete = type => {
        let filter_id = props.selectedFilter.frame_id;
        deleteFilterImage({type, filter_id, retailerId: 0, campaignId: props.campaign.campaign_id}).then(r => {
            props.setUploadedLottie({})
        }).catch(err => err)
    }

    const handleMovablePosition = (axis, value) => {
        let nextSettings = props.canvasProps.upload_images;
        nextSettings.lottie[axis] = parseStringToInt(value);
        props.updateCanvasProps('upload_images', nextSettings);
    }


  return (
    <>
    <ImageDialog 
        dialogOpen={imageDialogOpen}  
        dialogType={dialogType}
        handleCloseDialog={handleCloseDialog}
        selectedFilter={props.selectedFilter}
        uploadedLottie={props.uploadedLottie}
        lottieFile={true}
    />
    <TableContainer component={Paper}>
        <Alert className={!props.imageUploadErr ? "hidden" : ""} variant="outlined" severity="error">
            {props.imageUploadErr}
        </Alert>
    <Table aria-label="simple table">
        <TableHead>
            <TableRow>
            <TableCell>Image</TableCell>
            <TableCell align="right">View</TableCell>
            <TableCell align="right">Upload</TableCell>
            <TableCell align="right">Delete</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="Lottie">
                <TableCell component="th" scope="row">
                    <Typography variant="body2" style={styles.label} gutterBottom>Lottie</Typography>
                </TableCell>
                <TableCell align="right">
                    <Link onClick={e => handleClick("Lottie")} className={props.uploadedLottie.source == "remote" ? "" : "hidden"}>View</Link>
                    <IconButton className={props.uploadedLottie.source == "local" ? "" : "hidden"} style={styles.closeButton} 
                        aria-label="close" onClick={e => props.setUploadedLottie({})}>
                        <Close />
                    </IconButton>
                </TableCell>
                <TableCell align="right">
                    <div {...getRootPropsLottie({ refKey: 'innerRef' })}>
                        <input {...getInputPropsLottie()}/>
                        <Button variant="contained" color="primary" style={styles.uploadButton}>
                            Upload Image 
                        </Button>
                    </div>
            </TableCell>
            <TableCell align="right"> 
                <Link onClick={e => handleDelete("lottie")} className={props.uploadedLottie.source == "remote" ? "" : "hidden"}>
                    Delete
                </Link>
            </TableCell>
          </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
    <Box pl={1} style={styles.section_container}>
        <TextField id="position-x" 
            value={props.canvasProps.upload_images.lottie ? props.canvasProps.upload_images.lottie.x : "0"} 
            onChange={e => handleMovablePosition("x", e.target.value, ["lottie"])} 
            style={{width: "30%", marginRight: '5%'}} label="X" variant="outlined" />
        <TextField id="position-y" 
            value={props.canvasProps.upload_images.lottie ? props.canvasProps.upload_images.lottie.y : "0"} 
            onChange={e => handleMovablePosition("y", e.target.value, ["lottie"])} 
            style={{width: "30%", marginRight: '5%'}} label="Y" variant="outlined" />
    </Box>
  </>
  );
}

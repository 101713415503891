import React, { useEffect, useState } from "react";
import SettingsCard from "../SettingsCard";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import {
  getAppList,
  getExcludedApps,
  saveToExcludedList,
  removeFromExcludedList,
} from "../../ApiUtility/ExclusiveContent";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: 140,
  },
  formLabel: {
    display: "block",
  },
  formControl: {
    minWidth: "70%",
  },
  saveButton: {
    postion: "relative",
    right: "25px",
  },
  saveButtonContainer: {
    textAlign: "right",
  },
  buttonProgress: {
    position: "absolute",
  },
  setToRepeat: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
  },
});

/**
 * Component used to set filters to appear only on certain apps.
 *
 * @component
 */

export default function ExclusiveContentConfiguration(props) {
  const classes = useStyles();
  const { frameId, campaignId } = props;
  const [apps, setApps] = useState([
    { customer_name: "Capture" },
    { customer_name: "Camera+" },
  ]);
  const [excludedList, setExcludedList] = useState([]);

  const handleExclusiveContentChange = async (v, customerId) => {
    let [frame_id, campaign_id, customer_id] = [
      frameId,
      campaignId,
      customerId,
    ];
    if (!v) {
      setExcludedList((items) => [
        ...items,
        { frame_id, campaign_id, customer_id },
      ]);
      await saveToExcludedList({
        frameId: frameId,
        campaignId: campaignId,
        customerId,
      });
    } else {
      setExcludedList((items) =>
        items.filter((item) => item.customer_id !== customerId)
      );
      await removeFromExcludedList({
        frameId: frameId,
        campaignId: campaignId,
        customerId,
      });
    }
  };

  const fetchExcludedApps = () => {
    if (frameId || campaignId)
      getExcludedApps({ frameId, campaignId }).then(setExcludedList);
  };

  useEffect(() => {
    getAppList().then(setApps);
    fetchExcludedApps();
  }, []);

  return (
    <>
      <SettingsCard title="Exclusive Content Settings">
        {apps.map((x) => (
          <FormControlLabel
            className={classes.formLabel}
            control={
              <Checkbox
                disabled={!x.customer_id || (!frameId && !campaignId)}
                checked={
                  !excludedList.find(
                    (excluded) => x.customer_id == excluded.customer_id
                  )
                }
                color="primary"
                onChange={(e, v) =>
                  handleExclusiveContentChange(v, x.customer_id)
                }
                name={x.customer_name}
              />
            }
            label={`Show in ${x.customer_name}`}
          />
        ))}
      </SettingsCard>
    </>
  );
}

import moment from "moment-timezone";
import "moment-jdateformatparser";
import { API, Auth } from "aws-amplify";
import axios from "axios";
import { useRef, useEffect } from "react";
import defaultCanvasProps, {
  placesCanvasProps,
} from "./data/defaultCanvasProps";
import Location from "aws-sdk/clients/location";

export async function getAPICall(url, data, headers) {
  let apiName = "setupapi";
  let path = url;
  let myInit = { body: data };
  let needsAmplify =
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("cameraplus.studio");
  if (!needsAmplify && !data) return axios.get(url);
  else if (!needsAmplify && !!data) return axios.post(url, data);
  else if (needsAmplify && !data)
    return API.get(apiName, path).then((r) => ({ data: r }));
  else if (needsAmplify && !!data)
    return API.post(apiName, path, myInit).then((r) => ({ data: r }));
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function getPolygonCenter(arr) {
  var minX, maxX, minY, maxY;
  for (var i = 0; i < arr.length; i++) {
    minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
    maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
    minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
    maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
  }
  return { lat: (minX + maxX) / 2, lng: (minY + maxY) / 2 };
}

export function calcPolygonArea(vertices) {
  var sum = 0;
  for (var i = 0, l = vertices.length - 1; i < l; i++) {
    sum +=
      vertices[i][0] * vertices[i + 1][1] - vertices[i + 1][0] * vertices[i][1];
  }
  return Math.abs(sum / 2);
}

export function getEnvironment(withSSL) {
  let {
    location: { host },
  } = window;
  let pre = !!withSSL ? "https" : "http";
  if (host == "cameraplus.studio") return `https://prod`;
  else return `${pre}://qa`;
}

export function getEnvironmentCampaignIds() {
  let {
    location: { host },
  } = window;
  if (host == "cameraplus.studio")
    return {
      carousel: 615,
      global: 571,
      ar: 719,
      places: 329,
      arExperiences: 983,
    };
  else
    return {
      carousel: 258,
      global: 237,
      ar: 276,
      places: 189,
      arExperiences: 401,
    };
}

export const parseStringToInt = (v) => (v == "" ? 0 : parseInt(v));

export function applySchedActiveStatus(data) {
  data.forEach((x) => {
    if (!x.job) x.active_status = x.active;
    else x.active_status = x.active + 2;
  });
  return data;
}

export function formatSearch(term) {
  if (term == "#") return "%23";
  else return term;
}

export function getStatusFilterStatement(filters) {
  if (!filters[0]) return {};
  let value = filters[0].value[0];
  // 0 = inactive, 1 = active, 2 = scheduled inactive, 3 = scheduled active
  if (value == 0) return { active: 0, jobFlag: 0 };
  if (value == 1) return { active: 1, jobFlag: 0 };
  if (value == 2) return { active: 0, jobFlag: 1 };
  if (value == 3) return { active: 1, jobFlag: 1 };
  return { active: "", jobFlag: "" };
}

export function verifyDates(
  start,
  end,
  job = { start_cron_expression: "" },
  startDateValidation = true
) {
  if (job.start_cron_expression == "") return true;
  if (!job.start_cron_expression.includes("*") && !!startDateValidation)
    verifyStart(start);
  if (start > end) {
    throw {
      type: ["start_date", "end_date", "start_time", "end_time"],
      message: "Start Date Must be Less Than End Date",
    };
  } else return true;
}

export function verifyStart(start) {
  if (start < moment().format()) {
    throw {
      type: ["start_date", "start_time"],
      message: "Start Date Must Be Greater Than Current Date",
    };
  } else return true;
}

export function getRepeatEveryValue({ job }) {
  if (!job) return "";
  if (job.start_cron_expression.includes("/")) return "daily";
  if (job.start_cron_expression.includes("? *")) return "weekly";
  if (job.start_cron_expression.includes("* ?")) return "monthly";
  if (job.start_cron_expression.split(" ").length == 6) return "yearly";
  return "noRepeat";
}

export function getRepeatDayValue({ job }) {
  if (!job) return null;
  let index = job.start_cron_expression.indexOf("/") + 1;
  if (index == 0) return null;
  return parseInt(job.start_cron_expression.substr(index, 2));
}

export function getRepeatWeeklyValue({ job }) {
  if (!job)
    return {
      Mon: false,
      Tues: false,
      Wed: false,
      Thur: false,
      Fri: false,
      Sat: false,
      Sun: false,
    };
  let day_map = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
  let days = job.start_cron_expression.split(" ").slice(-1)[0].split(",");
  return day_map
    .map((x, i) => (days.includes(i + 1 + "") ? { [x]: true } : { [x]: false }))
    .reduce((a, c) => Object.assign(a, c), {});
}

export function getRepeatDayNames({ job }) {
  let day_map = {
    1: "Sun",
    2: "Mon",
    3: "Tues",
    4: "Wed",
    5: "Thurs",
    6: "Fri",
    7: "Sat",
  };
  let days = job.start_cron_expression
    .split(" ")
    .slice(-1)[0]
    .split(",")
    .map((x) => day_map[x]);
  return days.join();
}

export function readUploadedImage(file, type) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        resolve(reader.result);
      },
      false
    );

    // if (file) {
    if (type == "text") reader.readAsText(file);
    else if (type == "buffer") reader.readAsArrayBuffer(file);
    else reader.readAsDataURL(file);
    //   }
  });
}

export function formatGoogleLocationData(locationType, locationDetails) {
  let data;
  if (locationType == "cities") {
    console.log(locationDetails);
    data = {
      city_name: locationDetails[0].long_name,
      city_code: locationDetails[0].short_name,
      state_name: locationDetails[2].long_name,
      state_code: locationDetails[2].short_name,
      country_name: locationDetails[3]
        ? locationDetails[3].long_name
        : locationDetails[2].long_name,
      country_code: locationDetails[3]
        ? locationDetails[3].short_name
        : locationDetails[2].short_name,
      location_type: "city",
      location_name: locationDetails[0].long_name,
    };
  } else if (locationType == "zipCodes") {
    data = {
      zipcode: locationDetails[0].long_name,
      city_name: locationDetails[1].long_name,
      city_code: locationDetails[1].short_name,
      state_name: locationDetails[3].long_name,
      state_code: locationDetails[3].short_name,
      country_name: locationDetails[4].long_name,
      country_code: locationDetails[4].short_name,
      location_type: "zipcode",
      location_name: locationDetails[0].long_name,
    };
  } else if (locationType == "countries") {
    data = {
      country_name: locationDetails[0].long_name,
      country_code: locationDetails[0].short_name,
      location_type: "country",
      location_name: locationDetails[0].long_name,
    };
  } else if (locationType == "states") {
    data = {
      state_name: locationDetails[0].long_name,
      state_code: locationDetails[0].short_name,
      country_name: locationDetails[1].long_name,
      country_code: locationDetails[1].short_name,
      location_type: "state",
      location_name: locationDetails[0].long_name,
    };
  }
  return data;
}

export function turnBase64ToImg(base64) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = function () {
      resolve(image);
    };
    image.src = base64;
  });
}

export async function imageToDataUri(img, width, height, needsOffset) {
  if (!(img instanceof Image) && !(img instanceof HTMLCanvasElement))
    img = await turnBase64ToImg(img);
  // create an off-screen canvas
  var canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d"),
    offset = needsOffset ? -200 : 0;

  // set its dimension to target size
  canvas.width = width;
  canvas.height = height;

  // draw source image into the off-screen canvas:
  ctx.drawImage(img, offset, 0, width, height);
  // ctx.drawImage(img, 120, 0, width, height, 0, 0, width, height);
  // var image = new Image();
  // image.src = canvas.toDataURL();
  // encode image to data-uri with base64 version of compressed image
  return canvas.toDataURL();
}

export function getCanvasElementsToHide(selectedFilter) {
  let elements = ["city", "freeform", "date", "time"];
  if (selectedFilter.movable == 1)
    elements = ["date", "time", "placesText", "freeform", "city"];
  // else if() elements.push("sticker");
  return ["date", "time", "placesText", "freeform", "city"];
}

export function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  if (!r && !g && !b) return false;
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const adjustForInfinity = (v) => (isFinite(v) ? v : 0);

export function getXOffset({
  alignment: user_alignment,
  canvasProps,
  filterOrientationType,
}) {
  const {
    settings: { type, alignment },
  } = canvasProps;
  let scale = 1;
  if (type == 0 || type == 1) return 0;
  else if (type == 3 && user_alignment == "vertical")
    return 0 - adjustForInfinity(2 / alignment);
  else if (type == 2 && user_alignment == "horizontal")
    return (
      0 +
      (window.innerWidth * 0.893 - window.innerWidth * 0.673) / (2 / alignment)
    );
  return 0;
}

export function getYOffset({
  alignment: user_alignment,
  canvasProps,
  filterOrientationType,
}) {
  const {
    settings: { type, orienation },
  } = canvasProps;
  let scale = 1;
  if (type == 0 || type == 1) return 0;
  else if (type == 3 && user_alignment == "vertical")
    return 1 * adjustForInfinity(2 / orienation);
  else if (type == 2 && user_alignment == "horizontal")
    return (
      -1 *
      ((window.innerWidth * 0.893 - window.innerWidth * 0.673) /
        (2 / orienation))
    );
  return 0;
}

export function transformCanvasProps(
  canvasProps,
  filterOrientationType,
  alignment,
  x_factor,
  y_factor,
  d,
  scaleIn
) {
  let direction = d == "back" ? -1 : 1;
  let scale = scaleIn || 1;
  if (alignment == "horizontal" && filterOrientationType == "vertical")
    scale *= 0.75;
  if (alignment == "vertical" && filterOrientationType == "horizontal")
    scale *= 1 / 0.75;

  let variables = ["city", "date", "freeformtext", "time"];
  variables.forEach((v) => {
    canvasProps.variables[v].x *= scale;
    canvasProps.variables[v].y *= scale;
    canvasProps.variables[v].x += x_factor;
    canvasProps.variables[v].y += y_factor;
    // canvasProps.variables[v].y -= parseInt(canvasProps.variables[v].textSize) * .75 / 2;
    canvasProps.variables[v].length *= scale;
    canvasProps.variables[v].textSize *= scale;
  });

  // if(!!canvasProps.upload_images.sticker){
  //   canvasProps.upload_images.sticker.x /= (canvas_width / dest_canvas_width);
  //   canvasProps.upload_images.sticker.y /= (canvas_width / dest_canvas_width) * scale;
  // }
  canvasProps.upload_images.positionedImage.x *= scale;
  canvasProps.upload_images.positionedImage.x += x_factor * direction;
  canvasProps.upload_images.positionedImage.y *= scale;
  canvasProps.upload_images.positionedImage.y += y_factor * direction;

  if (!canvasProps.upload_images.sticker) {
    canvasProps.upload_images.sticker =
      canvasProps.upload_images.positionedImage;
  } else {
    canvasProps.upload_images.sticker.x *= scale;
    canvasProps.upload_images.sticker.x += x_factor * direction;
    canvasProps.upload_images.sticker.y *= scale;
    canvasProps.upload_images.sticker.y += y_factor * direction;
  }

  return canvasProps;
}

function scaleDownloadedNonPartnerConfig(
  canvasProps,
  canvas_width,
  dest_canvas_width,
  scale
) {
  canvasProps.nonPartnerConfig.length *= canvas_width / dest_canvas_width;
  canvasProps.nonPartnerConfig.x *= canvas_width / dest_canvas_width;
  if (canvasProps.nonPartnerConfig.alignment == "center")
    canvasProps.nonPartnerConfig.x -= canvasProps.nonPartnerConfig.length / 2;
  if (canvasProps.nonPartnerConfig.alignment == "right")
    canvasProps.nonPartnerConfig.x -= canvasProps.nonPartnerConfig.length;
  canvasProps.nonPartnerConfig.y *= (canvas_width / dest_canvas_width) * scale;
}

export function scaleDownloadedCanvasProps(canvasProps, canvasWidth) {
  console.log({ canvasProps });
  if (!canvasProps) return defaultCanvasProps;
  let canvas_width = canvasWidth || window.innerHeight * 0.6375;
  let dest_canvas_width = 1080;
  console.log({ canvasProps });
  let scale =
    canvasProps.settings?.type == 3 || canvasProps.settings?.type == 1
      ? 0.56
      : 1;
  // scale *= (canvasWidth / 1080)
  let variables = ["city", "date", "freeformtext", "time"];
  if (
    canvasProps.variables.date.format.includes("d") ||
    canvasProps.variables.date.format.includes("y")
  ) {
    console.log("canvasprops date: ", canvasProps.variables.date.format);
    canvasProps.variables.date.format = moment().toMomentFormatString(
      canvasProps.variables.date.format
    );
    // .replace("''", "'")
    console.log("canvasprops date: ", canvasProps.variables.date.format);
  } else {
    canvasProps.variables.date.format = moment().toMomentFormatString(
      canvasProps.variables.date.format
    );
  }
  if (canvasProps.nonPartnerConfig)
    scaleDownloadedNonPartnerConfig(
      canvasProps,
      canvas_width,
      dest_canvas_width,
      scale
    );
  variables.forEach((v) => {
    canvasProps.variables[v].length *= canvas_width / dest_canvas_width;
    canvasProps.variables[v].x *= canvas_width / dest_canvas_width;
    if (canvasProps.variables[v].alignment == "center")
      canvasProps.variables[v].x -= canvasProps.variables[v].length / 2;
    if (canvasProps.variables[v].alignment == "right")
      canvasProps.variables[v].x -= canvasProps.variables[v].length;
    canvasProps.variables[v].y *= (canvas_width / dest_canvas_width) * scale;
    // canvasProps.variables[v].y = Math.floor(canvasProps.variables[v].y);
    canvasProps.variables[v].y -=
      ((parseInt(canvasProps.variables[v].textSize) * 0.25) / 2) * scale;
  });
  canvasProps.upload_images.positionedImage.x *=
    canvas_width / dest_canvas_width;
  canvasProps.upload_images.positionedImage.y *=
    (canvas_width / dest_canvas_width) * scale;
  canvasProps.upload_images.positionedImage.x = Math.floor(
    canvasProps.upload_images.positionedImage.x
  );
  canvasProps.upload_images.positionedImage.y = Math.floor(
    canvasProps.upload_images.positionedImage.y
  );

  canvasProps.upload_images.sticker = canvasProps.upload_images.positionedImage;

  if (canvasProps.upload_images.apng) {
    canvasProps.upload_images.apng.x *= canvas_width / dest_canvas_width;
    canvasProps.upload_images.apng.y *=
      (canvas_width / dest_canvas_width) * scale;
    canvasProps.upload_images.apng.x = Math.floor(
      canvasProps.upload_images.apng.x
    );
    canvasProps.upload_images.apng.y = Math.floor(
      canvasProps.upload_images.apng.y
    );
  }
  if (canvasProps.upload_images.gif) {
    canvasProps.upload_images.gif.x *= canvas_width / dest_canvas_width;
    canvasProps.upload_images.gif.y *=
      (canvas_width / dest_canvas_width) * scale;
    canvasProps.upload_images.gif.x = Math.floor(
      canvasProps.upload_images.gif.x
    );
    canvasProps.upload_images.gif.y = Math.floor(
      canvasProps.upload_images.gif.y
    );
  }

  if (canvasProps.upload_images.lottie) {
    canvasProps.upload_images.lottie.x *= canvas_width / dest_canvas_width;
    canvasProps.upload_images.lottie.y *=
      (canvas_width / dest_canvas_width) * scale;
    canvasProps.upload_images.lottie.x = Math.floor(
      canvasProps.upload_images.lottie.x
    );
    canvasProps.upload_images.lottie.y = Math.floor(
      canvasProps.upload_images.lottie.y
    );
  }

  return canvasProps;
}

function scaleNonSponsorInfoForUpload(
  canvasProps,
  canvas_width,
  dest_canvas_width,
  scale
) {
  canvasProps.nonPartnerConfig.length /= canvas_width / dest_canvas_width;
  canvasProps.nonPartnerConfig.x /= canvas_width / dest_canvas_width;
  if (canvasProps.nonPartnerConfig.alignment == "center")
    canvasProps.nonPartnerConfig.x += canvasProps.nonPartnerConfig.length / 2;
  if (canvasProps.nonPartnerConfig.alignment == "right")
    canvasProps.nonPartnerConfig.x += canvasProps.nonPartnerConfig.length;
  canvasProps.nonPartnerConfig.y /= (canvas_width / dest_canvas_width) * scale;
  canvasProps.nonPartnerConfig.y +=
    ((parseInt(canvasProps.nonPartnerConfig.textSize) * 0.75) / 2) * scale;

  let x_string = Math.floor(canvasProps.nonPartnerConfig.x) + "";
  let y_string = Math.floor(canvasProps.nonPartnerConfig.y) + "";
  let length_string = Math.floor(canvasProps.nonPartnerConfig.length) + "";
  canvasProps.nonPartnerConfig.x = x_string;
  canvasProps.nonPartnerConfig.y = y_string;
  canvasProps.nonPartnerConfig.length = length_string;
}

export function scaleCanvasPropsForUpload(canvasProps) {
  let canvas_width = window.innerHeight * 0.6375;
  let dest_canvas_width = 1080;
  let scale =
    canvasProps.settings.type == 3 || canvasProps.settings.type == 1 ? 0.56 : 1;
  canvasProps.variables.date.format = moment()
    .toJDFString(canvasProps.variables.date.format)
    .replace("'", "''");
  console.log(
    "canvasProps.variables.date.format: ",
    canvasProps.variables.date.format
  );
  console.log(
    "date format: ",
    moment().toJDFString(canvasProps.variables.date.format)
  );
  let variables = ["city", "date", "freeformtext", "time"];
  if (canvasProps.nonPartnerConfig)
    scaleNonSponsorInfoForUpload(
      canvasProps,
      canvas_width,
      dest_canvas_width,
      scale
    );
  variables.forEach((v) => {
    canvasProps.variables[v].length /= canvas_width / dest_canvas_width;
    canvasProps.variables[v].x /= canvas_width / dest_canvas_width;
    if (canvasProps.variables[v].alignment == "center")
      canvasProps.variables[v].x += canvasProps.variables[v].length / 2;
    if (canvasProps.variables[v].alignment == "right")
      canvasProps.variables[v].x += canvasProps.variables[v].length;
    canvasProps.variables[v].y /= (canvas_width / dest_canvas_width) * scale;
    canvasProps.variables[v].y +=
      ((parseInt(canvasProps.variables[v].textSize) * 0.75) / 2) * scale;

    let x_string = Math.floor(canvasProps.variables[v].x) + "";
    let y_string = Math.floor(canvasProps.variables[v].y) + "";
    let length_string = Math.floor(canvasProps.variables[v].length) + "";
    canvasProps.variables[v].x = x_string;
    canvasProps.variables[v].y = y_string;
    canvasProps.variables[v].length = length_string;
  });
  canvasProps.upload_images.positionedImage.x /=
    canvas_width / dest_canvas_width;
  canvasProps.upload_images.positionedImage.y /=
    (canvas_width / dest_canvas_width) * scale;
  canvasProps.upload_images.positionedImage.x =
    "" + parseInt(canvasProps.upload_images.positionedImage.x);
  canvasProps.upload_images.positionedImage.y =
    "" + parseInt(canvasProps.upload_images.positionedImage.y);

  canvasProps.upload_images.sticker = canvasProps.upload_images.positionedImage;

  if (canvasProps.upload_images.apng) {
    canvasProps.upload_images.apng.x /= canvas_width / dest_canvas_width;
    canvasProps.upload_images.apng.y /=
      (canvas_width / dest_canvas_width) * scale;
    canvasProps.upload_images.apng.x =
      "" + parseInt(canvasProps.upload_images.apng.x);
    canvasProps.upload_images.apng.y =
      "" + parseInt(canvasProps.upload_images.apng.y);
  }
  if (canvasProps.upload_images.gif) {
    canvasProps.upload_images.gif.x /= canvas_width / dest_canvas_width;
    canvasProps.upload_images.gif.y /=
      (canvas_width / dest_canvas_width) * scale;
    canvasProps.upload_images.gif.x =
      "" + parseInt(canvasProps.upload_images.gif.x);
    canvasProps.upload_images.gif.y =
      "" + parseInt(canvasProps.upload_images.gif.y);
  }

  if (canvasProps.upload_images.lottie) {
    canvasProps.upload_images.lottie.x /= canvas_width / dest_canvas_width;
    canvasProps.upload_images.lottie.y /=
      (canvas_width / dest_canvas_width) * scale;
    canvasProps.upload_images.lottie.x =
      "" + parseInt(canvasProps.upload_images.lottie.x);
    canvasProps.upload_images.lottie.y =
      "" + parseInt(canvasProps.upload_images.lottie.y);
  }

  return canvasProps;
}

export function setFrameContentType(
  selectedFilter,
  content_type,
  setSelectedFilter
) {
  let nextSelectedFilter = JSON.parse(JSON.stringify(selectedFilter));
  nextSelectedFilter.frame_content_type = content_type;
  setSelectedFilter(nextSelectedFilter);
}

export function verifyName(name) {
  if (name.charAt("_") == "_") return false;
  return !/[~`!#$%\^&*+=\-\[\]\\';,/{}()@|\\":<>\?\s]/g.test(name);
}

export function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => resolve(reader.result.split("base64,")[1]),
      false
    );

    if (file) reader.readAsDataURL(file);
  });
}

export async function createClient() {
  const credentials = await Auth.currentCredentials();
  const client = new Location({
    credentials,
    region: "us-east-1",
  });
  return client;
}

export function formatGeoCode(results) {
  let gcCeGfData = {};
  gcCeGfData.geoFence = {};
  gcCeGfData.geoFence.data = [];
  gcCeGfData.address_category = "";
  gcCeGfData.address_type = "custom";
  gcCeGfData.formatted_address = "";
  gcCeGfData.city_name = "";
  gcCeGfData.state_name = "";
  gcCeGfData.country_name = "";
  gcCeGfData.zipcode = "";
  gcCeGfData.country_name = "";
  gcCeGfData.state_name = "";
  gcCeGfData.city_name = "";
  var resultDataCntyStaCty = {},
    resultDataCntySta = {},
    resultDataCnty = {};
  if (results.length > 0) {
    results.forEach((value, index) => {
      if (results[index].types.length > 0) {
        var resultData = results[index];
        var typesChecking = results[index].types;
        var cntyStaCtyChecking = 0,
          StaCtyChecking = 0,
          CtyChecking = 0;
        typesChecking.forEach(function (value, index) {
          if (typesChecking[index] == "political") {
            cntyStaCtyChecking++;
            StaCtyChecking++;
            CtyChecking++;
          }

          if (typesChecking[index] == "locality") {
            cntyStaCtyChecking++;
          }

          if (typesChecking[index] == "administrative_area_level_1") {
            StaCtyChecking++;
          }

          if (typesChecking[index] == "country") {
            CtyChecking++;
          }

          if (cntyStaCtyChecking == 2) {
            resultDataCntyStaCty = resultData;
          }

          if (StaCtyChecking == 2) {
            resultDataCntySta = resultData;
          }

          if (CtyChecking == 2) {
            resultDataCnty = resultData;
          }
        });
      }
    });

    var resultsToSend = {};
    if (JSON.stringify(resultDataCntyStaCty) != "{}") {
      resultsToSend = resultDataCntyStaCty;
    } else if (JSON.stringify(resultDataCntySta) != "{}") {
      resultsToSend = resultDataCntySta;
    } else if (JSON.stringify(resultDataCnty) != "{}") {
      resultsToSend = resultDataCnty;
    }

    if (JSON.stringify(resultsToSend) != "{}") {
      gcCeGfData.formatted_address = resultsToSend.formatted_address;
      gcCeGfData.address_type = resultsToSend.types[0];
      if (resultsToSend.address_components !== undefined) {
        var line1_ = "";
        var line2_ = "";
        for (var i = 0; i < resultsToSend.address_components.length; i++) {
          if (resultsToSend.address_components[i].types[0] == "postal_code") {
            gcCeGfData.zipcode = resultsToSend.address_components[i].long_name;
          }
          if (resultsToSend.address_components[i].types[0] == "country") {
            gcCeGfData.country_name =
              resultsToSend.address_components[i].long_name;
            gcCeGfData.country_code =
              resultsToSend.address_components[i].short_name;
          }
          if (
            resultsToSend.address_components[i].types[0] ==
            "administrative_area_level_1"
          ) {
            gcCeGfData.state_name =
              resultsToSend.address_components[i].long_name;
            gcCeGfData.state_code =
              resultsToSend.address_components[i].short_name;
          }
          if (resultsToSend.address_components[i].types[0] == "locality") {
            gcCeGfData.city_name =
              resultsToSend.address_components[i].long_name;
            gcCeGfData.city_code =
              resultsToSend.address_components[i].short_name;
          }
          if (resultsToSend.address_components[i].types[0] == "street_number") {
            line1_ = resultsToSend.address_components[i].long_name;
          }
          if (resultsToSend.address_components[i].types[0] == "route") {
            line1_ += resultsToSend.address_components[i].long_name;
          }
          if (
            resultsToSend.address_components[i].types[0] ==
            "sublocality_level_1"
          ) {
            line2_ = resultsToSend.address_components[i].long_name;
          }
          if (
            resultsToSend.address_components[i].types[0] ==
            "administrative_area_level_2"
          ) {
            line2_ += resultsToSend.address_components[i].long_name;
          }
        }
        gcCeGfData.line1 = line1_;
        gcCeGfData.line2 = line2_;
      }
    }
    return gcCeGfData;
  } else {
    return gcCeGfData;
  }
}

export function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
}

import React, {useState, useEffect, useRef} from 'react';
import ARFilters from './ARFilters';
import {Typography, Box, Tabs, Tab, TextField, Button, CircularProgress} from '@material-ui/core';
import TabPanel from '../TabPanel';
import 'typeface-roboto';
import EditFilter from '../Common/EditFilter.js';
import DeleteItem from '../Common/DeleteItem';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import GeoLocations from '../Common/GeoLocations/GeoLocations';
import { getEnvironmentCampaignIds } from '../../CommonFunctions';
import { 
    getCampaignLocationList, 
    materialTableCarouselFilters, 
    getARDetails,
    updateCampaign
} from '../../ApiUtility/ApiUtility';


const useStyles = makeStyles({
    saveButton: {
        float: 'right',
        right: '25px'
    }
});



/**
 * Component used to display the designer for AR Faces.
 *
 * @component
 */

function ARFaces (){
    const classes = useStyles();
    const [isCreateFilterDialogOpen, setIsCreateFilterDialogOpen] = useState(false);
    const [arDetails, setArDetails] = useState({});
    const [textHeader, setTextHeader] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleteFilterDialogOpen, setIsDeleteFilterDialogOpen] = useState(false);
    const [isCreateGeoLocationsDialogOpen, setIsCreateGeoLocationsDialogOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [isLinksDialogOpen, setIsLinksDialogOpen] = useState(false);
    const [campaignLocationLinks, setCampaignLocationLinks] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({});
    const [campaignFilters, setCampaignFilters] = useState([]);
    const [campaign, setCampaign] = useState({name: "AR", campaign_id: getEnvironmentCampaignIds().ar, retailer_id: 0});
    const [value, setValue] = useState(0);

    const tableRef = useRef();
    

    function handleChange(e, i){
        setValue(i);
    }

    function handleViewLinksClick(frame){
        setSelectedFilter(frame);
        setIsLinksDialogOpen(true);
    }

    const handleEditClose = e => {
        setSelectedFilter({});
        setEditOpen(false);
    }

    const updateCampaignLocationList = () => {
        getCampaignLocationList(campaign.campaign_id).then((fetchedCampaignLocations) => {
            setCampaignLocationLinks(fetchedCampaignLocations);
        });
    }

    const updateCarouselDisplayName = () => {
        setIsSaving(true);
        let nextARDetails = JSON.parse(JSON.stringify(arDetails));
        nextARDetails.text_header = textHeader;
        updateCampaign(nextARDetails)
        .then(r => setIsSaving(false))
        .catch(err => console.log("err: ", err));
    }

    useEffect(()=>{
        updateCampaignLocationList();
        getARDetails().then(r => {
            setArDetails(r);
            setTextHeader(r.text_header);
        });
    }, [])


    return (
        <>
            <EditFilter
                editOpen={editOpen}
                handleEditClose={handleEditClose}
                selectedFilter={selectedFilter} 
                setSelectedFilter={setSelectedFilter}
                campaign={campaign}
                retailer={{retailer_id:0}}
                refreshData={e => tableRef.current.onQueryChange()}
                frameAssociations={[{"entity_type_name":"campaign","entity_id":campaign.campaign_id}]}
            />
            <DeleteItem 
                open={isDeleteFilterDialogOpen}
                setIsDeleteOpen={setIsDeleteFilterDialogOpen}
                handleDeleteClose={e => setIsDeleteFilterDialogOpen(false)}
                selectedItem={selectedFilter} 
                setSelectedItem={setSelectedFilter}
                refreshData={e => tableRef.current.onQueryChange()}
                campaign={campaign}
            />
            <Box style={{width:'100%'}}>
                <Box style={{textAlign: "left"}} pl={2}>
                    <Link style={{color: 'black'}} to={"/other-setups/non-sponsor"}>
                        <Typography style={{display: "inline-block", verticalAlign:"middle"}} variant="h6">
                            AR Filters
                        </Typography>
                    </Link>
                    <Button className={classes.saveButton} variant="contained" color="inherit" onClick={updateCarouselDisplayName}>
                        save {isSaving && <CircularProgress size={24} style={{position: "absolute"}} />}
                    </Button>
                    <TextField style={{float: 'right', marginRight:"45px"}} 
                        label="Menu Display Name" 
                        variant="outlined"
                        defaultValue={textHeader} 
                        value={textHeader}
                        onChange={e => setTextHeader(e.target.value)}
                    />
                </Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab id="geo_filters" label="Filters" /> 
                    <Tab id="geo_locations" label="Geo Locations" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ARFilters
                        open={isLinksDialogOpen} 
                        setIsLinksDialogOpen={setIsLinksDialogOpen}
                        campaignLocationLinks={campaignLocationLinks}
                        tableRef={tableRef}
                        setSelectedFilter={setSelectedFilter}
                        setEditOpen={setEditOpen}
                        selectedFilter={selectedFilter} 
                        campaignFilters={campaignFilters}
                        setIsCreateFilterDialogOpen={setIsCreateFilterDialogOpen}
                        setIsDeleteFilterDialogOpen={setIsDeleteFilterDialogOpen}
                        handleViewLinksClick={handleViewLinksClick}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <GeoLocations 
                        open={isLinksDialogOpen} 
                        setIsLinksDialogOpen={setIsLinksDialogOpen}
                        handleViewLinksClick={handleViewLinksClick}
                        campaign_id={campaign.campaign_id}
                        updateCampaignLocationList={updateCampaignLocationList}
                        campaignLocationLinks={campaignLocationLinks}
                        setIsCreateGeoLocationsDialogOpen={setIsCreateGeoLocationsDialogOpen}
                        isCreateGeoLocationsDialogOpen={isCreateGeoLocationsDialogOpen}
                        selectedFilter={selectedFilter} 
                        setSelectedFilter={setSelectedFilter}
                        itemsToSelect={materialTableCarouselFilters}
                    />
                </TabPanel>
            </Box>
        </>
    )
  }

  export default ARFaces;
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Storage } from "aws-amplify";
import {
  Button,
  Dialog,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  Chip,
  Slider,
  Typography,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import { ToggleButton, ToggleButtonGroup, Alert } from "@material-ui/lab/";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TabPanel from "../TabPanel";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import moment from "moment";
import ScheduleSettings from "./ScheduleSettings";
import FilterDesigner from "./FilterDesigner";
import SettingsCard from "../SettingsCard";
import SaveExitDialog from "./SaveExitDialog";
import ExclusiveContentConfiguration from "./ExclusiveContentConfiguration";
import axios from "axios";
import { tagsAtom, frameTagsAtom } from "../../App";
import { atom, useAtom } from "jotai";

import {
  getCanvasElementsToHide,
  imageToDataUri,
  verifyDates,
  verifyName,
  getAPICall,
} from "../../CommonFunctions";
import {
  saveFilter,
  getCanvasProps,
  saveCanvasProps,
  saveFilterImages,
  saveTemplateImage,
  getFonts,
  saveARJSON,
  getARJSON,
  testFormDataCall,
  saveThumbnailImage,
  loadFilterImage,
  getPremiumAttributionInfo,
  savePremiumAttribution,
  saveFrameTags,
  deleteFrameTags,
} from "../../ApiUtility/ApiUtility";
import defaultCanvasProps, {
  placesCanvasProps,
} from "../../data/defaultCanvasProps";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "rgb(72, 120, 152)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formLabel: {
    display: "block",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Main component used to hold state and show controls for editing filters.
 *
 * @component
 */

export default function FilterDialog(props) {
  const classes = useStyles();
  const filter = createFilterOptions();
  const [contentTypeLoaded, setContentTypeLoaded] = useState(false);
  const [value, setValue] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [canvasProps, setCanvasProps] = useState({
    variables: {},
    settings: {},
  });
  const [attributionActive, setAttributionActive] = useState(0);
  const [attributionText, setAttributionText] = useState("");
  const [attributionLink, setAttributionLink] = useState("");
  const [selectedArBinaries, setSelectedArBinaries] = useState([]);
  const [selectedDeepArVersion, setSelectedDeepArVersion] = useState(null);
  const [uploadedWorldViewEffect, setUploadedWorldViewEffect] = useState({});
  const [uploadedArAudio, setUploadedArAudio] = useState({});
  const [uploadedApng, setUploadedApng] = useState(false);
  const [uploadedSticker, setUploadedSticker] = useState({});
  const [uploadedBackground, setUploadedBackground] = useState(false);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(false);
  const [uploadedGif, setUploadedGif] = useState({});
  const [uploadedLottie, setUploadedLottie] = useState({});
  const [fonts, setFonts] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [hiddenCanvasElements, setHiddenCanvasElements] = useState([]);
  const [alignment, setAlignment] = useState("vertical");
  const [err, setErr] = useState({});
  const [areSettingsUpdated, setAreSettingsUpdated] = useState(false);
  const [isSaveBeforeExitOpen, setIsSaveBeforeExitOpen] = useState(false);
  const [filterWasSaved, setFilterWasSaved] = useState(false);
  const [doStartValidation, setDoStartValidation] = useState(true);
  const [currentTags, setCurrentTags] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [deepArBgRadio, setDeepArBgRadio] = useState("no");

  const [tags, setTags] = useAtom(tagsAtom);
  const [frameTags, setFrameTags] = useAtom(frameTagsAtom);

  const [testFrameID, setTestFrameID] = useState(" ");

  const stageRef = useRef();

  const handleAlignment = (e, v) => {
    setAlignment(v);
  };

  const displaySavedAlert = (e) => {
    setFilterWasSaved(true);
    setTimeout(() => {
      setFilterWasSaved(false);
    }, 2000);
  };

  const updateCanvasProps = (type, settings) => {
    setAreSettingsUpdated(true);
    let nextProps = JSON.parse(JSON.stringify(canvasProps));
    if (
      type == "settings" ||
      type == "upload_images" ||
      type == "providerUrl" ||
      type == "nonPartnerConfig" ||
      type == "web_ar" ||
      type == "thumbnail" ||
      type == "arAudio" ||
      type == "arPlacement"
    )
      nextProps[type] = settings;
    else nextProps.variables[type] = settings;
    setCanvasProps(nextProps);
  };

  const handleChange = (e, v) => setValue(v);

  const handleDisplayChange = (e) => {
    setAreSettingsUpdated(true);
    let checkbox_name = e.target.getAttribute("name");
    let nextFilter = Object.assign({}, props.selectedFilter);
    if (checkbox_name == "carousel")
      nextFilter.optin_algorithm = nextFilter.optin_algorithm == 1 ? 0 : 1;
    else nextFilter.optin_menu = nextFilter.optin_menu == 1 ? 0 : 1;
    props.setSelectedFilter(nextFilter);
  };

  const updateFilterName = (e) => {
    if (filterName == e.target.value) return;
    setAreSettingsUpdated(true);
    if (!props.selectedFilter.frame_id) return setFilterName(e.target.value);
    let nextFilter = Object.assign({}, props.selectedFilter);
    nextFilter.name = e.target.value;
    props.setSelectedFilter(nextFilter);
  };

  const checkForError = (e) => {
    if (!e) return;
    let value;
    if (typeof e === "string") value = e;
    else value = e.target.value;
    if (!verifyName(value)) setNameError(true);
    else setNameError(false);
  };

  const prepareARFormData = async () => {
    const requestConfig = {
      customPrefix: {
        public: "retailers",
        protected: "retailers",
        private: "retailers",
      },
    };
    return new Promise(async (resolve, reject) => {
      let arUploads = [];
      let obj = { deep_ar_version: selectedDeepArVersion, scenes: [] };
      let arBinaries = selectedArBinaries.map((x) =>
        axios.get(
          `https://www.googleapis.com/drive/v3/files/${x.id}?supportsAllDrives=true&includeItemsFromAllDrives=true&mimeType=application/octet-stream&alt=media`,
          { responseType: "blob" }
        )
      );
      let binaries = await axios.all(arBinaries);
      selectedArBinaries.forEach(async (v, i) => {
        obj.scenes.push(v.name);
        arUploads.push(
          Storage.put(
            `/${props.retailer.retailer_id}/${props.campaign.campaign_id}/frames/${props.selectedFilter.frame_id}_${v.name}`,
            binaries[i].data,
            requestConfig
          )
        );
      });
      await Promise.allSettled(arUploads);
      await saveARJSON(
        props.retailer.retailer_id,
        props.campaign.campaign_id,
        props.selectedFilter.frame_id,
        obj
      );
      let next_binaries = selectedArBinaries.map((x) => {
        delete x.id;
        return x;
      });
      setSelectedArBinaries(next_binaries);
      resolve();
    });
  };

  const getPremiumAttributionObject = () => {
    let validatedLink =
      attributionLink.indexOf("http://") == -1 &&
      attributionLink.indexOf("https://") == -1 &&
      attributionLink !== ""
        ? "http://" + attributionLink
        : attributionLink;
    return {
      frame_id: props.selectedFilter.frame_id,
      attribution_text: attributionText,
      attribution_link: validatedLink,
      owner: "Mobilize",
      active: attributionActive,
    };
  };

  const createFilter = () => {
    let {
      selectedFilter: { job },
    } = props;
    let active =
      typeof props.selectedFilter.active == "undefined"
        ? 0
        : props.selectedFilter.active;
    return new Promise((resolve, reject) => {
      saveFilter({
        name: filterName,
        frame_tags: "",
        frameAssociations: props.frameAssociations,
        frame_type: props.sponsor_id
          ? "sponsor"
          : props.frame_type || "non-sponsor",
        active,
        job,
        frame_content_type: "default",
      })
        .then((filter) => {
          filter.movable = 1;
          props.setSelectedFilter(filter);
          let canvas_props = filter.frame_type.includes("places")
            ? placesCanvasProps
            : defaultCanvasProps;
          console.log("create filter: ", filter, canvas_props);
          setCanvasProps(canvas_props);
          saveCanvasProps(filter.frame_id, canvas_props);
          setIsSaving(false);
          setAreSettingsUpdated(false);
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  const handleSave = async () => {
    let networkCalls = [];
    return new Promise(async (resolve, reject) => {
      let selectedFilterClone = JSON.parse(
        JSON.stringify(props.selectedFilter)
      );
      setIsSaving(true);
      if (nameError) return false;
      if (!props.selectedFilter.frame_id) {
        try {
          verifyDates(
            selectedFilterClone.startDate,
            selectedFilterClone.endDate,
            selectedFilterClone.job,
            true
          );
          await createFilter();
          displaySavedAlert();
          props.refreshData();
          resolve();
        } catch (err) {
          setIsSaving(false);
          setErr(err);
          resolve();
        }
      } else {
        try {
          verifyDates(
            props.selectedFilter.startDate,
            props.selectedFilter.endDate,
            props.selectedFilter.job,
            doStartValidation
          );
          if (canvasProps.web_ar?.link)
            selectedFilterClone.frame_content_type = "web_ar";
          else if (uploadedWorldViewEffect.file)
            selectedFilterClone.frame_content_type = "worldview";
          else if (
            (selectedArBinaries[0] && uploadedBackground.source) ||
            deepArBgRadio === "yes"
          )
            selectedFilterClone.frame_content_type = "deep_ar_with_bg";
          else if (selectedArBinaries[0])
            selectedFilterClone.frame_content_type = "deep_ar";
          else if (uploadedGif.source)
            selectedFilterClone.frame_content_type = "gif";
          else if (uploadedApng.source)
            selectedFilterClone.frame_content_type = "apng";
          else if (uploadedSticker.url)
            selectedFilterClone.frame_content_type = "sticker";
          else if (uploadedLottie.data)
            selectedFilterClone.frame_content_type = "lottie";
          else selectedFilterClone.frame_content_type = "default";

          networkCalls.push(
            saveCanvasProps(selectedFilterClone.frame_id, canvasProps)
          );

          networkCalls.push(saveFilter(selectedFilterClone));
          networkCalls.push(
            savePremiumAttribution(getPremiumAttributionObject())
          );
          //save ar effects
          if (selectedArBinaries[0] && !!selectedArBinaries[0].id)
            networkCalls.push(prepareARFormData());
          displaySavedAlert();
        } catch (err) {
          setErr(err);
        }
        if (!stageRef.current) {
          setValue(1);
          setTimeout(async () => {
            networkCalls.push(uploadImages());
          }, 1000);
        } else {
          networkCalls.push(uploadImages());
        }
        Promise.allSettled(networkCalls).then(() => {
          props.refreshData();
          setAreSettingsUpdated();
          setIsSaving(false);
          resolve();
        });
      }
    });
  };

  const uploadImages = async () => {
    return new Promise((resolve, reject) => {
      if (!stageRef.current) return setIsSaving(false);
      setHiddenCanvasElements(getCanvasElementsToHide(props.selectedFilter));
      setTimeout(async (e) => {
        let dataURL = await imageToDataUri(
          stageRef.current.children[0].canvas._canvas,
          1080,
          1440
        );
        dataURL = dataURL;

        setHiddenCanvasElements([]);
        let networkCalls = [];
        const frame_id = props.selectedFilter.frame_id;
        const campaign_id = props.campaign.campaign_id;
        const retailer_id = props.campaign.retailer_id;
        console.log("props.retailer: ", props.retailer);
        let imagesForUpload = [];
        if (uploadedSticker.source == "local") {
          let contentType =
            uploadedSticker.url.substr(0, 18) == "data:image/svg+xml"
              ? "image%2Fsvg%2Bxml"
              : "image%2Fpng";
          imagesForUpload.push({
            key: "sticker",
            file: uploadedSticker.url,
            contentType,
            contentEncoding: "base64",
          });
          imagesForUpload.push({
            key: "pi",
            file: uploadedSticker.url,
            contentType,
            contentEncoding: "base64",
          });
        }
        if (uploadedBackground.source == "local") {
          let contentType =
            uploadedBackground.file.substr(0, 18) == "data:image/svg+xml"
              ? "image%2Fsvg%2Bxml"
              : "image%2Fpng";
          imagesForUpload.push({
            key: "bi",
            file: uploadedBackground.file,
            contentType,
            contentEncoding: "base64",
          });
        }

        if (!!uploadedThumbnail)
          networkCalls.push(
            saveThumbnailImage(
              frame_id,
              campaign_id,
              retailer_id,
              uploadedThumbnail
            )
          );
        if (!!uploadedLottie.data)
          imagesForUpload.push({
            key: "lottie",
            file: uploadedLottie.data,
            contentType: "application%2Fjson",
            contentEncoding: "UTF-8",
          });
        if (!!uploadedGif.file)
          imagesForUpload.push({ key: "gif", file: uploadedGif.file });
        if (!!uploadedApng.file)
          imagesForUpload.push({ key: "apng", file: uploadedApng.file });
        networkCalls.push(
          saveTemplateImage({ frame_id, campaign_id, dataURL })
        );
        networkCalls.push(saveFilterImages(frame_id, imagesForUpload));
        Promise.allSettled(networkCalls)
          .then((x) => {
            if (uploadedLottie.data)
              setUploadedLottie({
                source: "remote",
                data: uploadedLottie.data,
              });
            if (!!uploadedGif.url)
              setUploadedGif({ source: "remote", url: uploadedGif.url });
            if (!!uploadedApng.url)
              setUploadedApng({ source: "remote", url: uploadedApng.url });
            if (uploadedSticker.url)
              setUploadedSticker({
                source: "remote",
                url: uploadedSticker.url,
              });
            if (uploadedBackground.file)
              setUploadedBackground({
                source: "remote",
                file: uploadedBackground.file,
              });
            setUploadedThumbnail(false);
            resolve();
          })
          .catch((err) => {
            if (uploadedSticker.url)
              setUploadedSticker({
                source: "remote",
                url: uploadedSticker.url,
              });
            if (uploadedBackground.url)
              setUploadedBackground({
                source: "remote",
                file: uploadedBackground.file,
              });
            setUploadedThumbnail(false);
            reject();
          });
      }, 1000);
    });
  };

  const setPremiumAttribution = async () => {
    if (!props.selectedFilter.frame_id) return;
    let r = await getPremiumAttributionInfo({
      frame_id: props.selectedFilter.frame_id,
    });
    if (!r.owner && props.campaign.campaign_id)
      r = await getPremiumAttributionInfo({
        campaign_id: props.campaign.campaign_id,
      });
    setAttributionActive(r.active);
    setAttributionText(r.attribution_text || "");
    setAttributionLink(r.attribution_link || "");
  };

  const closeEditFilter = () => {
    if (isSaving) return;
    if (areSettingsUpdated) setIsSaveBeforeExitOpen(true);
    else resetAndClose();
  };

  const loadARConfig = () => {
    return new Promise((resolve, reject) => {
      getARJSON(
        props.retailer.retailer_id,
        props.campaign.campaign_id,
        props.selectedFilter.frame_id
      )
        .then((r) => {
          setSelectedArBinaries(r.scenes);
          setSelectedDeepArVersion(r.deep_ar_version);
          resolve();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  const loadAsset = (type) => {
    if (type == "ar-experience")
      setUploadedWorldViewEffect({ source: "loading" });
    return new Promise((resolve, reject) => {
      loadFilterImage({
        filter_id: props.selectedFilter.frame_id,
        campaign_id: props.campaign.campaign_id,
        retailer_id: props.campaign.retailer_id,
        type,
      })
        .then((f) => {
          if (type == "lottie") {
            axios.get(f).then((r) => {
              setUploadedLottie({ source: "remote", data: r.data });
            });
          } else if (type == "bi")
            setUploadedBackground({ source: "remote", file: f });
          else if (type == "pi")
            setUploadedSticker({ source: "remote", url: f });
          else if (type == "gif") setUploadedGif({ source: "remote", url: f });
          else if (type == "apng")
            setUploadedApng({ source: "remote", url: f });
          else if (type == "ar-experience")
            setUploadedWorldViewEffect({ source: "remote", file: f });
          else if (type == "arAudio") {
          }
          resolve();
        })
        .catch((err) => {
          if (type == "ar-experience") setUploadedWorldViewEffect({});
          reject(err);
        });
    });
  };

  const loadAudio = async ({ key }) => {
    const requestConfig = {
      customPrefix: {
        public: "retailers",
        protected: "retailers",
        private: "retailers",
      },
    };
    return new Promise(async (resolve, reject) => {
      let audio_url = await Storage.get(key, requestConfig);
      let arrayBufferAudio = await loadArrayBufferFromURL(audio_url);
      console.log("arrayBufferAudio: ", arrayBufferAudio);
      setUploadedArAudio({ source: "remote", file: arrayBufferAudio });
      resolve(arrayBufferAudio);
    });
  };

  function loadArrayBufferFromURL(urlToSound) {
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.open("GET", urlToSound, true);
      request.responseType = "arraybuffer";
      request.onreadystatechange = function () {
        if (request.readyState == 4) {
          if (request.status == 200) {
            resolve(request.response);
          }
        }
      };
      request.send(null);
    });
  }

  const updateFrameTags = (nextCurrentTags) => {
    let index = frameTags.findIndex(
      (x) => x.frame_id == props.selectedFilter.frame_id
    );
    let nextFrameTags = JSON.parse(JSON.stringify(frameTags));
    if (index != -1) nextFrameTags[index].tags = nextCurrentTags;
    else
      nextFrameTags.push({
        active: 1,
        frame_id: props.selectedFilter.frame_id,
        tags: nextCurrentTags,
      });
    setFrameTags(nextFrameTags);
    setCurrentTags(nextCurrentTags);
  };

  const setFrameContentType = () => {
    console.log("canvasProps.arAudio: ", canvasProps.arAudio);
    Promise.allSettled([
      loadARConfig(),
      loadAsset("ar-experience"),
      loadAsset("pi"),
      loadAsset("bi"),
      loadAsset("lottie"),
      loadAsset("gif"),
      loadAsset("apng"),
    ])
      .then((x) => {
        setContentTypeLoaded(true);
      })
      .catch((err) => {
        setContentTypeLoaded(true);
      });
  };

  const resetAndClose = () => {
    setValue(0);
    setDeepArBgRadio("no");
    setUploadedSticker({});
    setUploadedBackground({});
    setUploadedLottie({});
    setUploadedGif({});
    setUploadedApng({});
    setUploadedWorldViewEffect({});
    setUploadedArAudio({});
    setAttributionActive(0);
    setAttributionText("");
    setAttributionLink("");
    setErr({});
    setNameError(false);
    props.handleEditClose();
  };

  useEffect(() => {
    getFonts().then((data) => setFonts(data));
  }, []);

  useEffect(() => {
    setPremiumAttribution();
    setContentTypeLoaded(false);
    setCurrentTags(
      frameTags.find((x) => x.frame_id == props.selectedFilter.frame_id)
        ?.tags || []
    );
  }, [props.selectedFilter.frame_id]);

  useEffect(() => {
    setFilterName(props.selectedFilter.name);
    checkForError(props.selectedFilter.name);
    getCanvasProps(props.selectedFilter.frame_id)
      .then((r) => {
        if ([0, 2].some((x) => x == r.settings.type)) setAlignment("vertical");
        else setAlignment("horizontal");
        setCanvasProps(r);
        loadAudio({ key: r.arAudio?.key });
      })
      .catch((err) => {
        if (props.selectedFilter.frame_type?.includes("places"))
          setCanvasProps(placesCanvasProps);
        else setCanvasProps(defaultCanvasProps);
      });
    setFrameContentType();
    if (props.selectedFilter.frame_content_type == "deep_ar_with_bg")
      setDeepArBgRadio("yes");
    else setDeepArBgRadio("no");
  }, [props.selectedFilter.frame_id]);

  useEffect(() => {
    if (!props.selectedFilter.job) return setDoStartValidation(true);
    let {
      selectedFilter: {
        job: { start_date, end_date },
      },
    } = props;
    if (moment(start_date) < moment() && moment() < moment(end_date))
      setDoStartValidation(false);
    else setDoStartValidation(true);
  }, [props.editOpen]);

  return (
    <div>
      <SaveExitDialog
        open={isSaveBeforeExitOpen}
        setIsSaveBeforeExitOpen={setIsSaveBeforeExitOpen}
        setAreSettingsUpdated={setAreSettingsUpdated}
        handleSave={handleSave}
        setValue={setValue}
        setUploadedSticker={setUploadedSticker}
        handleEditClose={resetAndClose}
        setErr={setErr}
      />
      <Dialog
        fullScreen
        open={props.editOpen}
        onClose={closeEditFilter}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              id="close_button"
              edge="start"
              color="inherit"
              disabled={isSaving}
              onClick={closeEditFilter}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              onClick={testFormDataCall}
            >
              {props.campaign.name} : {props.selectedFilter.name}
            </Typography>
            <Button
              id="save_button"
              disabled={!contentTypeLoaded || nameError}
              autoFocus
              color="inherit"
              onClick={handleSave}
            >
              {!props.selectedFilter.frame_id ? "create" : "save"}{" "}
              {isSaving && (
                <CircularProgress size={24} style={{ position: "absolute" }} />
              )}
            </Button>
          </Toolbar>
          {filterWasSaved && (
            <Alert severity="success">Filter has been saved</Alert>
          )}
          <Alert className={!err.message ? "hidden" : ""} severity="error">
            {err.message}
          </Alert>
        </AppBar>
        <Grid
          container
          p={3}
          style={{
            width: "95%",
            marginTop: "80px",
            marginLeft: "20px",
            height: "80px",
            zIndex: 1000,
          }}
        >
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              <TextField
                id="outlined-basic"
                label="ID"
                InputProps={{
                  readOnly: true,
                }}
                value={props.selectedFilter.frame_id || " "}
                onClick={(e) => setTestFrameID(123)}
                onChange={(e) => e}
                variant="outlined"
              />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              <TextField
                id="filter_name"
                label="Name"
                onBlur={updateFilterName}
                onChange={checkForError}
                error={nameError}
                helperText={
                  nameError
                    ? "No special characters, white spaces, or leading underscores."
                    : ""
                }
                defaultValue={filterName}
                variant="outlined"
                autoFocus={typeof props.selectedFilter.name == "undefined"}
              />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <ToggleButtonGroup
              value={alignment}
              className={
                value !== 0 &&
                ![1, 0].some((x) => x == canvasProps.settings.type)
                  ? ""
                  : "hidden"
              }
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
              id="togglebuttongroup"
            >
              <ToggleButton value="vertical" aria-label="left aligned">
                Vertical
              </ToggleButton>
              <ToggleButton value="horizontal" aria-label="centered">
                Horizontal
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={3}>
            {value == 1 && frameTags.length == 0 && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress size={24} />
                <Typography variant="subtitle1">Loading tags... </Typography>
              </div>
            )}
            {value == 1 && frameTags.length > 0 && (
              <Autocomplete
                multiple
                id="tags-select"
                freeSolo
                options={tags}
                value={currentTags}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params).filter(
                    (o) => !currentTags.some((ct) => ct.tag_name == o.tag_name)
                  );

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.tag_name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      tag_name: inputValue,
                    });
                  }

                  return filtered;
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <div style={{ marginRight: "15px" }}>
                      <Chip
                        label={option.tag_name}
                        {...getTagProps({ index })}
                      ></Chip>
                      <Slider
                        defaultValue={option.relevance || 0}
                        step={1}
                        marks
                        min={0}
                        max={5}
                        getAriaValueText={(e) => e}
                        valueLabelDisplay="auto"
                        onChangeCommitted={(e, v) => {
                          let nextCurrentTags = JSON.parse(
                            JSON.stringify(currentTags)
                          );
                          let index = nextCurrentTags.findIndex(
                            (x) => x.tag_name == option.tag_name
                          );
                          nextCurrentTags[index].relevance = v;
                          saveFrameTags(
                            props.selectedFilter.frame_id,
                            nextCurrentTags
                          );
                          updateFrameTags(nextCurrentTags);
                        }}
                      />
                    </div>
                  ))
                }
                onChange={(e, v, reason) => {
                  if (reason == "remove-option" && e.type == "keydown") return;
                  if (currentTags.length > v.length) {
                    var result = currentTags.filter(
                      (e) => !v.find((a) => e.tag_name == a.tag_name)
                    );
                    deleteFrameTags(
                      props.selectedFilter.frame_id,
                      result[0].tag_id
                    );
                  } else {
                    v.forEach((t, i) => {
                      if (typeof t == "string") {
                        v[i] = { relevance: 0, tag_name: t };
                      }
                    });
                    saveFrameTags(props.selectedFilter.frame_id, v);
                  }
                  updateFrameTags(v);
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.tag_name;
                }}
                // defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{ ...params.InputProps, endAdornment: null }}
                    variant="standard"
                    label="Tags"
                    placeholder="Add Tags"
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ zIndex: "1002", width: "50%", margin: "0 auto" }}
        >
          <Tab label="Settings" />
          <Tab disabled={!props.selectedFilter.frame_id} label="Design" />
        </Tabs>
        <TabPanel value={value} index={0}>
          {(props.selectedFilter.frame_type == "global" ||
            props.campaign.name == "AR" ||
            props.campaign.name == "World View AR") && (
            <SettingsCard title="Display Configuration">
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Checkbox
                    checked={props.selectedFilter.optin_algorithm == 1}
                    color="primary"
                    onChange={handleDisplayChange}
                    name="carousel"
                  />
                }
                label="Show in Carousel"
              />
              <FormControlLabel
                className={classes.formLabel}
                color="primary"
                control={
                  <Checkbox
                    checked={props.selectedFilter.optin_menu == 1}
                    color="primary"
                    onChange={handleDisplayChange}
                    name="menuList"
                  />
                }
                label="Show in Menu List"
              />
            </SettingsCard>
          )}
          <ScheduleSettings
            setAreSettingsUpdated={setAreSettingsUpdated}
            item={props.selectedFilter}
            setItem={props.setSelectedFilter}
            isRepeatOpen={false}
            err={err}
            setErr={setErr}
            setIsRepeatOpen={(e) => e}
          />
          <ExclusiveContentConfiguration
            frameId={props.selectedFilter.frame_id}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FilterDesigner
            err={err}
            setErr={setErr}
            editOpen={props.editOpen}
            setAreSettingsUpdated={setAreSettingsUpdated}
            setAlignment={setAlignment}
            alignment={alignment}
            selectedFilter={props.selectedFilter}
            setSelectedFilter={props.setSelectedFilter}
            updateCanvasProps={updateCanvasProps}
            canvasProps={canvasProps}
            campaign={props.campaign}
            retailer={props.retailer}
            uploadedWorldViewEffect={uploadedWorldViewEffect}
            setUploadedWorldViewEffect={setUploadedWorldViewEffect}
            uploadedArAudio={uploadedArAudio}
            setUploadedArAudio={setUploadedArAudio}
            loadAudio={loadAudio}
            selectedArBinaries={selectedArBinaries}
            setSelectedArBinaries={setSelectedArBinaries}
            setSelectedDeepArVersion={setSelectedDeepArVersion}
            attributionActive={attributionActive}
            setAttributionActive={setAttributionActive}
            attributionText={attributionText}
            setAttributionText={setAttributionText}
            attributionLink={attributionLink}
            setAttributionLink={setAttributionLink}
            setDeepArBgRadio={setDeepArBgRadio}
            deepArBgRadio={deepArBgRadio}
            uploadedApng={uploadedApng}
            setUploadedApng={setUploadedApng}
            uploadedGif={uploadedGif}
            setUploadedGif={setUploadedGif}
            uploadedSticker={uploadedSticker}
            setUploadedSticker={setUploadedSticker}
            uploadedBackground={uploadedBackground}
            setUploadedBackground={setUploadedBackground}
            uploadedThumbnail={uploadedThumbnail}
            setUploadedThumbnail={setUploadedThumbnail}
            uploadedLottie={uploadedLottie}
            setUploadedLottie={setUploadedLottie}
            fonts={fonts}
            stageRef={stageRef}
            hiddenCanvasElements={hiddenCanvasElements}
          />
        </TabPanel>
      </Dialog>
    </div>
  );
}

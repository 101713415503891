import React, { useState, useEffect } from "react";
import MaterialTable, { MTableBody, MTablePagination } from "material-table";
import { Link, Typography } from "@material-ui/core";
import { Create, Delete, ChevronLeft } from "@material-ui/icons";
import {
  applySchedActiveStatus,
  getStatusFilterStatement,
  getAPICall,
  formatSearch,
} from "../../CommonFunctions";
import { pageHistoryAtom, visitedLinksAtom } from "../../App";
import { useAtom } from "jotai";

const table_styling = {
  headerStyle: {
    backgroundColor: "#9cadc3",
    fontWeight: "bold",
    borderRight: "1px solid white",
  },
  cellStyle: { borderRight: ".5px solid rgba(224, 224, 224, 1)" },
};

let queryFilter = [];

/**
 * Component for managing places filters.
 *
 * @component
 */

function PlacesFilters(props) {
  let filterBy = null;
  const [pageSize, setPageSize] = useState(10);
  const [pageHistory, setPageHistory] = useAtom(pageHistoryAtom);
  const [visitedLinks, setVisitedLinks] = useAtom(visitedLinksAtom);

  useEffect(() => {
    return (e) => (queryFilter = []);
  }, []);

  return (
    <MaterialTable
      title={
        <Link
          style={{ color: "black", cursor: "pointer" }}
          onClick={props.resetPlacesNav}
        >
          <ChevronLeft style={{ verticalAlign: "middle" }} fontSize="large" />
          <Typography
            style={{ display: "inline-block", verticalAlign: "middle" }}
            variant="h6"
          >
            {props.entity_id !== 1
              ? props.activeCategory?.category_name
              : "Generic Places Filters"}
          </Typography>
        </Link>
      }
      options={{
        filtering: true,
        pageSize,
        pageSizeOptions: [10, 20, 30],
      }}
      components={{
        Pagination: (props) => {
          delete props.classes.caption;
          delete props.classes.selectRoot;
          delete props.classes.toolbar;
          return (
            <MTablePagination {...props} onPageChange={props.onChangePage} />
          );
        },
        Body: (props) => (
          <MTableBody
            {...props}
            onFilterChanged={(columnId, value) => {
              let nextFilterBy = value.filter((x) => x !== filterBy);
              filterBy = nextFilterBy[0];
              props.onFilterChanged(columnId, [filterBy]);
            }}
          />
        ),
      }}
      tableRef={props.tableRef}
      columns={[
        { title: "Id", field: "frame_id", filtering: false, ...table_styling },
        {
          title: "Filter Name",
          field: "name",
          filtering: false,
          ...table_styling,
        },
        {
          title: "Status",
          field: "active_status",
          ...table_styling,
          defaultFilter: queryFilter,
          lookup: {
            0: "Inactive",
            1: "Active",
            2: "Scheduled Inactive",
            3: "Scheduled Active",
          },
        },
        {
          title: "Actions",
          filtering: false,
          render: (rowData) => (
            <Typography>
              <Link
                className={
                  visitedLinks.filters.includes(rowData.frame_id)
                    ? "visited"
                    : ""
                }
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  props.setSelectedFilter(rowData);
                  props.setEditOpen(true);
                  setVisitedLinks((x) => {
                    x.filters.push(rowData.frame_id);
                    return x;
                  });
                }}
              >
                <Create style={{ color: "black" }} />
                <span style={{ verticalAlign: "bottom", lineHeight: "36px" }}>
                  Edit
                </span>
              </Link>
              &nbsp;&nbsp;
              <Link
                href="/delete_campaign"
                onClick={(event) => {
                  event.preventDefault();
                  props.setSelectedFilter(rowData);
                  props.setIsDeleteFilterDialogOpen(true);
                }}
              >
                <Delete style={{ color: "black" }} />
                <span style={{ verticalAlign: "bottom", lineHeight: "36px" }}>
                  Delete
                </span>
              </Link>
            </Typography>
          ),
          headerStyle: { backgroundColor: "#9cadc3", fontWeight: "bold" },
        },
      ]}
      actions={[
        {
          icon: "add",
          tooltip: `Add ${
            props.entity_id == 1 ? "Generic" : "Specific"
          } Places Geo Filter`,
          isFreeAction: true,
          onClick: (e) => props.setEditOpen(true),
        },
      ]}
      data={(query) =>
        new Promise((resolve, reject) => {
          console.log("in function for places");
          if (pageHistory.active && pageHistory.pageNumber) {
            query.page = pageHistory.pageNumber;
            setPageHistory((x) => {
              delete x.active;
              return x;
            });
          }
          let { active, jobFlag } = getStatusFilterStatement(query.filters);
          queryFilter = query.filters[0].value;
          setPageSize(query.pageSize);
          let url = `/CameraPlus/frame/list`;
          url += "?size=" + query.pageSize;
          url += "&from=" + query.page * query.pageSize;
          url += "&keyword=" + formatSearch(query.search);
          if (query.filters[0])
            url += `&active=${typeof active == "undefined" ? "" : active}`;
          if (query.filters[0])
            url += `&jobFlag=${typeof jobFlag == "undefined" ? "" : jobFlag}`;
          getAPICall(url, {
            frameAssociations: [
              {
                entity_type_name: "campaign",
                entity_id: props.campaign_entity_id,
              },
              {
                entity_type_name: "category",
                entity_id: props.activeCategory?.id,
              },
            ],
          })
            .then((response) => response.data)
            .then((result) => {
              resolve({
                data: applySchedActiveStatus(result.response.items),
                page: pageHistory.active
                  ? pageHistory.pageNumber || 0
                  : query.page || 0,
                totalCount: result.response.total,
              });
            });
        })
      }
    />
  );
}

export default PlacesFilters;
